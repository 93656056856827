import React, { useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { Formik, FieldArray } from 'formik';
import { useMutation, useQuery } from 'react-apollo-hooks';

import * as Yup from 'yup';
import { Typography, Divider, InputAdornment, IconButton, MenuItem } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';

import { Grid, Cell } from 'styled-css-grid';
import { isEmpty, groupBy } from 'lodash';

import Button from 'components/styled/Button';
import { Form } from 'components/Form';
import { InputField } from 'components/InputField';
import { SelectField } from 'components/SelectField';
import { ConfirmDeleteDialog } from 'components/ConfirmDeleteDialog';
import { COMPONENT_UPDATE } from 'gql/mutations';
import { GET_TRANSPORTS } from 'gql/queries';
import { useModal } from 'hooks';
import OriginDestinationDialog from './OriginDestinationDialog';

// import SelectOriginDestination from './OriginDestinationDialog';

const AgregarButton = styled.div`
  width: 50%;
`;

function ComponentTransports({ component = {}, project = {} }) {
  const updateComponentMutation = useMutation(COMPONENT_UPDATE);
  const { data = {}, error, loading } = useQuery(GET_TRANSPORTS);
  const [isArrayTouched, setIsArrayTouched] = useState(false);

  const { transports = [], errors } = data;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const [dialogArgs, setDialogArgs] = useState({
    transportType: '',
    distanceCallback: () => {},
  });

  const openOriginDestinationDialog = (transportType, distanceCallback) => {
    setDialogArgs({ transportType, distanceCallback });
    setIsDialogOpen(true);
  };

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const handleCloseConfirm = () => {
    setIsConfirmOpen(false);
  };

  const [confirmArgs, setConfirmArgs] = useState({
    title: '',
    message: '',
    callback: () => {},
  });

  const openConfirmDeleteDialog = ({ title, message, callback }) => {
    setConfirmArgs({
      title,
      message,
      callback,
    });
    setIsConfirmOpen(true);
  };

  const { id, appliedTransports = [] } = component;

  const { isPublic } = project;

  const updateComponentSubmit = useCallback(
    async (values, actions) => {
      const input = {
        id,
        appliedTransports: values.appliedTransports.map(
          ({ transportId, distanceInKilometers }) => ({ transportId, distanceInKilometers })
        ),
      };
      updateComponentMutation({ variables: { input } });
    },
    [id]
  );

  if (isEmpty(component)) return null;

  const emptyTransport = {
    transportId: '',
    type: '',
    distanceInKilometers: 1,
  };

  // group transports by transport type
  const groupedTransports = groupBy(transports, 'type');
  const transportTypes = Object.keys(groupedTransports);

  // origin-destination dialog handler
  // function openSelectOriginDestinyDialog(transportType, distanceCallback) {
  //   resetDialog();
  //   setDialog(<SelectOriginDestination transportType={transportType} distanceCallback={distanceCallback} />);
  //   openDialog();
  // }

  const initialValues = {
    id,
    appliedTransports: appliedTransports.map(
      ({ transport: { id, type }, distanceInKilometers }) => ({
        transportId: id,
        type,
        distanceInKilometers,
      })
    ),
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={updateComponentSubmit}>
      {({
        errors,
        dirty,
        handleSubmit,
        setFieldValue,
        values,
        resetForm,
        initialValues: formikInitialValues,
      }) => {
        return (
          <>
            <OriginDestinationDialog
              isOpen={isDialogOpen}
              closeModal={handleDialogClose}
              {...dialogArgs}
            />
            <ConfirmDeleteDialog
              isOpen={isConfirmOpen}
              closeModal={handleCloseConfirm}
              {...confirmArgs}
            />
            <Form>
              <FieldArray name="appliedTransports">
                {({ push, replace, remove }) => (
                  <Grid columns={'1fr'} rows={'auto'}>
                    <Grid
                      columns={'1fr'}
                      rows={'auto'}
                      css={css`
                        padding-top: 1rem;
                      `}
                    >
                      {values.appliedTransports.map((x, index) => (
                        <Grid key={index} columns="1fr 1fr 20% auto auto">
                          <SelectField
                            disabled={isPublic}
                            name={`appliedTransports.${index}.type`}
                            label="Tipo"
                          >
                            {transportTypes.map(transportType => (
                              <MenuItem key={transportType} value={transportType}>
                                {transportType}
                              </MenuItem>
                            ))}
                          </SelectField>

                          <SelectField
                            disabled={isPublic}
                            name={`appliedTransports.${index}.transportId`}
                            label="Vehículo"
                            // disabled={isEmpty(values.appliedTransports[index].type)}
                          >
                            <MenuItem value="" />
                            {(groupedTransports[values.appliedTransports[index].type] || []).map(
                              transport => (
                                <MenuItem key={transport.id} value={transport.id}>
                                  {transport.vehicle}
                                </MenuItem>
                              )
                            )}
                          </SelectField>

                          <InputField
                            disabled={isPublic}
                            name={`appliedTransports.${index}.distanceInKilometers`}
                            label="Distancia (km)"
                            type="number"
                            inputProps={{ min: 1, step: 'any' }}
                          />

                          <IconButton
                            disabled={isPublic || !values.appliedTransports[index].type}
                            onClick={() => {
                              openOriginDestinationDialog(
                                values.appliedTransports[index].type,
                                distance => {
                                  setFieldValue(
                                    `appliedTransports.${index}.distanceInKilometers`,
                                    distance
                                  );
                                  // console.log(distance)
                                  // replace(index, {
                                  //   ...values.appliedTransports[index],
                                  //   distance,
                                  // })
                                }
                              );
                            }}
                          >
                            <SearchIcon />
                          </IconButton>

                          <IconButton
                            disabled={isPublic}
                            onClick={() => {
                              openConfirmDeleteDialog({
                                title: 'Confirmar eliminación',
                                message:
                                  '¿Estás seguro, que deseas eliminar el transporte seleccionado?',
                                callback: () => {
                                  remove(index);
                                  setIsArrayTouched(true);
                                },
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      ))}
                    </Grid>
                    <Cell center middle>
                      <Grid columns="30% 30%" justifyContent="space-around">
                        <Cell>
                          <Button
                            disabled={isPublic}
                            onClick={() => push({ ...emptyTransport })}
                            color="primary"
                          >
                            Agregar Transporte
                          </Button>
                        </Cell>
                        <Cell>
                          <Button
                            disabled={!isArrayTouched && (isPublic || !isEmpty(errors) || !dirty)}
                            onClick={handleSubmit}
                            color="primary"
                          >
                            {' '}
                            Guardar cambios
                          </Button>
                        </Cell>
                      </Grid>
                    </Cell>
                  </Grid>
                )}
              </FieldArray>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default ComponentTransports;

export enum LcStage {
  EXTRACCION_MATERIA_PRIMA = 'EXTRACCION_MATERIA_PRIMA',
  MANUFACTURA = 'MANUFACTURA',
  TRANPOSRTE = 'TRANPOSRTE',
  DISPOSICION_FINAL = 'DISPOSICION_FINAL',
}

export const LcStageMap = {
  EXTRACCION_MATERIA_PRIMA: 'Extraccion Materia Prima',
  MANUFACTURA: 'Manufactura',
  TRANPOSRTE: 'Transporte',
  DISPOSICION_FINAL: 'Disposicion Final',
};

export default LcStage;

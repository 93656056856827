import React from 'react';

import { ModalContextType } from './types'

const strings = {
  errors: {
    invariantViolation: 'Attempted to call useModal outside of context.',
  }
};

const invariantViolation = () => {
  throw new Error(strings.errors.invariantViolation);
};

const ModalContext = React.createContext<ModalContextType>({
  addModal: invariantViolation,
  openModal: invariantViolation,
  closeModal: invariantViolation,
  deleteModal: invariantViolation,
});

export default ModalContext;





import React, { useCallback } from 'react';
import useMethods from 'use-methods';

import ModalContext from './ModalContext';
import { ModalMap } from './types';
import ModalRoot from './ModalRoot';

const initialState: { modals: ModalMap } = {
  modals: {}
};  

const methods = ({ modals }) => ({

  addModal(key, component, closeModal) {
    const newEntrie = {
      [key]: {
        component,
        props: { isOpen: false, closeModal }
      }
    };
    return { modals: { ...modals, ...newEntrie } };
  },
  openModal(key) {
    let entrie = modals[key];
    if (entrie) {
      entrie = { ...entrie, props: { ...entrie.props, isOpen: true } }
    }
    return { modals: { ...modals, [key]: entrie } };
  },
  closeModal(key) {
    let entrie = modals[key];
    if (entrie) {
      entrie = { ...entrie, props: { ...entrie.props, isOpen: false } }
    }
    return { modals: { ...modals, [key]: entrie } };
  },
  deleteModal(key) {
    let filteredModals = { ...modals };
    delete filteredModals[key]
    return { modals: filteredModals };
  }
});

const ModalProvider = ({ children }) => {
  const [{ modals }, contextValue] = useMethods(methods, initialState);
  return (
    <ModalContext.Provider value={contextValue} >
      {children}
      <ModalRoot modals={modals} />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
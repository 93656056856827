export enum FunctionalUnit {
  GRAMS = 'GRAMS',
  KILOGRAMS = 'KILOGRAMS',
  LITERS = 'LITERS',
  MILLILITERS = 'MILLILITERS',
  UNITS = 'UNITS',
}

export const FunctionalUnitMap = {
  GRAMS: 'gr',
  KILOGRAMS: 'kg',
  LITERS: 'lt',
  MILLILITERS: 'ml',
  UNITS: 'unidades',
};

export default FunctionalUnit;

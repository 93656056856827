import React from 'react';
import {
  Typography,
} from '@material-ui/core'
import { Grid, Cell } from 'styled-css-grid';
import { Flex } from 'rebass'

import { FunctionalUnitMap } from 'consts';
import { ResultTypes } from 'consts/results'

import EcoequivalencyGEITable from './EcoequivalencyGEITable';


export const EcoequivalencyGEI = ({ components = [], project }) => {

  const mappedComponents = components.map(({ name, resultsTree }) => {
    const resultsTreeObject = JSON.parse(resultsTree);
    return {
      name,
      gei: Object.values(resultsTreeObject['EMISIONES_GEI'])
        .reduce((a, b) => (a + b), 0),
    };
  });

  const projectFunctionalUnit = (project && project.functionalUnit);
  const projectMagnitude = (project && project.functionalUnitMagnitude);

  return (
    <Grid
      columns={1}
      rowGap='2em'
    >

      <Typography variant='h6'>
        <i>Las emisiones de gases de efecto invernadero generadas por cada producto por
        <b>{` ${projectMagnitude} ${FunctionalUnitMap[projectFunctionalUnit]} `}</b>
          de capacidad son equivalentes a lo siguiente:
        </i>
      </Typography>
      <Flex justifyContent='center'>
        <EcoequivalencyGEITable components={mappedComponents} />
      </Flex>
    </Grid>
  );
}

export default EcoequivalencyGEI;
import React, { useCallback } from 'react';
import { css } from 'styled-components/macro';
import { Formik } from 'formik';
import { useMutation } from 'react-apollo-hooks';
import * as Yup from 'yup';
import { Grid, Cell } from 'styled-css-grid';
import { isEmpty } from 'lodash';

import Button from 'components/styled/Button';
import { Form } from 'components/Form';
import { PercentageInputField } from 'components/PercentageInputField';
import { COMPONENT_UPDATE } from 'gql/mutations';

import { renewableVirginMaterialPercentage, nonRenewableVirginMaterialPercentage } from 'consts';

import Tooltip from 'components/Tooltip';

const fieldSchema = Yup.number()
  .min(0, 'Porcentaje no puede ser negativo')
  .max(100, 'Porcentaje no puede superar el 100%')
  .required('Campo no puede ser vacio');

const validationSchema = Yup.object().shape({
  recycledMaterial: fieldSchema,
  renewableMaterial: fieldSchema,
  nonRenewableMaterial: fieldSchema,
});

const childrenMessage = 'No se puede editar este campo si existen subcomponentes.';

function ComponentOrigin({ component = {}, project = {} }) {
  const updateComponentMutation = useMutation(COMPONENT_UPDATE);

  const {
    id,
    origin: { recycledMaterial, renewableMaterial, nonRenewableMaterial },
    children,
  } = component;

  const { isPublic } = project;

  const updateComponentSubmit = useCallback(
    async (values, actions) => {
      const input = { id, ...values };
      updateComponentMutation({ variables: { input } });
    },
    [id]
  );

  const initialValues = {
    id,
    recycledMaterial,
    renewableMaterial,
    nonRenewableMaterial: 100 - (recycledMaterial + renewableMaterial),
  };

  const handleRecycleChange = ({ values, setFieldValue }) => event => {
    const { name, value } = event.target;
    const currentValues = { ...values, [name]: Number(value) };
    const { recycledMaterial, renewableMaterial } = currentValues;
    setFieldValue('nonRenewableMaterial', 100 - (recycledMaterial + renewableMaterial));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={updateComponentSubmit}
    >
      {({
        errors,
        dirty,
        handleSubmit,
        setFieldValue,
        values,
        resetForm,
        initialValues: formikInitialValues,
      }) => {
        return (
          <Form>
            <Grid
              columns={'1fr'}
              rows={'auto'}
              rowGap="2rem"
              css={css`
                padding-top: 1rem;
              `}
            >
              <Cell center>
                <PercentageInputField
                  name="recycledMaterial"
                  label="Porcentaje de material reciclado"
                  onChange={handleRecycleChange({ values, setFieldValue })}
                  disabled={isPublic || !isEmpty(component.children)}
                  helperText={errors.weight || (!isEmpty(component.children) && childrenMessage)}
                />
              </Cell>

              <Cell center>
                <Tooltip text={renewableVirginMaterialPercentage}>
                  <PercentageInputField
                    name="renewableMaterial"
                    label="Porcentaje de material virgen renovable"
                    onChange={handleRecycleChange({ values, setFieldValue })}
                    disabled={isPublic || !isEmpty(component.children)}
                    helperText={errors.weight || (!isEmpty(component.children) && childrenMessage)}
                  />
                </Tooltip>
              </Cell>

              <Cell center>
                <Tooltip text={nonRenewableVirginMaterialPercentage}>
                  <PercentageInputField
                    name="nonRenewableMaterial"
                    label="Porcentaje de material virgen no renovable"
                    disabled={isPublic || true}
                  />
                </Tooltip>
              </Cell>

              <Cell center middle>
                <Grid columns="30% 30%" justifyContent="space-around">
                  <Cell left={2}>
                    <Button
                      disabled={isPublic || !(dirty && isEmpty(errors))}
                      onClick={handleSubmit}
                      color="primary"
                    >
                      Guardar cambios
                    </Button>
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ComponentOrigin;

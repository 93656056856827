import apolloClient from 'apollo-boost';

import { GRAPHQL_SERVER_URI } from 'consts'

export const createApolloClient = ({ uri = GRAPHQL_SERVER_URI, authToken }) => {
  const config = ({
    uri,
    request: async operation => {
      if (authToken) {
        operation.setContext({
          headers: { authorization: authToken }
        });
      }
    }
  });
  const client = new apolloClient(config);
  return client;
}

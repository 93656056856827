import React, { useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-apollo-hooks';

import { SIGNUP } from 'gql/mutations';
import { InputField } from 'components/InputField';
import { rutValidator, mergeObjects } from 'utils';
import { useSnackbar, useAuthToken } from 'hooks';


// TODO set to const or project schema
const maxNameLength = 100;

// TODO refactor fields in new file
const fields = [
  {
    name: 'email',
    initialValue: '',
    label: 'Email*',
    validation:
      Yup.string()
        .email('Email invalido')
        .required('Requerido'),
    InputComponent: InputField,
  },
  {
    name: 'password',
    initialValue: '',
    label: 'Contraseña*',
    validation:
      Yup.string()
        .required('Requerido'),
    InputComponent: InputField,
    componentProps: {
      type: 'password'
    }
  },
  {
    name: 'name',
    initialValue: '',
    label: 'Nombre*',
    validation:
      Yup.string()
        .max(maxNameLength, 'Muy largo')
        .required('Requerido'),
    InputComponent: InputField,
  },
  {
    name: 'lastName',
    initialValue: '',
    label: 'Apellidos*',
    validation:
      Yup.string()
        .max(maxNameLength, 'Muy largo')
        .required('Requerido'),
    InputComponent: InputField,
  },
  {
    name: 'rut',
    initialValue: '',
    label: 'RUT*',
    validation:
      Yup.string()
        .test('rut', 'Rut invalido', rutValidator)
        .required('Requerido'),
    InputComponent: InputField,
  },
  {
    name: 'nationality',
    initialValue: '',
    label: 'Nacionalidad',
    validation:
      Yup.string()
        .max(maxNameLength, 'Muy largo'),
    InputComponent: InputField,
  },
  {
    name: 'phone',
    initialValue: '',
    label: 'Teléfono fijo',
    validation:
      Yup.string()
        .max(maxNameLength, 'Muy largo'),
    InputComponent: InputField,
  },
  {
    name: 'mobilePhone',
    initialValue: '',
    label: 'Teléfono móvil*',
    validation:
      Yup.string()
        .max(maxNameLength, 'Muy largo')
        .required('Requerido'),
    InputComponent: InputField,
  },
  {
    name: 'region',
    initialValue: '',
    label: 'Región',
    validation:
      Yup.string()
        .max(maxNameLength, 'Muy largo'),
    InputComponent: InputField,
  },
  {
    name: 'commune',
    initialValue: '',
    label: 'Comuna',
    validation:
      Yup.string()
        .max(maxNameLength, 'Muy largo'),
    InputComponent: InputField,
  },
  {
    name: 'company',
    initialValue: '',
    label: 'Organización',
    validation:
      Yup.string()
        .max(maxNameLength, 'Muy largo'),
    InputComponent: InputField,
  },
  {
    name: 'productiveSector',
    initialValue: '',
    label: 'Sector productivo',
    validation:
      Yup.string()
        .max(maxNameLength, 'Muy largo'),
    InputComponent: InputField,
  },
];

const initialValues = fields.map(x => ({ [x.name]: x.initialValue })).reduce(mergeObjects);

const validationSchema = Yup.object().shape(
  fields.map(x => ({ [x.name]: x.validation })).reduce(mergeObjects)
);

export const SignupDialog = ({ isOpen, closeModal }) => {
  const signupMutation = useMutation(SIGNUP);
  const { setAuthToken } = useAuthToken();

  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();
  const signupSubmit = useCallback(async (values, actions) => {
    try {
      const { data } = await signupMutation({ variables: { input: values } });
      const { token, errors } = data.signup;
      if (!isEmpty(errors)) throw new Error(errors[0].message);
      setAuthToken(token);
      closeModal();
      enqueueSuccessSnackbar('Usuario creado con éxito');
    } catch (e) {
      enqueueErrorSnackbar(e.message);
    }
  }, [closeModal]);


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={signupSubmit}
      render={({ handleSubmit, errors, dirty }) => (
        <Dialog open={isOpen}>
          <DialogTitle>Registrarse</DialogTitle>
          <DialogContent>
            {fields.map(f => {
              const {
                InputComponent,
                name,
                label,
                componentProps,
              } = f;
              return (

                <InputComponent
                  key={name}
                  helperText={errors[name]}
                  {...{ name, label }}
                  {...(componentProps || {})}
                />
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cerrar</Button>
            <Button onClick={handleSubmit}
              disabled={!(dirty && isEmpty(errors))}
            >
              Registrarse
          </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  )
};

export default SignupDialog;
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { useMutation } from 'react-apollo-hooks';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import useReactRouter from 'use-react-router';

import { Divider } from '@material-ui/core';
import { Grid, Cell } from 'styled-css-grid';
import Button from 'components/styled/Button';
import { Form } from 'components/Form';
import { InputField } from 'components/InputField';
import { useSnackbar, useAuthToken } from 'hooks';
import { LOGIN } from 'gql/mutations';

const strings = {
  button: { login: 'Iniciar sesión' },
  input: {
    email: { label: 'Email' },
    password: { label: 'Contraseña' },
  },
  errors: {
    email: {
      required: 'Email es requerido',
      invalid: 'Email invalido',
    },
    password: {
      required: 'Contraseña es requerida',
    },
  },
};

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(strings.errors.email.invalid)
    .required(strings.errors.email.required),
  password: Yup.string().required(strings.errors.password.required),
});

//TODO layout
export const LoginForm = props => {
  const loginMutation = useMutation(LOGIN);
  const { setAuthToken } = useAuthToken();
  const { enqueueErrorSnackbar } = useSnackbar();
  const { history } = useReactRouter();

  const loginSubmit = useCallback(async (values, actions) => {
    try {
      const { data } = await loginMutation({ variables: { input: values } });
      const { token, errors } = data.login;
      if (!isEmpty(errors)) throw new Error(errors[0].message);
      setAuthToken(token);
      history.push('/');
    } catch (e) {
      enqueueErrorSnackbar(e.message);
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={loginSubmit}
      render={({ handleSubmit, errors, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <Grid
            gap="0px"
            height="100%"
            columns={`1fr`}
            rows={`1fr 1fr 1fr`}
            areas={['email', 'password', 'submit']}
          >
            <Cell area="email" center middle>
              <InputField
                name="email"
                label={strings.input.email.label}
                autoFocus
                autoComplete="email"
              />
            </Cell>
            <Cell area="password" center middle>
              <InputField
                name="password"
                type="password"
                label={strings.input.password.label}
                autoComplete="password"
              />
              <Divider variant="fullWidth" />
            </Cell>
            <Cell area="submit" center middle>
              <Button type="submit" color="primary" disabled={!(dirty && isEmpty(errors))}>
                {strings.button.login}
              </Button>
            </Cell>
          </Grid>
        </Form>
      )}
    />
  );
};

export default LoginForm;

import React, { useEffect, useReducer, useRef, useContext } from 'react';
import { Grid, Cell } from 'styled-css-grid';
import { isEmpty } from 'lodash';
import styled from 'styled-components/macro';
import { domRefToPng } from 'utils';
import { Typography } from '@material-ui/core';

import ComponentsSummaryTable from 'components/ComponentsSummaryTable/ComponentsSummaryTable';
import ImpactComparisonRadar from 'components/ImpactComparisonRadar';
import { PdfImpactComparisonGraphs } from './PdfImpactComparisonGraph';
import { PdfRecycleOriginGraph } from './PdfRecycleOriginGraph';

// TODO get from theme
const greenColor = '#56AF89';

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

const Context = React.createContext(null);

const HiddenDiv = styled.div`
  height: 0px;
  width: 1500px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
`;

const Elem = ({ name, children }) => {
  const { dispatch = () => {} } = useContext(Context);
  const domRef = useRef(null);
  useEffect(async () => {
    async function dispatchImageAction() {
      if (domRef.current) {
        await sleep(800);
        const pngDataUrl = await domRefToPng(domRef);
        dispatch({ type: 'image', payload: { name, pngDataUrl } });
      }
    }
    dispatchImageAction();
  }, []);
  return <div ref={domRef}>{children}</div>;
};

const initialState = {
  images: {},
  imageList: [
    'summaryTable',
    'radar',
    'water',
    'energy',
    'fossil',
    'gei',
    'waste',
    'recycleOrigin',
  ],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'image': {
      const { name, pngDataUrl } = action.payload;
      let { images, imageList } = state;
      images = { ...images, [name]: pngDataUrl };
      imageList = imageList.filter(x => x !== name);
      return { ...state, images, imageList };
    }
    default:
      return state;
  }
};

export const ResultsGraphContainer = ({
  children,
  components,
  project,
  dispatch: PdfResultsDispatch,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const contextValue = { dispatch };

  useEffect(() => {
    if (isEmpty(state.imageList)) {
      PdfResultsDispatch({ type: 'imagesReady', payload: { images: state.images } });
    }
  }, [state.imageList]);
  return (
    <Context.Provider value={contextValue}>
      <HiddenDiv>
        <Elem name="summaryTable">
          <ComponentsSummaryTable components={components} project={project} />
        </Elem>
        <Elem name="radar">
          <ImpactComparisonRadar components={components} />
        </Elem>
        <Elem name="water">
          <Grid columns={2}>
            <Cell width={2}>
              <Typography style={{ color: greenColor, fontSize: '50px' }}>
                Consumo de agua (ml)
              </Typography>
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto absoluto</Typography>
              <PdfImpactComparisonGraphs
                components={components}
                category={'CONSUMO_DE_AGUA'}
                variant={'ABSOLUTE'}
              />
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto relativo</Typography>
              <PdfImpactComparisonGraphs
                components={components}
                category={'CONSUMO_DE_AGUA'}
                variant={'RELATIVE'}
              />
            </Cell>
          </Grid>
        </Elem>

        <Elem name="energy">
          <Grid columns={2}>
            <Cell width={2}>
              <Typography style={{ color: greenColor, fontSize: '50px' }}>
                Consumo de energía (kWh)
              </Typography>
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto absoluto</Typography>
              <PdfImpactComparisonGraphs
                components={components}
                category={'CONSUMO_DE_ENERGIA'}
                variant={'ABSOLUTE'}
              />
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto relativo</Typography>
              <PdfImpactComparisonGraphs
                components={components}
                category={'CONSUMO_DE_ENERGIA'}
                variant={'RELATIVE'}
              />
            </Cell>
          </Grid>
        </Elem>

        <Elem name="fossil">
          <Grid columns={2}>
            <Cell width={2}>
              <Typography style={{ color: greenColor, fontSize: '50px' }}>
                Consumo de combustibles fósiles (kWh)
              </Typography>
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto absoluto</Typography>

              <PdfImpactComparisonGraphs
                components={components}
                category={'CONSUMO_DE_COMBUSTIBLES_FOSILES'}
                variant={'ABSOLUTE'}
              />
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto relativo</Typography>
              <PdfImpactComparisonGraphs
                components={components}
                category={'CONSUMO_DE_COMBUSTIBLES_FOSILES'}
                variant={'RELATIVE'}
              />
            </Cell>
          </Grid>
        </Elem>

        <Elem name="gei">
          <Grid columns={2}>
            <Cell width={2}>
              <Typography style={{ color: greenColor, fontSize: '50px' }}>
                Emisiones GEI (gr CO2e)
              </Typography>
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto absoluto</Typography>

              <PdfImpactComparisonGraphs
                components={components}
                category={'EMISIONES_GEI'}
                variant={'ABSOLUTE'}
              />
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto relativo</Typography>
              <PdfImpactComparisonGraphs
                components={components}
                category={'EMISIONES_GEI'}
                variant={'RELATIVE'}
              />
            </Cell>
          </Grid>
        </Elem>

        <Elem name="waste">
          <Grid columns={2}>
            <Cell width={2}>
              <Typography style={{ color: greenColor, fontSize: '50px' }}>
                Generación de Residuos (gr)
              </Typography>
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto absoluto</Typography>

              <PdfImpactComparisonGraphs
                components={components}
                category={'GENERACION_DE_RESIDUOS'}
                variant={'ABSOLUTE'}
              />
            </Cell>
            <Cell width={1}>
              <Typography style={{ fontSize: '30px' }}>Impacto relativo</Typography>
              <PdfImpactComparisonGraphs
                components={components}
                category={'GENERACION_DE_RESIDUOS'}
                variant={'RELATIVE'}
              />
            </Cell>
          </Grid>
        </Elem>

        <Elem name="recycleOrigin">
          <Typography style={{ color: greenColor, fontSize: '50px' }}>
            Porcentaje de material por tipo de origen
          </Typography>
          <Typography style={{ fontSize: '30px' }}>
            Porcentaje de material según origen, para cada producto
          </Typography>
          <PdfRecycleOriginGraph components={components} />
        </Elem>
      </HiddenDiv>
    </Context.Provider>
  );
};

export default ResultsGraphContainer;

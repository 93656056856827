const transportOptions = {
	'AEREO': {
		'Alemania -  Berlin-Schonefeld': [{
			destiny: 'Chile -  Santiago',
			distance: 12595
		}],
		'Alemania -  Dusseldorf': [{
			destiny: 'Chile -  Santiago',
			distance: 12125
		}],
		'Alemania -  Frankfurt': [{
			destiny: 'Chile -  Santiago',
			distance: 12181
		}],
		'Alemania -  Hamburgo': [{
			destiny: 'Chile -  Santiago',
			distance: 12444
		}],
		'Alemania -  Munich': [{
			destiny: 'Chile -  Santiago',
			distance: 12259
		}],
		'Argentina -  Ezeiza (ministro Pistarini)': [{
			destiny: 'Chile -  Santiago',
			distance: 1223
		}],
		'Australia -  Sidney': [{
			destiny: 'Chile -  Santiago',
			distance: 11284
		}],
		'Brasil -  Brasilia': [{
			destiny: 'Chile -  Santiago',
			distance: 3091
		}],
		'Brasil -  Congonhas': [{
			destiny: 'Chile -  Santiago',
			distance: 3095
		}],
		'Brasil -  Guarulhos': [{
			destiny: 'Chile -  Santiago',
			distance: 2687
		}],
		'Brasil -  Porto Alegre': [{
			destiny: 'Chile -  Santiago',
			distance: 1962
		}],
		'Brasil -  Salvador': [{
			destiny: 'Chile -  Santiago',
			distance: 4055
		}],
		'Canada -  Vancouver': [{
			destiny: 'Chile -  Santiago',
			distance: 10523
		}],
		'China -  Hong Kong': [{
			destiny: 'Chile -  Santiago',
			distance: 18700
		}],
		'Colombia -  Bogota': [{
			destiny: 'Chile -  Santiago',
			distance: 4256
		}],
		'Corea del Sur -  Incheon': [{
			destiny: 'Chile -  Santiago',
			distance: 18306
		}],
		'Ecuador -  Quito': [{
			destiny: 'Chile -  Santiago',
			distance: 3780
		}],
		'EEUU -  New York': [{
			destiny: 'Chile -  Santiago',
			distance: 8266
		}],
		'España -  Barcelona (cataluña)': [{
			destiny: 'Chile -  Santiago',
			distance: 11233
		}],
		'España -  Madrid Barajas': [{
			destiny: 'Chile -  Santiago',
			distance: 10775
		}],
		'España -  Málaga': [{
			destiny: 'Chile -  Santiago',
			distance: 10466
		}],
		'España -  Mallorca (islas baleares)': [{
			destiny: 'Chile -  Santiago',
			distance: 11149
		}],
		'Francia -  Charles De Gaulle': [{
			destiny: 'Chile -  Santiago',
			distance: 11718
		}],
		'Holanda -  Amsterdam': [{
			destiny: 'Chile -  Santiago',
			distance: 12089
		}],
		'India -  Indira Gandhi- Delhi': [{
			destiny: 'Chile -  Santiago',
			distance: 16779
		}],
		'Italia -  Roma-Fiumicino': [{
			destiny: 'Chile -  Santiago',
			distance: 11988
		}],
		'Japon -  Kansai': [{
			destiny: 'Chile -  Santiago',
			distance: 16526
		}],
		'Japon -  Narita': [{
			destiny: 'Chile -  Santiago',
			distance: 17154
		}],
		'Mexico -  Benito Juárez': [{
			destiny: 'Chile -  Santiago',
			distance: 6579
		}],
		'Mexico -  Cancún': [{
			destiny: 'Chile -  Santiago',
			distance: 6301
		}],
		'Mexico -  Gral Mariano Escobedo': [{
			destiny: 'Chile -  Santiago',
			distance: 7255
		}],
		'Mexico -  Miguel Hidalgo Costilla': [{
			destiny: 'Chile -  Santiago',
			distance: 6910
		}],
		'Mexico -  Tijuana': [{
			destiny: 'Chile -  Santiago',
			distance: 8751
		}],
		'Paraguay -  Silvio Pettirossi- Luque': [{
			destiny: 'Chile -  Santiago',
			distance: 1708
		}],
		'Peru -  Lima': [{
			destiny: 'Chile -  Santiago',
			distance: 2459
		}],
		'Reino Unido -  Heathrow': [{
			destiny: 'Chile -  Santiago',
			distance: 11811
		}],
		'Tailandia -  Bangkok': [{
			destiny: 'Chile -  Santiago',
			distance: 17669
		}],
		'Vietnam -  Tan Son Nhat- Vietnam': [{
			destiny: 'Chile -  Santiago',
			distance: 17843
		}]
	},
	'MARITIMO': {
		'Alemania - Bremen': [{
			destiny: 'Chile - Coquimbo',
			distance: 14061.1248
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 14369.668
		}, {
			destiny: 'Chile - San Antonio',
			distance: 14460.416
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14736.9196
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 14372.446
		}],
		'Alemania - Bremerhaven': [{
			destiny: 'Chile - Coquimbo',
			distance: 13965.5616
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 14273.364
		}, {
			destiny: 'Chile - San Antonio',
			distance: 14365.964
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14641.3564
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 14276.8828
		}],
		'Alemania - Hamburgo': [{
			destiny: 'Chile - Coquimbo',
			distance: 14034.456
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 14256.1404
		}, {
			destiny: 'Chile - San Antonio',
			distance: 14434.488
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14710.2508
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 14345.777200000002
		}],
		'Argentina - Zárate': [{
			destiny: 'Chile - Coquimbo',
			distance: 5105.5936
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2746.7012
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5478.216
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5268.754800000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5477.6604
		}],
		'Argentina - Rosario': [{
			destiny: 'Chile - Coquimbo',
			distance: 5458.3996
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2948.1988
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5744.904
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5469.696800000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5830.4664
		}],
		'Argentina - Bahía Blanca': [{
			destiny: 'Chile - Coquimbo',
			distance: 4152.5544
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 1790.5136
		}, {
			destiny: 'Chile - San Antonio',
			distance: 4544.808
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 4312.7524
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 4524.6212000000005
		}],
		'Australia - Sydney': [{
			destiny: 'Chile - Coquimbo',
			distance: 12566.0052
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 15589.580400000004
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13015.856
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13380.7
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 12938.072
		}],
		'Australia - Islas Christmas': [{
			destiny: 'Chile - Coquimbo',
			distance: 9382.417200000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 12405.9924
		}, {
			destiny: 'Chile - San Antonio',
			distance: 9832.268
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 10197.112
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 9754.484
		}],
		'Brazil - Santos': [{
			destiny: 'Chile - Coquimbo',
			distance: 12901.958
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 3925.8696
		}, {
			destiny: 'Chile - San Antonio',
			distance: 6572.7480000000005
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 6512.0024
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 6555.709600000001
		}],
		'Brazil - Río Grande': [{
			destiny: 'Chile - Coquimbo',
			distance: 13880.369600000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2912.4552
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5485.624000000001
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5498.588000000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5549.8884
		}],
		'Brazil - Paranaguá': [{
			destiny: 'Chile - Coquimbo',
			distance: 13153.4596
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 3616.956
		}, {
			destiny: 'Chile - San Antonio',
			distance: 6770.912
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 6142.5284
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 6351.6192
		}],
		'Canada - Bahía Departure, isla Vancouver': [{
			destiny: 'Chile - Coquimbo',
			distance: 10644.9256
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 12832.3228
		}, {
			destiny: 'Chile - San Antonio',
			distance: 10903.0944
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 11052.5508
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 11032.364
		}],
		'Chile - Puerto Montt': [{
			destiny: 'Chile - Coquimbo',
			distance: 1817.3676
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 1853.6668
		}, {
			destiny: 'Chile - San Antonio',
			distance: 1096.7544
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 764.3204000000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 1131.572
		}],
		'Chile - San Vicente': [{
			destiny: 'Chile - Coquimbo',
			distance: 787.1
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2442.0472
		}, {
			destiny: 'Chile - San Antonio',
			distance: 405.4028
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 9.26
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 451.888
		}],
		'Chile - Talcahuano': [{
			destiny: 'Chile - Coquimbo',
			distance: 778.3956000000001
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2512.9788000000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 382.6232
		}, {
			destiny: 'Alemania - Bremen',
			distance: 14736.9196
		}, {
			destiny: 'Alemania - Bremerhaven',
			distance: 14641.3564
		}, {
			destiny: 'Alemania - Hamburgo',
			distance: 14710.2508
		}, {
			destiny: 'Argentina - Zárate',
			distance: 5268.754800000001
		}, {
			destiny: 'Argentina - Rosario',
			distance: 5469.696800000001
		}, {
			destiny: 'Argentina - Bahía Blanca',
			distance: 4312.7524
		}, {
			destiny: 'Australia - Sydney',
			distance: 13380.7
		}, {
			destiny: 'Australia - Islas Christmas',
			distance: 10197.112
		}, {
			destiny: 'Brazil - Santos',
			distance: 6512.0024
		}, {
			destiny: 'Brazil - Río Grande',
			distance: 5498.588000000001
		}, {
			destiny: 'Brazil - Paranaguá',
			distance: 6142.5284
		}, {
			destiny: 'Canada - Bahía Departure, isla Vancouver',
			distance: 11052.5508
		}, {
			destiny: 'Chile - Puerto Montt',
			distance: 764.3204000000001
		}, {
			destiny: 'Chile - San Vicente',
			distance: 9.26
		}, {
			destiny: 'Chile - Arica',
			distance: 2049.2380000000003
		}, {
			destiny: 'Chile - Iquique',
			distance: 1787.9208
		}, {
			destiny: 'Chile - Antofagasta',
			distance: 1474.7476
		}, {
			destiny: 'Chile - Chacabuco',
			distance: 1115.6448
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2512.9788000000003
		}, {
			destiny: 'China - Takú Bar',
			distance: 19855.292
		}, {
			destiny: 'China - Hong Kong',
			distance: 21229.1056
		}, {
			destiny: 'China - Shanghai',
			distance: 20152.1676
		}, {
			destiny: 'Colombia - Buenaventura',
			distance: 4805.9400000000005
		}, {
			destiny: 'Colombia - Tumaco',
			distance: 4477.21
		}, {
			destiny: 'Corea del Sur - Busan',
			distance: 19568.232
		}, {
			destiny: 'Corea del Sur - Incheon',
			distance: 20221.2472
		}, {
			destiny: 'Costa Rica - Puntarenas',
			distance: 5320.0552
		}, {
			destiny: 'Cuba - La Habana',
			distance: 7285.5828
		}, {
			destiny: 'Cuba - Santiago de Cuba',
			distance: 6604.4172
		}, {
			destiny: 'Ecuador - Esmeraldas',
			distance: 4265.8968
		}, {
			destiny: 'Ecuador - Guayaquil',
			distance: 4016.617600000001
		}, {
			destiny: 'EEUU - Seattle',
			distance: 11723.345200000002
		}, {
			destiny: 'EEUU - Los Ángeles',
			distance: 9569.6544
		}, {
			destiny: 'EEUU - Nueva York',
			distance: 8958.679600000001
		}, {
			destiny: 'EEUU - Charleston',
			distance: 8196.026
		}, {
			destiny: 'El Salvador - Acajutla',
			distance: 5955.6616
		}, {
			destiny: 'El Salvador - La Unión',
			distance: 5843.615600000001
		}, {
			destiny: 'España - Cádiz',
			distance: 13346.623200000002
		}, {
			destiny: 'España - El Ferrol',
			distance: 13126.7908
		}, {
			destiny: 'Fiji - Isla Viti Levu',
			distance: 11108.296
		}, {
			destiny: 'Filipinas - Manila',
			distance: 18425.548000000003
		}, {
			destiny: 'Francia - Burdeos',
			distance: 13886.296
		}, {
			destiny: 'Francia - Cherburgo',
			distance: 13720.7272
		}, {
			destiny: 'Francia - Brest',
			distance: 15668.6608
		}, {
			destiny: 'Guatemala - Champerico',
			distance: 6037.149600000001
		}, {
			destiny: 'Guatemala - San José',
			distance: 6043.076
		}, {
			destiny: 'Holanda - Rotterdam',
			distance: 14187.4312
		}, {
			destiny: 'Holanda - Amsterdam',
			distance: 14389.4844
		}, {
			destiny: 'Honduras - Amapala',
			distance: 5838.43
		}, {
			destiny: 'India - Calcuta',
			distance: 26696.58
		}, {
			destiny: 'India - Mundra',
			distance: 18164.7864
		}, {
			destiny: 'India - Chennai',
			distance: 18713.719200000003
		}, {
			destiny: 'India - Jawaharlal Nehru',
			distance: 18087.1876
		}, {
			destiny: 'Islas Marshall - Isla Wotje',
			distance: 13662.204000000002
		}, {
			destiny: 'Islas Marshall - Isla Jaluit',
			distance: 13469.596
		}, {
			destiny: 'Islas Salomon - B. Selwyn',
			distance: 13045.488
		}, {
			destiny: 'Italia - Livorno',
			distance: 15037.6844
		}, {
			destiny: 'Italia - Venecia',
			distance: 16588.9196
		}, {
			destiny: 'Italia - Cagliari',
			distance: 14753.032
		}, {
			destiny: 'Japon - Sasebo',
			distance: 18658.9
		}, {
			destiny: 'Japon - Kobe',
			distance: 18099.596
		}, {
			destiny: 'Japon - Hakodate',
			distance: 17475.472
		}, {
			destiny: 'Kiribati - Isla Nonouti, islas Gilbert del Sur',
			distance: 12606.564
		}, {
			destiny: 'Mexico - Ensenada',
			distance: 9405.7524
		}, {
			destiny: 'Mexico - Veracruz',
			distance: 8059.533600000001
		}, {
			destiny: 'Mexico - Manzanillo',
			distance: 7337.624000000001
		}, {
			destiny: 'Micronesia - Tomil Harbour, isla Yap, islas Carolinas',
			distance: 16456.872
		}, {
			destiny: 'Micronesia - Isla Guam, islas Marianas',
			distance: 16203.148
		}, {
			destiny: 'Micronesia - Islas Truk, islas Carolinas',
			distance: 15169.732
		}, {
			destiny: 'Micronesia - Isla Ponapé, islas Carolinas',
			distance: 14575.240000000002
		}, {
			destiny: 'Nicaragua - Corinto',
			distance: 5654.7116000000005
		}, {
			destiny: 'Nicaragua - San Juan del Sur',
			distance: 5493.9580000000005
		}, {
			destiny: 'Nueva Zelandia - Auckland',
			distance: 9274.816
		}, {
			destiny: 'Nueva Zelandia - Isla Raoul, islas Kermadec',
			distance: 9169.252
		}, {
			destiny: 'Nueva Zelandia - Wellington',
			distance: 8939.604000000001
		}, {
			destiny: 'Palaos - Malakal, islas Palau',
			distance: 16567.992000000002
		}, {
			destiny: 'Panama - Colón',
			distance: 5250.2348
		}, {
			destiny: 'Panama - Balboa',
			distance: 5214.3060000000005
		}, {
			destiny: 'Papua Nueva Guinea - Rabaul, isla Nueva Bretaña',
			distance: 14260.4
		}, {
			destiny: 'Peru - Paita',
			distance: 3650.4772
		}, {
			destiny: 'Peru - Salaverry',
			distance: 3348.416
		}, {
			destiny: 'Peru - Callao',
			distance: 2783.3708
		}, {
			destiny: 'Peru - Caleta Matarani',
			distance: 2179.9892
		}, {
			destiny: 'Peru - Ilo',
			distance: 2120.54
		}, {
			destiny: 'Polinesia - Isla Funafuti (islas Tuvalu)',
			distance: 11689.824
		}, {
			destiny: 'Portugal - Lisboa',
			distance: 13107.53
		}, {
			destiny: 'Portugal - San Vicente, isla Cabo Verde',
			distance: 11373.132
		}, {
			destiny: 'Reino Unido - Londres, Inglaterra',
			distance: 14237.6204
		}, {
			destiny: 'Reino Unido - Southampton, Inglaterra',
			distance: 13779.4356
		}, {
			destiny: 'Reino Unido - Liverpool, Inglaterra',
			distance: 13814.2532
		}, {
			destiny: 'Reino Unido - Plymouth, Inglaterra',
			distance: 14229.1012
		}, {
			destiny: 'Rusia - Vladivostok',
			distance: 18772.6128
		}, {
			destiny: 'Tailandia - Bangkok',
			distance: 21453.938400000003
		}, {
			destiny: 'Tailandia - Laem Chabang',
			distance: 23895.9856
		}, {
			destiny: 'Vietnam - Ho Chi Minh',
			distance: 22952.021200000003
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 442.628
		}],
		'Chile - San Antonio': [{
			destiny: 'Chile - Coquimbo',
			distance: 436.146
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2663.7316
		}, {
			destiny: 'Alemania - Bremen',
			distance: 14460.416
		}, {
			destiny: 'Alemania - Bremerhaven',
			distance: 14365.964
		}, {
			destiny: 'Alemania - Hamburgo',
			distance: 14434.488
		}, {
			destiny: 'Argentina - Zárate',
			distance: 5478.216
		}, {
			destiny: 'Argentina - Rosario',
			distance: 5744.904
		}, {
			destiny: 'Argentina - Bahía Blanca',
			distance: 4544.808
		}, {
			destiny: 'Australia - Sydney',
			distance: 13015.856
		}, {
			destiny: 'Australia - Islas Christmas',
			distance: 9832.268
		}, {
			destiny: 'Brazil - Santos',
			distance: 6572.7480000000005
		}, {
			destiny: 'Brazil - Río Grande',
			distance: 5485.624000000001
		}, {
			destiny: 'Brazil - Paranaguá',
			distance: 6770.912
		}, {
			destiny: 'Canada - Bahía Departure, isla Vancouver',
			distance: 10903.0944
		}, {
			destiny: 'Chile - Arica',
			distance: 1756.0664000000002
		}, {
			destiny: 'Chile - Iquique',
			distance: 1517.8992
		}, {
			destiny: 'Chile - Antofagasta',
			distance: 1137.3132
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 382.6232
		}, {
			destiny: 'Chile - San Vicente',
			distance: 405.4028
		}, {
			destiny: 'Chile - Puerto Montt',
			distance: 1096.7544
		}, {
			destiny: 'Chile - Chacabuco',
			distance: 1448.0788
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2663.7316
		}, {
			destiny: 'China - Hong Kong',
			distance: 20159.5756
		}, {
			destiny: 'China - Shanghai',
			distance: 19077.8224
		}, {
			destiny: 'China - Takú Bar',
			distance: 19334.88
		}, {
			destiny: 'Colombia - Buenaventura',
			distance: 4539.9928
		}, {
			destiny: 'Colombia - Tumaco',
			distance: 4192.1872
		}, {
			destiny: 'Corea del Sur - Busan',
			distance: 18331.096
		}, {
			destiny: 'Corea del Sur - Incheon',
			distance: 19034.856
		}, {
			destiny: 'Costa Rica - Puntarenas',
			distance: 5035.0324
		}, {
			destiny: 'Cuba - La Habana',
			distance: 6994.078
		}, {
			destiny: 'Cuba - Santiago de Cuba',
			distance: 6313.097600000001
		}, {
			destiny: 'Ecuador - Esmeraldas',
			distance: 4005.5056
		}, {
			destiny: 'Ecuador - Guayaquil',
			distance: 3748.6332
		}, {
			destiny: 'EEUU - Seattle',
			distance: 11303.1264
		}, {
			destiny: 'EEUU - Los Ángeles',
			distance: 9196.8468
		}, {
			destiny: 'EEUU - Nueva York',
			distance: 8667.174799999999
		}, {
			destiny: 'EEUU - Charleston',
			distance: 7904.7064
		}, {
			destiny: 'El Salvador - Acajutla',
			distance: 5645.4516
		}, {
			destiny: 'El Salvador - La Unión',
			distance: 5533.2204
		}, {
			destiny: 'España - Cádiz',
			distance: 13055.3036
		}, {
			destiny: 'España - El Ferrol',
			distance: 12835.286
		}, {
			destiny: 'Fiji - Isla Viti Levu',
			distance: 10743.452
		}, {
			destiny: 'Filipinas - Manila',
			distance: 18060.704
		}, {
			destiny: 'Francia - Burdeos',
			distance: 13521.452
		}, {
			destiny: 'Francia - Cherburgo',
			distance: 13429.2224
		}, {
			destiny: 'Francia - Brest',
			distance: 15819.784
		}, {
			destiny: 'Guatemala - Champerico',
			distance: 5805.649600000001
		}, {
			destiny: 'Guatemala - San José',
			distance: 5678.232
		}, {
			destiny: 'Holanda - Rotterdam',
			distance: 13901.112
		}, {
			destiny: 'Holanda - Amsterdam',
			distance: 14114.092
		}, {
			destiny: 'Honduras - Amapala',
			distance: 5528.0348
		}, {
			destiny: 'India - Calcuta',
			distance: 26020.6
		}, {
			destiny: 'India - Mundra',
			distance: 28150.4
		}, {
			destiny: 'India - Chennai',
			distance: 25896.516
		}, {
			destiny: 'India - Jawaharlal Nehru',
			distance: 22296.228000000003
		}, {
			destiny: 'Islas Marshall - Isla Wotje',
			distance: 13297.36
		}, {
			destiny: 'Islas Marshall - Isla Jaluit',
			distance: 13104.752
		}, {
			destiny: 'Italia - Livorno',
			distance: 14762.292
		}, {
			destiny: 'Italia - Venecia',
			distance: 16316.12
		}, {
			destiny: 'Italia - Cagliari',
			distance: 14477.084
		}, {
			destiny: 'Japon - Sasebo',
			distance: 18294.056
		}, {
			destiny: 'Japon - Kobe',
			distance: 17734.752
		}, {
			destiny: 'Japon - Hakodate',
			distance: 17110.628
		}, {
			destiny: 'Kiribati - Isla Nonouti, islas Gilbert del Sur',
			distance: 12241.72
		}, {
			destiny: 'Mexico - Ensenada',
			distance: 8973.3104
		}, {
			destiny: 'Mexico - Veracruz',
			distance: 7768.0288
		}, {
			destiny: 'Mexico - Manzanillo',
			distance: 7027.228800000001
		}, {
			destiny: 'Micronesia - Tomil Harbour, isla Yap, islas Carolinas',
			distance: 16092.028
		}, {
			destiny: 'Micronesia - Isla Guam, islas Marianas',
			distance: 15838.304
		}, {
			destiny: 'Micronesia - Islas Truk, islas Carolinas',
			distance: 14804.888
		}, {
			destiny: 'Micronesia - Isla Ponapé, islas Carolinas',
			distance: 14210.396
		}, {
			destiny: 'Nicaragua - Corinto',
			distance: 5376.170800000001
		}, {
			destiny: 'Nicaragua - San Juan del Sur',
			distance: 5211.7132
		}, {
			destiny: 'Nueva Zelandia - Auckland',
			distance: 9795.228
		}, {
			destiny: 'Nueva Zelandia - Isla Raoul, islas Kermadec',
			distance: 9689.664
		}, {
			destiny: 'Nueva Zelandia - Wellington',
			distance: 9460.016
		}, {
			destiny: 'Palaos - Malakal, islas Palau',
			distance: 16203.148
		}, {
			destiny: 'Panama - Colón',
			distance: 5000.400000000001
		}, {
			destiny: 'Panama - Balboa',
			distance: 4946.692
		}, {
			destiny: 'Papua Nueva Guinea - Rabaul, isla Nueva Bretaña',
			distance: 13895.556
		}, {
			destiny: 'Peru - Paita',
			distance: 3388.9748000000004
		}, {
			destiny: 'Peru - Salaverry',
			distance: 2983.572
		}, {
			destiny: 'Peru - Callao',
			distance: 2478.7168
		}, {
			destiny: 'Peru - Caleta Matarani',
			distance: 1881.632
		}, {
			destiny: 'Peru - Ilo',
			distance: 1789.958
		}, {
			destiny: 'Polinesia - Isla Funafuti (islas Tuvalu)',
			distance: 11324.98
		}, {
			destiny: 'Portugal - Lisboa',
			distance: 12816.025200000002
		}, {
			destiny: 'Portugal - San Vicente, isla Cabo Verde',
			distance: 11008.288
		}, {
			destiny: 'Reino Unido - Londres, Inglaterra',
			distance: 13946.1156
		}, {
			destiny: 'Reino Unido - Southampton, Inglaterra',
			distance: 13487.9308
		}, {
			destiny: 'Reino Unido - Liverpool, Inglaterra',
			distance: 13522.9336
		}, {
			destiny: 'Reino Unido - Plymouth, Inglaterra',
			distance: 13937.7816
		}, {
			destiny: 'Rusia - Vladivostok',
			distance: 18073.1124
		}, {
			destiny: 'Tailandia - Bangkok',
			distance: 23233.34
		}, {
			destiny: 'Tailandia - Laem Chabang',
			distance: 23211.116
		}, {
			destiny: 'Vietnam - Ho Chi Minh',
			distance: 21874.3424
		}, {
			destiny: 'Islas Salomon - B. Selwyn',
			distance: 12680.644
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 77.784
		}],
		'Chile - Arica': [{
			destiny: 'Chile - Coquimbo',
			distance: 1290.6588
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 4532.0292
		}, {
			destiny: 'Chile - San Antonio',
			distance: 1756.0664000000002
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 2049.2380000000003
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 1647.7244
		}],
		'Chile - Iquique': [{
			destiny: 'Chile - Coquimbo',
			distance: 1103.2364000000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 4332.1984
		}, {
			destiny: 'Chile - San Antonio',
			distance: 1517.8992
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 1787.9208
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 1471.2288
		}],
		'Chile - Antofagasta': [{
			destiny: 'Chile - Coquimbo',
			distance: 709.6864
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 3934.0184
		}, {
			destiny: 'Chile - San Antonio',
			distance: 1137.3132
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 1474.7476
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 1061.9368
		}],
		'Chile - Chacabuco': [{
			destiny: 'Chile - Coquimbo',
			distance: 1757.548
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 1401.9640000000002
		}, {
			destiny: 'Chile - San Antonio',
			distance: 1448.0788
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 1115.6448
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 1534.0116
		}],
		'Chile - Punta Arenas': [{
			destiny: 'Chile - Coquimbo',
			distance: 3023.5752
		}, {
			destiny: 'Chile - San Antonio',
			distance: 2663.7316
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 2512.9788000000003
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 2651.5084
		}],
		'Chile - Valparaiso': [{
			destiny: 'Chile - Coquimbo',
			distance: 372.0668
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2651.5084
		}, {
			destiny: 'Chile - San Antonio',
			distance: 77.784
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 442.628
		}, {
			destiny: 'Alemania - Bremen',
			distance: 14372.446
		}, {
			destiny: 'Alemania - Bremerhaven',
			distance: 14276.8828
		}, {
			destiny: 'Alemania - Hamburgo',
			distance: 14345.777200000002
		}, {
			destiny: 'Argentina - Zárate',
			distance: 5477.6604
		}, {
			destiny: 'Argentina - Rosario',
			distance: 5830.4664
		}, {
			destiny: 'Argentina - Bahía Blanca',
			distance: 4524.6212000000005
		}, {
			destiny: 'Australia - Sydney',
			distance: 12938.072
		}, {
			destiny: 'Australia - Islas Christmas',
			distance: 9754.484
		}, {
			destiny: 'Brazil - Santos',
			distance: 6555.709600000001
		}, {
			destiny: 'Brazil - Río Grande',
			distance: 5549.8884
		}, {
			destiny: 'Brazil - Paranaguá',
			distance: 6351.6192
		}, {
			destiny: 'Canada - Bahía Departure, isla Vancouver',
			distance: 11032.364
		}, {
			destiny: 'Chile - Puerto Montt',
			distance: 1131.572
		}, {
			destiny: 'Chile - San Vicente',
			distance: 451.888
		}, {
			destiny: 'Chile - Arica',
			distance: 1647.7244
		}, {
			destiny: 'Chile - Iquique',
			distance: 1471.2288
		}, {
			destiny: 'Chile - Antofagasta',
			distance: 1061.9368
		}, {
			destiny: 'Chile - Chacabuco',
			distance: 1534.0116
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2651.5084
		}, {
			destiny: 'China - Takú Bar',
			distance: 19412.664
		}, {
			destiny: 'China - Hong Kong',
			distance: 18923.736
		}, {
			destiny: 'China - Shanghai',
			distance: 18794.096
		}, {
			destiny: 'Colombia - Buenaventura',
			distance: 4405.908
		}, {
			destiny: 'Colombia - Tumaco',
			distance: 4128.108
		}, {
			destiny: 'Corea del Sur - Busan',
			distance: 18700.57
		}, {
			destiny: 'Corea del Sur - Incheon',
			distance: 19219.5004
		}, {
			destiny: 'Costa Rica - Puntarenas',
			distance: 4981.88
		}, {
			destiny: 'Cuba - La Habana',
			distance: 6761.652
		}, {
			destiny: 'Cuba - Santiago de Cuba',
			distance: 6194.9400000000005
		}, {
			destiny: 'Ecuador - Esmeraldas',
			distance: 4007.728
		}, {
			destiny: 'Ecuador - Guayaquil',
			distance: 3668.812
		}, {
			destiny: 'EEUU - Seattle',
			distance: 10956.432
		}, {
			destiny: 'EEUU - Los Ángeles',
			distance: 8904.416000000001
		}, {
			destiny: 'EEUU - Nueva York',
			distance: 8585.872000000001
		}, {
			destiny: 'EEUU - Charleston',
			distance: 7822.848
		}, {
			destiny: 'El Salvador - Acajutla',
			distance: 5531.924
		}, {
			destiny: 'El Salvador - La Unión',
			distance: 5439.3240000000005
		}, {
			destiny: 'España - Cádiz',
			distance: 12867.696
		}, {
			destiny: 'España - El Ferrol',
			distance: 12749.168
		}, {
			destiny: 'Fiji - Isla Viti Levu',
			distance: 10665.668
		}, {
			destiny: 'Filipinas - Manila',
			distance: 17982.920000000002
		}, {
			destiny: 'Francia - Burdeos',
			distance: 13443.668
		}, {
			destiny: 'Francia - Cherburgo',
			distance: 13339.956
		}, {
			destiny: 'Francia - Brest',
			distance: 13115.864
		}, {
			destiny: 'Guatemala - Champerico',
			distance: 5685.64
		}, {
			destiny: 'Guatemala - San José',
			distance: 5600.448
		}, {
			destiny: 'Holanda - Rotterdam',
			distance: 13822.957600000002
		}, {
			destiny: 'Holanda - Amsterdam',
			distance: 14025.0108
		}, {
			destiny: 'Honduras - Amapala',
			distance: 5431.916
		}, {
			destiny: 'India - Calcuta',
			distance: 26164.500400000004
		}, {
			destiny: 'India - Mundra',
			distance: 18568.8928
		}, {
			destiny: 'India - Chennai',
			distance: 19117.8256
		}, {
			destiny: 'India - Jawaharlal Nehru',
			distance: 24052.109200000003
		}, {
			destiny: 'Islas Marshall - Isla Wotje',
			distance: 13219.576
		}, {
			destiny: 'Islas Marshall - Isla Jaluit, islas Marshall',
			distance: 13026.968
		}, {
			destiny: 'Islas Salomon - B. Selwyn, islas Salomón',
			distance: 12602.86
		}, {
			destiny: 'Italia - Livorno',
			distance: 14488.0108
		}, {
			destiny: 'Italia - Venecia',
			distance: 16224.446
		}, {
			destiny: 'Italia - Cagliari',
			distance: 14388.5584
		}, {
			destiny: 'Japon - Sasebo',
			distance: 18216.272
		}, {
			destiny: 'Japon - Kobe',
			distance: 17656.968
		}, {
			destiny: 'Japon - Hakodate',
			distance: 17032.844
		}, {
			destiny: 'Kiribati - Isla Nonouti, islas Gilbert del Sur',
			distance: 12163.936
		}, {
			destiny: 'Mexico - Ensenada',
			distance: 8693.288
		}, {
			destiny: 'Mexico - Veracruz',
			distance: 7541.344
		}, {
			destiny: 'Mexico - Manzanillo',
			distance: 6763.504
		}, {
			destiny: 'Micronesia - Tomil Harbour, isla Yap, islas Carolinas',
			distance: 16014.244
		}, {
			destiny: 'Micronesia - Isla Guam, islas Marianas',
			distance: 15760.52
		}, {
			destiny: 'Micronesia - Islas Truk, islas Carolinas',
			distance: 14727.104
		}, {
			destiny: 'Micronesia - Isla Ponapé, islas Carolinas',
			distance: 14132.612
		}, {
			destiny: 'Nicaragua - Corinto',
			distance: 5324.5
		}, {
			destiny: 'Nicaragua - San Juan del Sur',
			distance: 5131.892
		}, {
			destiny: 'Nueva Zelandia - Auckland',
			distance: 9717.444
		}, {
			destiny: 'Nueva Zelandia - Isla Raoul, islas Kermadec',
			distance: 9611.88
		}, {
			destiny: 'Nueva Zelandia - Wellington',
			distance: 9382.232
		}, {
			destiny: 'Palaos - Malakal, islas Palau',
			distance: 16125.364
		}, {
			destiny: 'Panama - Colón',
			distance: 4930.024
		}, {
			destiny: 'Panama - Balboa',
			distance: 4854.092000000001
		}, {
			destiny: 'Papua Nueva Guinea - Rabaul, isla Nueva Bretaña',
			distance: 13817.772
		}, {
			destiny: 'Peru - Paita',
			distance: 3285.4480000000003
		}, {
			destiny: 'Peru - Salaverry',
			distance: 2905.788
		}, {
			destiny: 'Peru - Callao',
			distance: 2418.712
		}, {
			destiny: 'Peru - Caleta Matarani',
			distance: 1798.292
		}, {
			destiny: 'Peru - Ilo',
			distance: 1722.36
		}, {
			destiny: 'Polinesia - Isla Funafuti (islas Tuvalu)',
			distance: 11247.196
		}, {
			destiny: 'Portugal - Lisboa',
			distance: 12619.528
		}, {
			destiny: 'Portugal - San Vicente, isla Cabo Verde',
			distance: 10930.504
		}, {
			destiny: 'Reino Unido - Londres, Inglaterra',
			distance: 13717.764
		}, {
			destiny: 'Reino Unido - Southampton, Inglaterra',
			distance: 13397.368
		}, {
			destiny: 'Reino Unido - Liverpool, Inglaterra',
			distance: 13321.436000000002
		}, {
			destiny: 'Reino Unido - Plymouth, Inglaterra',
			distance: 13176.98
		}, {
			destiny: 'Rusia - Vladivostok',
			distance: 17790.312
		}, {
			destiny: 'Tailandia - Bangkok',
			distance: 21858.0448
		}, {
			destiny: 'Tailandia - Laem Chabang',
			distance: 23548.7356
		}, {
			destiny: 'Vietnam - Ho Chi Minh',
			distance: 22425.127200000003
		}],
		'Chile - Punta Arenas': [{
			destiny: 'Alemania - Bremen',
			distance: 14369.668
		}, {
			destiny: 'Alemania - Bremerhaven',
			distance: 14273.364
		}, {
			destiny: 'Alemania - Hamburgo',
			distance: 14256.1404
		}, {
			destiny: 'Argentina - Zárate',
			distance: 2746.7012
		}, {
			destiny: 'Argentina - Rosario',
			distance: 2948.1988
		}, {
			destiny: 'Argentina - Bahía Blanca',
			distance: 1790.5136
		}, {
			destiny: 'Australia - Sydney',
			distance: 15589.580400000004
		}, {
			destiny: 'Australia - Islas Christmas',
			distance: 12405.9924
		}, {
			destiny: 'Brazil - Santos',
			distance: 3925.8696
		}, {
			destiny: 'Brazil - Río Grande',
			distance: 2912.4552
		}, {
			destiny: 'Brazil - Paranaguá',
			distance: 3616.956
		}, {
			destiny: 'Canada - Bahía Departure, isla Vancouver',
			distance: 12832.3228
		}, {
			destiny: 'Chile - Puerto Montt',
			distance: 1853.6668
		}, {
			destiny: 'Chile - San Vicente',
			distance: 2442.0472
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 2512.9788000000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 2663.7316
		}, {
			destiny: 'Chile - Arica',
			distance: 4532.0292
		}, {
			destiny: 'Chile - Iquique',
			distance: 4332.1984
		}, {
			destiny: 'Chile - Antofagasta',
			distance: 3934.0184
		}, {
			destiny: 'Chile - Coquimbo',
			distance: 3023.5752
		}, {
			destiny: 'Chile - Chacabuco',
			distance: 1401.9640000000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 2651.5084
		}, {
			destiny: 'China - Takú Bar',
			distance: 22064.172400000003
		}, {
			destiny: 'China - Hong Kong',
			distance: 21575.244400000003
		}, {
			destiny: 'China - Shanghai',
			distance: 21445.604400000004
		}, {
			destiny: 'Colombia - Buenaventura',
			distance: 6981.669600000001
		}, {
			destiny: 'Colombia - Tumaco',
			distance: 6779.6164
		}, {
			destiny: 'Corea del Sur - Busan',
			distance: 18125.8944
		}, {
			destiny: 'Corea del Sur - Incheon',
			distance: 19177.46
		}, {
			destiny: 'Costa Rica - Puntarenas',
			distance: 7633.3884
		}, {
			destiny: 'Cuba - La Habana',
			distance: 9555.0236
		}, {
			destiny: 'Cuba - Santiago de Cuba',
			distance: 8838.1144
		}, {
			destiny: 'Ecuador - Esmeraldas',
			distance: 6460.5168
		}, {
			destiny: 'Ecuador - Guayaquil',
			distance: 6177.7164
		}, {
			destiny: 'EEUU - Seattle',
			distance: 13285.877600000002
		}, {
			destiny: 'EEUU - Los Ángeles',
			distance: 11127.3716
		}, {
			destiny: 'EEUU - Nueva York',
			distance: 11227.935200000002
		}, {
			destiny: 'EEUU - Charleston',
			distance: 10465.4668
		}, {
			destiny: 'El Salvador - Acajutla',
			distance: 8134.7248
		}, {
			destiny: 'El Salvador - La Unión',
			distance: 8090.8324
		}, {
			destiny: 'España - Cádiz',
			distance: 11859.6524
		}, {
			destiny: 'España - El Ferrol',
			distance: 12505.0744
		}, {
			destiny: 'Fiji - Isla Viti Levu',
			distance: 13317.1764
		}, {
			destiny: 'Filipinas - Manila',
			distance: 20634.4284
		}, {
			destiny: 'Francia - Burdeos',
			distance: 16095.176400000002
		}, {
			destiny: 'Francia - Cherburgo',
			distance: 13405.5168
		}, {
			destiny: 'Francia - Brest',
			distance: 13122.346
		}, {
			destiny: 'Guatemala - Champerico',
			distance: 8124.724
		}, {
			destiny: 'Guatemala - San José',
			distance: 8251.956400000001
		}, {
			destiny: 'Holanda - Rotterdam',
			distance: 13835.7364
		}, {
			destiny: 'Holanda - Amsterdam',
			distance: 16339.27
		}, {
			destiny: 'Honduras - Amapala',
			distance: 7811.365600000001
		}, {
			destiny: 'India - Calcuta',
			distance: 20897.2272
		}, {
			destiny: 'India - Mundra',
			distance: 16573.548000000003
		}, {
			destiny: 'India - Chennai',
			distance: 17121.369599999998
		}, {
			destiny: 'India - Jawaharlal Nehru',
			distance: 16513.728400000004
		}, {
			destiny: 'Islas Marshall - Isla Wotje',
			distance: 15871.084400000002
		}, {
			destiny: 'Islas Marshall - Isla Jaluit',
			distance: 15678.4764
		}, {
			destiny: 'Islas Salomon - B. Selwyn',
			distance: 15254.3684
		}, {
			destiny: 'Italia - Livorno',
			distance: 13485.8936
		}, {
			destiny: 'Italia - Venecia',
			distance: 15048.6112
		}, {
			destiny: 'Italia - Cagliari',
			distance: 13229.2064
		}, {
			destiny: 'Japon - Sasebo',
			distance: 20867.780400000003
		}, {
			destiny: 'Japon - Kobe',
			distance: 20308.476400000003
		}, {
			destiny: 'Japon - Hakodate',
			distance: 19684.352400000003
		}, {
			destiny: 'Kiribati - Isla Nonouti, islas Gilbert del Sur',
			distance: 14815.4444
		}, {
			destiny: 'Mexico - Ensenada',
			distance: 11488.511600000002
		}, {
			destiny: 'Mexico - Veracruz',
			distance: 10328.7892
		}, {
			destiny: 'Mexico - Manzanillo',
			distance: 8942.567200000001
		}, {
			destiny: 'Micronesia - Tomil Harbour, isla Yap, islas Carolinas',
			distance: 18665.7524
		}, {
			destiny: 'Micronesia - Isla Guam, islas Marianas',
			distance: 15103.986
		}, {
			destiny: 'Micronesia - Islas Truk, islas Carolinas',
			distance: 17378.6124
		}, {
			destiny: 'Micronesia - Isla Ponapé, islas Carolinas',
			distance: 16784.120400000003
		}, {
			destiny: 'Nicaragua - Corinto',
			distance: 7976.0084
		}, {
			destiny: 'Nicaragua - San Juan del Sur',
			distance: 7676.3548
		}, {
			destiny: 'Nueva Zelandia - Auckland',
			distance: 9138.1384
		}, {
			destiny: 'Nueva Zelandia - Isla Raoul, islas Kermadec',
			distance: 9474.832
		}, {
			destiny: 'Nueva Zelandia - Wellington',
			distance: 9245.184
		}, {
			destiny: 'Palaos - Malakal, islas Palau',
			distance: 18776.872400000004
		}, {
			destiny: 'Panama - Colón',
			distance: 7563.3828
		}, {
			destiny: 'Panama - Balboa',
			distance: 7404.1108
		}, {
			destiny: 'Papua Nueva Guinea - Rabaul, isla Nueva Bretaña',
			distance: 13769.8052
		}, {
			destiny: 'Peru - Paita',
			distance: 5844.3564
		}, {
			destiny: 'Peru - Salaverry',
			distance: 5524.5160000000005
		}, {
			destiny: 'Peru - Callao',
			distance: 5025.957600000001
		}, {
			destiny: 'Peru - Caleta Matarani',
			distance: 4477.5804
		}, {
			destiny: 'Peru - Ilo',
			distance: 4614.4432
		}, {
			destiny: 'Polinesia - Isla Funafuti (islas Tuvalu)',
			distance: 13898.7044
		}, {
			destiny: 'Portugal - Lisboa',
			distance: 11924.2872
		}, {
			destiny: 'Portugal - San Vicente, isla Cabo Verde',
			distance: 13582.0124
		}, {
			destiny: 'Reino Unido - Londres, Inglaterra',
			distance: 13809.8084
		}, {
			destiny: 'Reino Unido - Southampton, Inglaterra',
			distance: 13512.5624
		}, {
			destiny: 'Reino Unido - Liverpool, Inglaterra',
			distance: 13636.8316
		}, {
			destiny: 'Reino Unido - Plymouth, Inglaterra',
			distance: 16976.5432
		}, {
			destiny: 'Rusia - Vladivostok',
			distance: 20707.7676
		}, {
			destiny: 'Tailandia - Bangkok',
			distance: 19858.8108
		}, {
			destiny: 'Tailandia - Laem Chabang',
			distance: 19813.066400000003
		}, {
			destiny: 'Vietnam - Ho Chi Minh',
			distance: 19095.2312
		}],
		'China - Takú Bar': [{
			destiny: 'Chile - Coquimbo',
			distance: 19040.5972
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 22064.172400000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 19334.88
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 19855.292
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 19412.664
		}],
		'China - Hong Kong': [{
			destiny: 'Chile - Coquimbo',
			distance: 18551.6692
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 21575.244400000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 20159.5756
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 21229.1056
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 18923.736
		}],
		'China - Shanghai': [{
			destiny: 'Chile - Coquimbo',
			distance: 19511.3756
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 21445.604400000004
		}, {
			destiny: 'Chile - San Antonio',
			distance: 19077.8224
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 20152.1676
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 18794.096
		}],
		'Colombia - Buenaventura': [{
			destiny: 'Chile - Coquimbo',
			distance: 4157.369600000001
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 6981.669600000001
		}, {
			destiny: 'Chile - San Antonio',
			distance: 4539.9928
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 4805.9400000000005
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 4405.908
		}],
		'Colombia - Tumaco': [{
			destiny: 'Chile - Coquimbo',
			distance: 3818.4536000000007
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 6779.6164
		}, {
			destiny: 'Chile - San Antonio',
			distance: 4192.1872
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 4477.21
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 4128.108
		}],
		'Corea del Sur - Busan': [{
			destiny: 'Chile - Coquimbo',
			distance: 18860.0272
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 18125.8944
		}, {
			destiny: 'Chile - San Antonio',
			distance: 18331.096
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 19568.232
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 18700.57
		}],
		'Corea del Sur - Incheon': [{
			destiny: 'Chile - Coquimbo',
			distance: 19580.4552
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 19177.46
		}, {
			destiny: 'Chile - San Antonio',
			distance: 19034.856
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 20221.2472
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 19219.5004
		}],
		'Costa Rica - Puntarenas': [{
			destiny: 'Chile - Coquimbo',
			distance: 4661.484
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 7633.3884
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5035.0324
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5320.0552
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 4981.88
		}],
		'Cuba - La Habana': [{
			destiny: 'Chile - Coquimbo',
			distance: 6609.7880000000005
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 9555.0236
		}, {
			destiny: 'Chile - San Antonio',
			distance: 6994.078
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 7285.5828
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 6761.652
		}],
		'Cuba - Santiago de Cuba': [{
			destiny: 'Chile - Coquimbo',
			distance: 5928.807600000001
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 8838.1144
		}, {
			destiny: 'Chile - San Antonio',
			distance: 6313.097600000001
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 6604.4172
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 6194.9400000000005
		}],
		'Ecuador - Esmeraldas': [{
			destiny: 'Chile - Coquimbo',
			distance: 3603.4364
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 6460.5168
		}, {
			destiny: 'Chile - San Antonio',
			distance: 4005.5056
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 4265.8968
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 4007.728
		}],
		'Ecuador - Guayaquil': [{
			destiny: 'Chile - Coquimbo',
			distance: 3369.8992
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 6177.7164
		}, {
			destiny: 'Chile - San Antonio',
			distance: 3748.6332
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 4016.617600000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 3668.812
		}],
		'EEUU - Seattle': [{
			destiny: 'Chile - Coquimbo',
			distance: 11082.553200000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13285.877600000002
		}, {
			destiny: 'Chile - San Antonio',
			distance: 11303.1264
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 11723.345200000002
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 10956.432
		}],
		'EEUU - Los Ángeles': [{
			destiny: 'Chile - Coquimbo',
			distance: 8683.2872
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 11127.3716
		}, {
			destiny: 'Chile - San Antonio',
			distance: 9196.8468
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 9569.6544
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 8904.416000000001
		}],
		'EEUU - Nueva York': [{
			destiny: 'Chile - Coquimbo',
			distance: 8282.8848
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 11227.935200000002
		}, {
			destiny: 'Chile - San Antonio',
			distance: 8667.174799999999
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 8958.679600000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 8585.872000000001
		}],
		'EEUU - Charleston': [{
			destiny: 'Chile - Coquimbo',
			distance: 7520.4164
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 10465.4668
		}, {
			destiny: 'Chile - San Antonio',
			distance: 7904.7064
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 8196.026
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 7822.848
		}],
		'El Salvador - Acajutla': [{
			destiny: 'Chile - Coquimbo',
			distance: 5314.869600000001
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 8134.7248
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5645.4516
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5955.6616
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5531.924
		}],
		'El Salvador - La Unión': [{
			destiny: 'Chile - Coquimbo',
			distance: 5142.2632
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 8090.8324
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5533.2204
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5843.615600000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5439.3240000000005
		}],
		'España - Cádiz': [{
			destiny: 'Chile - Coquimbo',
			distance: 12671.0136
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 11859.6524
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13055.3036
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13346.623200000002
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 12867.696
		}],
		'España - El Ferrol': [{
			destiny: 'Chile - Coquimbo',
			distance: 12450.996
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 12505.0744
		}, {
			destiny: 'Chile - San Antonio',
			distance: 12835.286
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13126.7908
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 12749.168
		}],
		'Fiji - Isla Viti Levu': [{
			destiny: 'Chile - Coquimbo',
			distance: 10293.6012
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13317.1764
		}, {
			destiny: 'Chile - San Antonio',
			distance: 10743.452
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 11108.296
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 10665.668
		}],
		'Filipinas - Manila': [{
			destiny: 'Chile - Coquimbo',
			distance: 17610.8532
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 20634.4284
		}, {
			destiny: 'Chile - San Antonio',
			distance: 18060.704
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 18425.548000000003
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 17982.920000000002
		}],
		'Francia - Burdeos': [{
			destiny: 'Chile - Coquimbo',
			distance: 13071.6012
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 16095.176400000002
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13521.452
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13886.296
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13443.668
		}],
		'Francia - Cherburgo': [{
			destiny: 'Chile - Coquimbo',
			distance: 13044.9324
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13405.5168
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13429.2224
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13720.7272
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13339.956
		}],
		'Francia - Brest': [{
			destiny: 'Chile - Coquimbo',
			distance: 13066.6008
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13122.346
		}, {
			destiny: 'Chile - San Antonio',
			distance: 15819.784
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 15668.6608
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13115.864
		}],
		'Guatemala - Champerico': [{
			destiny: 'Chile - Coquimbo',
			distance: 5477.29
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 8124.724
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5805.649600000001
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 6037.149600000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5685.64
		}],
		'Guatemala - San José': [{
			destiny: 'Chile - Coquimbo',
			distance: 5228.3812
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 8251.956400000001
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5678.232
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 6043.076
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5600.448
		}],
		'Holanda - Rotterdam': [{
			destiny: 'Chile - Coquimbo',
			distance: 13511.6364
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13835.7364
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13901.112
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14187.4312
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13822.957600000002
		}],
		'Holanda - Amsterdam': [{
			destiny: 'Chile - Coquimbo',
			distance: 13713.8748
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 16339.27
		}, {
			destiny: 'Chile - San Antonio',
			distance: 14114.092
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14389.4844
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 14025.0108
		}],
		'Honduras - Amapala': [{
			destiny: 'Chile - Coquimbo',
			distance: 5137.0776000000005
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 7811.365600000001
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5528.0348
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5838.43
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5431.916
		}],
		'India - Calcuta': [{
			destiny: 'Chile - Coquimbo',
			distance: 26055.788
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 20897.2272
		}, {
			destiny: 'Chile - San Antonio',
			distance: 26020.6
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 26696.58
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 26164.500400000004
		}],
		'India - Mundra': [{
			destiny: 'Chile - Coquimbo',
			distance: 28197.626
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 16573.548000000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 28150.4
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 18164.7864
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 18568.8928
		}],
		'India - Chennai': [{
			destiny: 'Chile - Coquimbo',
			distance: 25932.63
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 17121.369599999998
		}, {
			destiny: 'Chile - San Antonio',
			distance: 25896.516
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 18713.719200000003
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 19117.8256
		}],
		'India - Jawaharlal Nehru': [{
			destiny: 'Chile - Coquimbo',
			distance: 27572.7612
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 16513.728400000004
		}, {
			destiny: 'Chile - San Antonio',
			distance: 22296.228000000003
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 18087.1876
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 24052.109200000003
		}],
		'Islas Marshall - Isla Wotje': [{
			destiny: 'Chile - Coquimbo',
			distance: 12847.5092
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 15871.084400000002
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13297.36
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13662.204000000002
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13219.576
		}],
		'Islas Marshall - Isla Jaluit': [{
			destiny: 'Chile - Coquimbo',
			distance: 12654.901200000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 15678.4764
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13104.752
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13469.596
		}],
		'Islas Salomon - B. Selwyn': [{
			destiny: 'Chile - Coquimbo',
			distance: 12230.793200000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 15254.3684
		}, {
			destiny: 'Chile - San Antonio',
			distance: 12680.644
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13045.488
		}],
		'Italia - Livorno': [{
			destiny: 'Chile - Coquimbo',
			distance: 14361.8896
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13485.8936
		}, {
			destiny: 'Chile - San Antonio',
			distance: 14762.292
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 15037.6844
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 14488.0108
		}],
		'Italia - Venecia': [{
			destiny: 'Chile - Coquimbo',
			distance: 15913.1248
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 15048.6112
		}, {
			destiny: 'Chile - San Antonio',
			distance: 16316.12
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 16588.9196
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 16224.446
		}],
		'Italia - Cagliari': [{
			destiny: 'Chile - Coquimbo',
			distance: 14077.4224
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13229.2064
		}, {
			destiny: 'Chile - San Antonio',
			distance: 14477.084
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14753.032
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 14388.5584
		}],
		'Japon - Sasebo': [{
			destiny: 'Chile - Coquimbo',
			distance: 17844.2052
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 20867.780400000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 18294.056
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 18658.9
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 18216.272
		}],
		'Japon - Kobe': [{
			destiny: 'Chile - Coquimbo',
			distance: 17284.9012
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 20308.476400000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 17734.752
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 18099.596
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 17656.968
		}],
		'Japon - Hakodate': [{
			destiny: 'Chile - Coquimbo',
			distance: 16660.7772
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 19684.352400000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 17110.628
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 17475.472
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 17032.844
		}],
		'Kiribati - Isla Nonouti': [{
			destiny: 'Chile - Coquimbo',
			distance: 11791.8692
		}],
		'Kiribati - Isla Nonouti, islas Gilbert del Sur': [{
			destiny: 'Chile - Punta Arenas',
			distance: 14815.4444
		}, {
			destiny: 'Chile - San Antonio',
			distance: 12241.72
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 12606.564
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 12163.936
		}],
		'Islas Marshall - Isla Jaluit, islas Marshall': [{
			destiny: 'Chile - Valparaiso',
			distance: 13026.968
		}],
		'Islas Salomon - B. Selwyn, islas Salomón': [{
			destiny: 'Chile - Valparaiso',
			distance: 12602.86
		}],
		'Mexico - Ensenada': [{
			destiny: 'Chile - Coquimbo',
			distance: 8764.9604
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 11488.511600000002
		}, {
			destiny: 'Chile - San Antonio',
			distance: 8973.3104
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 9405.7524
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 8693.288
		}],
		'Mexico - Veracruz': [{
			destiny: 'Chile - Coquimbo',
			distance: 7383.7388
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 10328.7892
		}, {
			destiny: 'Chile - San Antonio',
			distance: 7768.0288
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 8059.533600000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 7541.344
		}],
		'Mexico - Manzanillo': [{
			destiny: 'Chile - Coquimbo',
			distance: 6696.832
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 8942.567200000001
		}, {
			destiny: 'Chile - San Antonio',
			distance: 7027.228800000001
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 7337.624000000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 6763.504
		}],
		'Micronesia - Tomil Harbour, isla Yap, islas Carolinas': [{
			destiny: 'Chile - Coquimbo',
			distance: 15642.177200000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 18665.7524
		}, {
			destiny: 'Chile - San Antonio',
			distance: 16092.028
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 16456.872
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 16014.244
		}],
		'Micronesia - Isla Guam, islas Marianas': [{
			destiny: 'Chile - Coquimbo',
			distance: 15388.453200000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 15103.986
		}, {
			destiny: 'Chile - San Antonio',
			distance: 15838.304
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 16203.148
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 15760.52
		}],
		'Micronesia - Islas Truk, islas Carolinas': [{
			destiny: 'Chile - Coquimbo',
			distance: 14355.0372
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 17378.6124
		}, {
			destiny: 'Chile - San Antonio',
			distance: 14804.888
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 15169.732
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 14727.104
		}],
		'Micronesia - Isla Ponapé, islas Carolinas': [{
			destiny: 'Chile - Coquimbo',
			distance: 13760.5452
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 16784.120400000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 14210.396
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14575.240000000002
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 14132.612
		}],
		'Nicaragua - Corinto': [{
			destiny: 'Chile - Coquimbo',
			distance: 5002.9928
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 7976.0084
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5376.170800000001
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5654.7116000000005
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5324.5
		}],
		'Nicaragua - San Juan del Sur': [{
			destiny: 'Chile - Coquimbo',
			distance: 4837.979600000001
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 7676.3548
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5211.7132
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5493.9580000000005
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 5131.892
		}],
		'Nueva Zelandia - Auckland': [{
			destiny: 'Chile - Coquimbo',
			distance: 10089.5108
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 9138.1384
		}, {
			destiny: 'Chile - San Antonio',
			distance: 9795.228
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 9274.816
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 9717.444
		}],
		'Nueva Zelandia - Isla Raoul, islas Kermadec': [{
			destiny: 'Chile - Coquimbo',
			distance: 9983.9468
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 9474.832
		}, {
			destiny: 'Chile - San Antonio',
			distance: 9689.664
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 9169.252
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 9611.88
		}],
		'Nueva Zelandia - Wellington': [{
			destiny: 'Chile - Coquimbo',
			distance: 9754.2988
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 9245.184
		}, {
			destiny: 'Chile - San Antonio',
			distance: 9460.016
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 8939.604000000001
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 9382.232
		}],
		'Palaos - Malakal, islas Palau': [{
			destiny: 'Chile - Coquimbo',
			distance: 15753.2972
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 18776.872400000004
		}, {
			destiny: 'Chile - San Antonio',
			distance: 16203.148
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 16567.992000000002
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 16125.364
		}],
		'Panama - Colón': [{
			destiny: 'Chile - Coquimbo',
			distance: 4591.4784
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 7563.3828
		}, {
			destiny: 'Chile - San Antonio',
			distance: 5000.400000000001
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5250.2348
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 4930.024
		}],
		'Panama - Balboa': [{
			destiny: 'Chile - Coquimbo',
			distance: 4567.2172
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 7404.1108
		}, {
			destiny: 'Chile - San Antonio',
			distance: 4946.692
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 5214.3060000000005
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 4854.092000000001
		}],
		'Papua Nueva Guinea - Rabaul, isla Nueva Bretaña': [{
			destiny: 'Chile - Coquimbo',
			distance: 13445.705200000002
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13769.8052
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13895.556
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14260.4
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13817.772
		}],
		'Peru - Paita': [{
			destiny: 'Chile - Coquimbo',
			distance: 2991.5356
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 5844.3564
		}, {
			destiny: 'Chile - San Antonio',
			distance: 3388.9748000000004
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 3650.4772
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 3285.4480000000003
		}],
		'Peru - Salaverry': [{
			destiny: 'Chile - Coquimbo',
			distance: 2532.0544
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 5524.5160000000005
		}, {
			destiny: 'Chile - San Antonio',
			distance: 2983.572
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 3348.416
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 2905.788
		}],
		'Peru - Callao': [{
			destiny: 'Chile - Coquimbo',
			distance: 2093.1304
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 5025.957600000001
		}, {
			destiny: 'Chile - San Antonio',
			distance: 2478.7168
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 2783.3708
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 2418.712
		}],
		'Peru - Caleta Matarani': [{
			destiny: 'Chile - Coquimbo',
			distance: 1450.8568
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 4477.5804
		}, {
			destiny: 'Chile - San Antonio',
			distance: 1881.632
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 2179.9892
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 1798.292
		}],
		'Peru - Ilo': [{
			destiny: 'Chile - Coquimbo',
			distance: 1360.294
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 4614.4432
		}, {
			destiny: 'Chile - San Antonio',
			distance: 1789.958
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 2120.54
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 1722.36
		}],
		'Polinesia - Isla Funafuti (islas Tuvalu)': [{
			destiny: 'Chile - Coquimbo',
			distance: 10875.1292
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13898.7044
		}, {
			destiny: 'Chile - San Antonio',
			distance: 11324.98
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 11689.824
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 11247.196
		}],
		'Portugal - Lisboa': [{
			destiny: 'Chile - Coquimbo',
			distance: 12431.7352
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 11924.2872
		}, {
			destiny: 'Chile - San Antonio',
			distance: 12816.025200000002
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13107.53
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 12619.528
		}],
		'Portugal - San Vicente, isla Cabo Verde': [{
			destiny: 'Chile - Coquimbo',
			distance: 10558.4372
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13582.0124
		}, {
			destiny: 'Chile - San Antonio',
			distance: 11008.288
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 11373.132
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 10930.504
		}],
		'Reino Unido - Londres, Inglaterra': [{
			destiny: 'Chile - Coquimbo',
			distance: 13561.8256
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13809.8084
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13946.1156
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14237.6204
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13717.764
		}],
		'Reino Unido - Southampton, Inglaterra': [{
			destiny: 'Chile - Coquimbo',
			distance: 13103.6408
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13512.5624
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13487.9308
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13779.4356
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13397.368
		}],
		'Reino Unido - Liverpool, Inglaterra': [{
			destiny: 'Chile - Coquimbo',
			distance: 13138.6436
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 13636.8316
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13522.9336
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 13814.2532
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13321.436000000002
		}],
		'Reino Unido - Plymouth, Inglaterra': [{
			destiny: 'Chile - Coquimbo',
			distance: 13553.4916
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 16976.5432
		}, {
			destiny: 'Chile - San Antonio',
			distance: 13937.7816
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 14229.1012
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 13176.98
		}],
		'Rusia - Vladivostok': [{
			destiny: 'Chile - Coquimbo',
			distance: 18131.6356
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 20707.7676
		}, {
			destiny: 'Chile - San Antonio',
			distance: 18073.1124
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 18772.6128
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 17790.312
		}],
		'Tailandia - Bangkok': [{
			destiny: 'Chile - Coquimbo',
			distance: 28457.832
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 19858.8108
		}, {
			destiny: 'Chile - San Antonio',
			distance: 23233.34
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 21453.938400000003
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 21858.0448
		}],
		'Tailandia - Laem Chabang': [{
			destiny: 'Chile - Coquimbo',
			distance: 23255.1936
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 19813.066400000003
		}, {
			destiny: 'Chile - San Antonio',
			distance: 23211.116
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 23895.9856
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 23548.7356
		}],
		'Vietnam - Ho Chi Minh': [{
			destiny: 'Chile - Coquimbo',
			distance: 22311.2292
		}, {
			destiny: 'Chile - Punta Arenas',
			distance: 19095.2312
		}, {
			destiny: 'Chile - San Antonio',
			distance: 21874.3424
		}, {
			destiny: 'Chile - Talcahuano',
			distance: 22952.021200000003
		}, {
			destiny: 'Chile - Valparaiso',
			distance: 22425.127200000003
		}]
	},
	'TERRESTRE': {
		'Argentina - Buenos Aires': [{
			destiny: 'Chile -  Santiago',
			distance: 1525
		}],
		'Argentina - Córdoba': [{
			destiny: 'Chile -  Santiago',
			distance: 1039
		}],
		'Argentina - La Plata': [{
			destiny: 'Chile -  Santiago',
			distance: 1508
		}],
		'Argentina - Rosario': [{
			destiny: 'Chile -  Santiago',
			distance: 1234
		}],
		'Argentina - Salta': [{
			destiny: 'Chile -  Santiago',
			distance: 1626
		}],
		'Bolivia - La Paz': [{
			destiny: 'Chile -  Santiago',
			distance: 2433
		}],
		'Bolivia - Santa Cruz de la Sierra': [{
			destiny: 'Chile -  Santiago',
			distance: 2498
		}],
		'Brasil - Belo Horizonte': [{
			destiny: 'Chile -  Santiago',
			distance: 3800
		}],
		'Brasil - Brasilia': [{
			destiny: 'Chile -  Santiago',
			distance: 3894
		}],
		'Brasil - Curitiba': [{
			destiny: 'Chile -  Santiago',
			distance: 2848
		}],
		'Brasil - Goiana': [{
			destiny: 'Chile -  Santiago',
			distance: 3698
		}],
		'Brasil - Rio de Janeiro': [{
			destiny: 'Chile -  Santiago',
			distance: 3700
		}],
		'Brasil - Sao Paulo': [{
			destiny: 'Chile -  Santiago',
			distance: 3265
		}],
		'Brasil - Sorocaba': [{
			destiny: 'Chile -  Santiago',
			distance: 3181
		}],
		'Chile - Antofagasta': [{
			destiny: 'Chile -  Santiago',
			distance: 1335
		}],
		'Chile - Calama': [{
			destiny: 'Chile -  Santiago',
			distance: 1535
		}],
		'Chile - Concepción': [{
			destiny: 'Chile -  Santiago',
			distance: 499
		}],
		'Chile - La Serena': [{
			destiny: 'Chile -  Santiago',
			distance: 472
		}],
		'Chile - Osorno': [{
			destiny: 'Chile -  Santiago',
			distance: 928
		}],
		'Chile - San Antonio': [{
			destiny: 'Chile -  Santiago',
			distance: 122
		}],
		'Chile - Temuco': [{
			destiny: 'Chile -  Santiago',
			distance: 679
		}],
		'Chile - Valparaíso': [{
			destiny: 'Chile -  Santiago',
			distance: 115
		}],
		'Paraguay - Asunción': [{
			destiny: 'Chile -  Santiago',
			distance: 2109
		}],
		'Paraguay - Ciuedad del Este': [{
			destiny: 'Chile -  Santiago',
			distance: 2318
		}],
		'Paraguay - San Lorenzo': [{
			destiny: 'Chile -  Santiago',
			distance: 2120
		}],
		'Perú - Arequipa': [{
			destiny: 'Chile -  Santiago',
			distance: 2461
		}],
		'Perú - Lima': [{
			destiny: 'Chile -  Santiago',
			distance: 3297
		}],
		'Perú - Piura': [{
			destiny: 'Chile -  Santiago',
			distance: 4281
		}],
		'Perú - Trujillo': [{
			destiny: 'Chile -  Santiago',
			distance: 3851
		}],
		'Uruguay - Montevideo': [{
			destiny: 'Chile -  Santiago',
			distance: 1699
		}],
		'Uruguay - Paysandú': [{
			destiny: 'Chile -  Santiago',
			distance: 1541
		}]
	}
};

export default transportOptions;
import React, { useCallback } from 'react';
import { css } from 'styled-components/macro';
import { Formik } from 'formik';
import { useMutation } from 'react-apollo-hooks';
import * as Yup from 'yup';
import { MenuItem } from '@material-ui/core';
import { Grid, Cell } from 'styled-css-grid';
import { isEmpty } from 'lodash';

import Button from 'components/styled/Button';
import { Form } from 'components/Form';
import { InputField } from 'components/InputField';
import { SelectField } from 'components/SelectField';
import { FunctionalUnitMap } from 'consts';
import { COMPONENT_UPDATE } from 'gql/mutations';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(60,'Nombre muy largo')
    .required('Nombre no puede ser vacío'),
  weightValue: Yup.number()
    .min(0, 'Valor tiene que ser positivo')
    .required('Peso no puede ser vacio'),
  functionalUnitMagnitude: Yup.number()
    .min(0, 'Valor tiene que ser positivo')
    .required('Capacidad del envase requerida'),
});

const childrenMessage = 'No se puede editar este campo si existen subcomponentes';

function ComponentName({ component = {}, project = {} }) {
  const updateComponentMutation = useMutation(COMPONENT_UPDATE);
  const {
    id,
    name,
    functionalUnitMagnitude,
    description,
    weight: { value: weightValue, metric: weightMetric },
    children,
    isRoot,
  } = component;

  const { functionalUnit, isPublic } = project;

  const updateComponentSubmit = useCallback(
    async (values, actions) => {
      const input = { id, ...values };
      updateComponentMutation({ variables: { input } });
    },
    [id]
  );

  if (isEmpty(component)) return null;

  const initialValues = {
    id,
    name,
    weightValue,
    weightMetric,
    functionalUnitMagnitude,
    description,
  };

  const handleMetricChange = ({ values, setFieldValue }) => event => {
    const { value } = event.target;
    const { weightMetric, weightValue } = values;
    if (weightMetric === value) return;
    setFieldValue('weightValue', value === 'KILOGRAMS' ? weightValue / 1000 : weightValue * 1000);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={updateComponentSubmit}
    >
      {({
        errors,
        dirty,
        handleSubmit,
        setFieldValue,
        values,
        resetForm,
        initialValues: formikInitialValues,
      }) => {
        return (
          <Form>
            <Grid
              columns={'1fr'}
              rows={'auto'}
              rowGap="2rem"
              css={css`
                padding-top: 1rem;
              `}
            >
              <Cell center middle>
                <InputField disabled={isPublic} name="name" label="Nombre" />
              </Cell>

              <Cell center middle>
                <InputField disabled={isPublic} name="description" label="Descripción" />
              </Cell>

              <Grid columns="80% 1fr">
                <Cell center middle>
                  <InputField
                    name="weightValue"
                    label="Peso por envase"
                    type="number"
                    inputProps={{ min: '0', step: 'any' }}
                    disabled={isPublic || !isEmpty(component.children)}
                    helperText={errors.weight || (!isEmpty(component.children) && childrenMessage)}
                  />
                </Cell>
                <Cell
                  center
                  middle
                  css={css`
                    padding-top: 1em;
                  `}
                >
                  <SelectField
                    disabled={isPublic}
                    name="weightMetric"
                    onChange={handleMetricChange({ values, setFieldValue })}
                  >
                    <MenuItem value="GRAMS">gr</MenuItem>
                    <MenuItem value="KILOGRAMS">kg</MenuItem>
                  </SelectField>
                </Cell>
              </Grid>
              {isRoot && (
                <Grid columns="1fr">
                  <Cell center middle>
                    <InputField
                      disabled={isPublic}
                      name="functionalUnitMagnitude"
                      label={`Capacidad del Envase (${FunctionalUnitMap[functionalUnit]})`}
                      type="number"
                      inputProps={{ min: '0', step: 'any' }}
                    />
                  </Cell>
                </Grid>
              )}
              <Cell center middle>
                <Grid columns="30% 30%" justifyContent="space-around">
                  <Cell left={2}>
                    <Button
                      disabled={isPublic || !(dirty && isEmpty(errors))}
                      onClick={handleSubmit}
                      color="primary"
                    >
                      Guardar cambios
                    </Button>
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ComponentName;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components/macro';

import SvgCircle from 'components/styled/SvgCircle';
import { ReactComponent as LightSvg } from './svg/light.svg';
import { ReactComponent as CarSvg } from './svg/car.svg';
import { ReactComponent as PhoneSvg } from './svg/phone.svg';
import { ReactComponent as TreeSvg } from './svg/tree.svg';
import { formatNumber, mergeObjects } from 'utils';

// TODO? get color from pallette
const greenFill = '#1AA58A';

const colorTable = css`
  border-right: 1px solid;
  border-right-color: #bdbdbd;
  border-top: 1px solid;
  border-top-color: #bdbdbd;
`;

const geiFactors = {
  ampolleta: ((1.0 / 18) * 1000) / 0.4187,
  celular: ((1.0 / 3.68) * 1000) / 0.4187,
  auto: 1.0 / 0.148,
  arbol: 1.0 / 0.13,
};

const geiFactorsFuncs = Object.entries(geiFactors)
  .map(([k, v]) => ({
    [k]: gei => formatNumber((gei && gei * v) || 0),
  }))
  .reduce(mergeObjects, {});

const columns = [
  {
    svg: LightSvg,
    geiFunc: gei => `${geiFactorsFuncs.ampolleta(gei)} horas`,
    title: 'Horas usando una ampolleta compactada Fluorescente de 18W',
  },
  {
    svg: PhoneSvg,
    geiFunc: gei => `${geiFactorsFuncs.celular(gei)} horas`,
    title: 'Horas de carga de celular (Asumiendo consumo de 3.68W)',
  },
  {
    svg: CarSvg,
    geiFunc: gei => `${geiFactorsFuncs.auto(gei)} kilómetros`,
    title: 'Kilómetros recorridos por un automóvil promedio',
  },
  {
    svg: TreeSvg,
    geiFunc: gei => `${geiFactorsFuncs.arbol(gei)} días`,
    title: 'Días de absorción de CO2 de un árbol',
  },
];


function EcoequivalencyGEITable({ components, ...rest }) {
  return (
    <Table
      {...rest}
      css={css`
        border: 1px solid;
        border-color: #bdbdbd;
      `}
    >
      <TableHead>
        <TableCell style={{ verticalAlign: 'bottom' }} css={colorTable}>
            <Typography variant="h6">Producto</Typography>
        </TableCell>
        {columns.map(({ svg, title }) => (
          <TableCell css={colorTable}>
            <Flex justifyContent="center" flexDirection="column" alignItems="center">
              <SvgCircle svg={svg} color={greenFill} diameter="100px" />
              <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                {title}
              </Typography>
            </Flex>
          </TableCell>
        ))}
      </TableHead>
      <TableBody>
        {components.map(({ name, gei }) => (
          <TableRow>
            <TableCell style={{ textAlign: 'left' }} css={colorTable}>
              <Typography variant="subtitle2" style={{ color: greenFill }}>
                {name}
              </Typography>
            </TableCell>
            {columns.map(({ geiFunc }) => (
              <TableCell style={{ textAlign: 'center', width: '20%' }} css={colorTable}>
                <Typography variant="body1">{geiFunc(gei)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default EcoequivalencyGEITable;

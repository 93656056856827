import React, { useCallback, useState } from 'react';
import { css } from 'styled-components/macro';
import { Grid, Cell } from 'styled-css-grid';
import { SelectField } from 'components/SelectField';
import { MenuItem } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { InputField } from 'components/InputField';
import { PercentageInputField } from 'components/PercentageInputField';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { Button } from 'components/styled/Button';
import { ConfirmDeleteDialog } from 'components/ConfirmDeleteDialog';
import useReactRouter from 'use-react-router';
import { InputAdornment } from '@material-ui/core';

import { useQuery, useMutation } from 'react-apollo-hooks';
import { GET_PROCESS, GET_COUNTRIES, GET_MY_PROCESSES } from 'gql/queries';
import { UPDATE_PROCESS, DELETE_PROCESS } from 'gql/mutations';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { LcStage, LcStageMap } from 'consts';
import { useSnackbar } from 'hooks';
import { mergeObjects } from 'utils';
import { isEmpty } from 'lodash';

import ProcessHeader from './ProcessHeader';

export const ProcessRoute = ({ match }) => {
  const { history } = useReactRouter();
  const { id: processId } = match.params;
  let { data, error, loading } = useQuery(GET_COUNTRIES);
  const { countries = [] } = data;

  ({ data, error, loading } = useQuery(GET_PROCESS, { variables: { id: processId } }));
  const { process = {} } = data;

  const { enqueueErrorSnackbar } = useSnackbar();
  const updateProcessMutation = useMutation(UPDATE_PROCESS);
  const deleteProcessMutation = useMutation(DELETE_PROCESS, {
    update: (cache, { data: { processDelete = {} } }) => {
      const { errors } = processDelete;
      if (!isEmpty(errors)) return;
      const { process } = processDelete;
      const { me } = cache.readQuery({ query: GET_MY_PROCESSES });
      const updatedProcesses = [...me.processes.filter(({ id }) => id !== processId)];
      cache.writeQuery({
        query: GET_MY_PROCESSES,
        data: { me: { ...me, processes: updatedProcesses } },
      });
    },
  });

  const updateProcessSubmit = async (values, actions) => {
    try {
      const { data } = await updateProcessMutation({
        variables: { input: { id: processId, ...values } },
      });
      const { errors } = data.processUpdate;

      if (!isEmpty(errors)) throw new Error(errors[0].message);

      history.push('/processes');
    } catch (e) {
      enqueueErrorSnackbar(e.message);
    }
  };

  const deleteProcessSubmit = async () => {
    try {
      const { data } = await deleteProcessMutation({
        variables: { input: { id: processId } },
      });
      const { errors } = data.processDelete;

      if (!isEmpty(errors)) throw new Error(errors[0].message);

      history.push('/processes');
    } catch (e) {
      enqueueErrorSnackbar(e.message);
    }
  };

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [confirmArgs, setConfirmArgs] = useState({
    title: '',
    message: '',
    callback: () => {},
  });

  const handleCloseConfirm = () => setIsConfirmOpen(false);

  const handleRecycleChange = ({ values, setFieldValue }) => event => {
    const { name, value } = event.target;
    const currentValues = { ...values, [name]: Number(value) };
    const { incineration, finalRecycle } = currentValues;
    setFieldValue('sanitaryFill', 100 - (finalRecycle + incineration));
  };

  const openConfirmDeleteDialog = ({ title, message, callback }) => {
    setConfirmArgs({
      title,
      message,
      callback,
    });
    setIsConfirmOpen(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const { id, name, description, material } = process;

  const fields = [
    {
      name: 'name',
      label: 'Nombre del proceso*',
      initialValue: '',
      InputComponent: InputField,
      validation: Yup.string()
        .max(60,'Nombre muy largo')
        .required('Campo no puede ser vacio'),
    },
    {
      name: 'material',
      label: 'Material*',
      initialValue: '',
      InputComponent: InputField,
      validation: Yup.string()
        .max(40,'Nombre muy largo')
        .required('Campo no puede ser vacio'),
    },
    {
      name: 'description',
      label: 'Descripción',
      initialValue: '',
      InputComponent: InputField,
      validation: Yup.string(),
    },
    {
      name: 'lcStage',
      label: 'Etapa del ciclo de vida*',
      initialValue: Object.keys(LcStage)[0],
      InputComponent: SelectField,
      componentProps: {
        children: Object.entries(LcStage).map(([k, v]) => (
          <MenuItem key={k} value={k}>
            {LcStageMap[v]}
          </MenuItem>
        )),
      },
    },
    {
      name: 'massOut',
      label: 'Salida de masa procesada (kg)*',
      initialValue: '',
      InputComponent: InputField,
      validation: Yup.number()
        .positive('Magnitud debe ser mayor o igual a 0')
        .required('Campo no puede ser vacio'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'massLoss',
      label: 'Porcentaje de mermas del proceso',
      initialValue: 0,
      InputComponent: PercentageInputField,
      validation: Yup.number()
      .min(0, 'Magnitud debe ser mayor o igual a 0')
      .max(100, 'Magnitud debe ser menor o igual a 100'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'finalRecycle',
      label: 'Porcentaje de merma con destino a reciclaje',
      initialValue: 0,
      InputComponent: PercentageInputField,
      validation: Yup.number()
      .min(0, 'Magnitud debe ser mayor o igual a 0')
      .max(100, 'Magnitud debe ser menor o igual a 100'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'incineration',
      label: 'Porcentaje de merma con destino a incineración',
      initialValue: 0,
      InputComponent: PercentageInputField,
      validation: Yup.number()
      .min(0, 'Magnitud debe ser mayor o igual a 0')
      .max(100, 'Magnitud debe ser menor o igual a 100'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'sanitaryFill',
      label: 'Porcentaje de merma con destino a relleno sanitario',
      initialValue: 0,
      InputComponent: PercentageInputField,
      validation: Yup.number()
      .min(0, 'Magnitud debe ser mayor o igual a 0')
      .max(100, 'Magnitud debe ser menor o igual a 100')      
      .test("max", "El total de mermas debe ser 100%", function(value) {
        const { finalRecycle, incineration } = this.parent;
        console.log(finalRecycle, incineration, value);
        return finalRecycle + incineration + value == 100;
      }),
      // TO DO disabled: true,
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'electricityCons',
      label: 'Consumo de electricidad (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'countryName',
      label: 'País de red eléctrica',
      initialValue: countries[0].name,
      InputComponent: SelectField,
      componentProps: {
        children: countries.sort((a, b) => (a.name > b.name) ? 1 : -1).map(({ name }) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        )),
      },
    },
    {
      name: 'naturalGasCons',
      label: 'Consumo de gas natural (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'glpCons',
      label: 'Consumo de GLP (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'dieselCons',
      label: 'Consumo de diesel (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'oilCons',
      label: 'Consumo de petróleo (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'biomassCons',
      label: 'Consumo de biomasa (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'coalCons',
      label: 'Consumo de carbón (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'waterCons',
      label: 'Consumo de agua (m³)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
  ];

  const initialValues = {
    name: process.name,
    material: process.material,
    description: process.description,
    lcStage: process.lcStage,
    massOut: process.massOut,
    massLoss: process.massLoss,
    finalRecycle: process.finalRecycle,
    incineration: process.incineration,
    sanitaryFill: 100 - process.finalRecycle - process.incineration,
    electricityCons: process.electricityCons,
    countryName: process.country.name,
    naturalGasCons: process.naturalGasCons,
    glpCons: process.glpCons,
    dieselCons: process.dieselCons,
    oilCons: process.oilCons,
    biomassCons: process.biomassCons,
    coalCons: process.coalCons,
    waterCons: process.waterCons,
  };

  const validationSchema = Yup.object().shape(
    fields.map(x => ({ [x.name]: x.validation })).reduce(mergeObjects)
  );

  return (
    <>
      <ConfirmDeleteDialog
        isOpen={isConfirmOpen}
        closeModal={handleCloseConfirm}
        {...confirmArgs}
      />
      <Grid
        columns={'1fr'}
        areas={['header', 'processEdit']}
        css={css`
          padding: 15px;
          height: 100%;
        `}
      >
        <Cell area="header">
          <ProcessHeader id={id} name={name} />
        </Cell>

        <Cell area="processEdit">
          <Paper css={css`margin: auto;`}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={updateProcessSubmit}
              handle
              render={({ handleSubmit, errors, dirty }) => (
                <Grid
                  columns={1}
                  areas={['fields', 'submit']}
                  css={css`
                    margin-top: 15px;
                    padding: 15px;
                    width: 650px;
                    height: 100%;
                    margin: auto;
                  `}
                  center
                  middle
                >
                  <Cell area="fields">
                    {fields.map(field => {
                      const {
                        InputComponent,
                        validation,
                        initialValue,
                        name,
                        label,
                        componentProps,
                        ...other
                      } = field;
                      return (
                        <InputComponent
                          key={name}
                          {...{ name, label }}
                          {...componentProps || {}}
                          {...other}
                        />
                      );
                    })}
                  </Cell>
                  <Cell area="submit">
                    <Grid columns="30% 30%" justifyContent="space-between">
                      <Cell>
                        <Button
                          onClick={() => {
                            openConfirmDeleteDialog({
                              title: 'Confirmar eliminación',
                              message:
                                '¿Estás seguro, que deseas eliminar el proceso seleccionado?',
                              callback: async () => deleteProcessSubmit(),
                            });
                          }}
                          style={{
                            backgroundColor: '#e10050',
                            '&:hover': {
                              backgroundColor: '#9d0038',
                            },
                            color: 'white',
                          }}
                        >
                          Eliminar
                          <DeleteIcon style={{ marginLeft: '10px' }} />
                        </Button>
                      </Cell>
                      <Cell>
                        <Button
                          color="primary"
                          onClick={handleSubmit}
                          disabled={!(dirty && isEmpty(errors))}
                        >
                          Guardar Cambios
                        </Button>
                      </Cell>
                    </Grid>
                  </Cell>
                </Grid>
              )}
            />
          </Paper>
        </Cell>
      </Grid>
    </>
  );
};

export default ProcessRoute;

import React from 'react';
import { saveAs } from 'file-saver';
import { Button } from '@material-ui/core';
import { ReactComponent as ExcelSvg } from '@fortawesome/fontawesome-free/svgs/solid/file-excel.svg';
import SvgCircle from 'components/styled/SvgCircle';

import { ResultStages, ResultTypes, ResultUnits } from 'consts/results';
import { mergeArrays } from 'utils';
import { FunctionalUnitMap } from 'consts';

const ExcelJS = require("exceljs/dist/exceljs");

// TODO? get color from pallette
const greenFill = '#1AA58A';

export const XLSXButton = ({ components = [], project = {} }) => {
  async function saveFile(fileName, workbook) {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    saveAs(
        new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        fileName
    );
  }    

  function parseResultsTree(componentTree) {
    const results = Object.keys(ResultTypes)
      .map(type =>
        Object.keys(ResultStages).map(stage => {
          const TypeFactor = ['CONSUMO_DE_AGUA', 'EMISIONES_GEI', 'GENERACION_DE_RESIDUOS'].includes(
            type
          )
            ? 1e3
            : 1;
          return {
            type: ResultTypes[type],
            stage: ResultStages[stage],
            value: componentTree[type][stage] * TypeFactor,
            unit: ResultUnits[type],
          };
        })
      )
      .reduce(mergeArrays, []);
  
    return results;
  }
  
  const handleButtonClick = () => {
    if (!components || !project) return;

    let componentArray = [];

    const getComponentResultsData = (component, currentDepth = 1, rootName = '') => {
      const {
        id,
        name,
        weight: { value: weightValue, metric: weightMetric },
        resultsTree,
        children,
      } = component;
      const root = currentDepth === 1 ? name : rootName;
      const weightInGrams = weightMetric === 'KILOGRAMS' ? weightValue * 1e3 : weightValue;
      const resultsTreeObject = JSON.parse(resultsTree);
      const parsedResults = parseResultsTree(resultsTreeObject);
      const data = {
        id,
        name,
        weightInGrams,
        results: parsedResults,
        depth: currentDepth,
        root,
      };
      componentArray.push(data);
      (children || []).forEach(child => {
        getComponentResultsData(child, currentDepth + 1, root);
      });
    };

    (project.components || []).forEach(component => {
      getComponentResultsData(component);
    });

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('Resultados');
    worksheet.addRow([
      'Nivel',
      'Producto Cima',
      'Nombre',
      'Masa (gr)',
      'Resultado',
      'Unidad',
      'Etapa',
      'Valor',
    ]);

    componentArray.forEach(component => {
      const { name, weightInGrams, results, depth, root } = component;
      const componentResults = results.map(result => {
        const { type, stage, value, unit } = result;
        worksheet.addRow([depth, root, name, weightInGrams, type, unit, stage, value]);
      });
    });

    const fileName = `resultados_${project.name}_uf${project.functionalUnitMagnitude}${FunctionalUnitMap[project.functionalUnit]}.xlsx`;
    
    saveFile(fileName, workbook);    
  };

  return (
    <Button onClick={handleButtonClick}>
      <SvgCircle
        svg={ExcelSvg}
        diameter="50px"
        borderRadius="15%"
        color={greenFill}
        style={{ margin: '5px' }}
      />
    </Button>
  );
};

export default XLSXButton;

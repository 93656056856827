import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { Grid, Cell } from 'styled-css-grid';
import { Flex } from 'rebass';

import StackBarChart from 'components/StackBarChart/StackBarChart';
import { ResultStages, ResultTypesAndUnit } from 'consts/results';
import { mergeObjects } from 'utils';

// TODO get from theme
const greenColor = '#56AF89';
const CATEGORIES = Object.values(ResultTypesAndUnit);
const STAGES = Object.values(ResultStages);

function ImpactComparisonGraphs({ components = [] }) {
  // category tabs
  const [tabValue, setTabValue] = useState(CATEGORIES[0]);
  function handleTabChange(event, value) {
    setTabValue(value);
  }
  // {[component.name]: {[type]: {[stage]: value}}}
  let productsCategories = components
    .map(({ name, resultsTree }) => {
      const resultsTreeObject = JSON.parse(resultsTree);

      const groupedResultsByType = Object.keys(ResultTypesAndUnit)
        .map(type => ({
          [ResultTypesAndUnit[type]]: Object.keys(ResultStages)
            .map(stage => {
              const typeFactor = [
                'CONSUMO_DE_AGUA',
                'EMISIONES_GEI',
                'GENERACION_DE_RESIDUOS',
              ].includes(type) ? 1e3 : 1;
              return {
                [ResultStages[stage]]: resultsTreeObject[type][stage] * typeFactor,
              };
            })
            .reduce(mergeObjects, {}),
        }))
        .reduce(mergeObjects, {});

      return { [name]: groupedResultsByType };
    })
    .reduce(mergeObjects, {});

  const productStagesImpact = Object.entries(productsCategories).map(([name, results]) => ({
    name,
    ...results[tabValue],
  }));

  // relative stages impact data
  let productsRelativeStagesImpact = productStagesImpact.map(x => {
    const { name, ...stages } = x;
    let totalImpact = Object.values(stages).reduce((a, b) => a + b, 0);
    totalImpact = totalImpact > 0 ? totalImpact : 1;
    return {
      name,
      ...Object.entries(stages)
        .map(([k, v]) => ({ [k]: (100.0 * v) / totalImpact }))
        .reduce(mergeObjects, {}),
    };
  });

  const recycleNameValues = [
    'Material Reciclado',
    'Material Virgen Renovable',
    'Material Virgen No Renovable',
  ];

  // relative stages impact data
  const productsRelativeRecycleImpact = components.map(({ name, origin }) => ({
    name,
    [recycleNameValues[0]]: origin.recycledMaterial,
    [recycleNameValues[1]]: origin.renewableMaterial,
    [recycleNameValues[2]]: origin.nonRenewableMaterial,
  }));

  return (
    <>
      <Tabs centered value={tabValue} onChange={handleTabChange}>
        {CATEGORIES.map(category => (
          <Tab label={category} key={category} value={category} />
        ))}
      </Tabs>

      <Flex mt="1em" mb="2em" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h5" style={{ color: greenColor }}>
            Impactos absolutos por etapa
          </Typography>
          <Typography>{tabValue} por etapa del ciclo de vida, para cada producto</Typography>
        </Flex>
      </Flex>

      <StackBarChart data={productStagesImpact} valuesKeys={STAGES} />

      <Flex mt="1em" mb="2em" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h5" style={{ color: greenColor }}>
            Impactos relativos por etapa
          </Typography>
          <Typography>
            {tabValue}: Aporte porcentual de las etapas del ciclo de vida, para cada producto
          </Typography>
        </Flex>
      </Flex>
      <StackBarChart
        data={productsRelativeStagesImpact}
        valuesKeys={STAGES}
        echartsOptions={{ xAxis: { min: 0, max: 100 } }}
      />

      {/* <h1> Porcentaje de material por tipo de origen </h1> */}
      <Flex mt="1em" mb="2em" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h5" style={{ color: greenColor }}>
            Porcentaje de material por tipo de origen
          </Typography>
          <Typography>Porcentaje de material según origen, para cada producto</Typography>
        </Flex>
      </Flex>
      <StackBarChart
        data={productsRelativeRecycleImpact}
        valuesKeys={recycleNameValues}
        echartsOptions={{ xAxis: { min: 0, max: 100 } }}
      />
    </>
  );
}

export default ImpactComparisonGraphs;

import React, { useEffect, useReducer } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Button, CircularProgress } from '@material-ui/core';
import { ReactComponent as PdfSvg } from '@fortawesome/fontawesome-free/svgs/solid/file-pdf.svg';

import SvgCircle from 'components/styled/SvgCircle';
import { ResultsGraphContainer } from './ResultsGraphContainer';
import PdfDocument from './PdfDocument';

import { FunctionalUnitMap } from 'consts';

const reducer = (state, action) => {
  switch (action.type) {
    case 'start': {
      return { ...state, loading: true };
    }
    case 'finish': {
      return { loading: false, isPdfReady: false, images: {} };
    }
    case 'imagesReady': {
      return { ...state, isPdfReady: true, images: action.payload.images };
    }
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  isPdfReady: false,
  images: {},
};

// TODO? get color from pallette
const greenFill = '#1AA58A';
export const PdfResultsButton = ({ components = [], project = {} }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, isPdfReady, images } = state;
  const { name: projectName } = project;

  const fileName = `resultados_${project.name}_uf${project.functionalUnitMagnitude}${FunctionalUnitMap[project.functionalUnit]}.pdf`;

  const handleButtonClick = () => {
    dispatch({ type: 'start' });
  };

  useEffect(() => {
    if (isPdfReady && images && projectName) {
      async function downloadPdf() {
        const document = PdfDocument({ images, project });
        const blob = await pdf(document).toBlob();
        saveAs(blob, fileName);
      }
      downloadPdf();
    }
  }, [isPdfReady, images, project]);

  return (
    <Button onClick={handleButtonClick}>
      {!loading && (
        <SvgCircle
          svg={PdfSvg}
          diameter="50px"
          borderRadius="15%"
          color={greenFill}
          style={{ margin: '5px' }}
        />
      )}
      {loading && (
        <>
          <CircularProgress />
          <ResultsGraphContainer components={components} project={project} dispatch={dispatch} />
        </>
      )}
    </Button>
  );
};

export default PdfResultsButton;

import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { FunctionalUnitMap } from 'consts';

import { PdfHeader } from './PdfHeader';
import { PdfFooter } from './PdfFooter';

// TODO get from theme
const greenColor = '#56AF89';

const robotoURI = 'https://github.com/google/fonts/blob/master/apache/roboto/Roboto-Regular.ttf';

const StyledDocument = styled.Document`
  @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: ${robotoURI};
  }
`;

const Container = styled.View`
  display: flex;
  flex-direction: column;
  margin: 1cm;
`;

const Title = styled.Text`
  text-align: center;
  color: ${greenColor};
  font-size: 24;
`;

const Subtitle = styled.Text`
  text-align: center;
  font-size: 12;
`;

const StyledImage = styled.Image`
  margin-top: 5mm;
  margin-bottom: 5mm;
`;

const CustomPage = ({ children, projectName }) => (
  <Page size="A4">
    <Container>
      <PdfHeader />
      {children}
    </Container>
    <PdfFooter projectName={projectName} />
  </Page>
);

export const PdfDocument = ({ images = [], project = {} }) => {
  const { summaryTable, radar, water, energy, fossil, gei, waste, recycleOrigin } = images;
  const { name: projectName, functionalUnit, functionalUnitMagnitude, owner = {} } = project;
  const { email = '' } = owner;
  return (
    <StyledDocument>
      <CustomPage projectName={projectName}>
        <View
          style={{
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Title>Reporte de Análisis de Ciclo de Vida EcoPackaging</Title>

          <View style={{ fontSize: 12, marginTop: '5px', width: '100%' }}>
            <Text>Proyecto: {projectName}</Text>
            <Text>
              Unidad Funcional: {FunctionalUnitMap[functionalUnit]} {functionalUnitMagnitude}
            </Text>
            <Text>Autor: {email}</Text>
          </View>
        </View>

        <Title style={{ marginTop: '10px' }}>Resumen de Productos</Title>

        <StyledImage style={{ marginTop: '15px' }} src={summaryTable} />

        <Title style={{ marginTop: '20px' }}>Comparación de impactos</Title>
        <Subtitle>
          A continuación se presenta una comparación de los principales impactos de manera gráfica
        </Subtitle>
        <StyledImage src={radar}/>
      </CustomPage>

      <CustomPage projectName={projectName}>
        <StyledImage src={water} />

        <StyledImage src={energy} />
      </CustomPage>
      <CustomPage projectName={projectName}>
        <StyledImage src={fossil} />
        <StyledImage src={gei} />
      </CustomPage>
      <CustomPage projectName={projectName}>
        <StyledImage src={waste} />
        <StyledImage src={recycleOrigin} />
      </CustomPage>
    </StyledDocument>
  );
};

export default PdfDocument;

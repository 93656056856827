import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';

const propTypes = {
  /** Data of the radar chart, array of objects. Objects must contain indicatorKeys and itemKey */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Keys of the indicators in  data */
  indicatorKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Key of the item in data */
  itemKey: PropTypes.string,
};

const defaultProps = {
  itemKey: 'name',
}

/**
 * Radar chart using Echarts.
 * example: https://ecomfe.github.io/echarts-examples/public/editor.html?c=radar 
 */
function RadarChart({ data, itemKey, indicatorKeys, ...rest }) {

  // const items
	
// Paleta colores elegida
  const colorPalette = ['#1381B9','#1FC29F','#782689','#71E8D2'];

  const indicators = indicatorKeys.map(indicator => ({
    name: indicator,
    max: Math.max(...data.map(x => x[indicator] > 0 ? x[indicator] : 1)),
  }))


  const series = {
    type: 'radar',
    itemStyle: {normal: {areaStyle: {type: 'default'}}},
    data: data.map(x => ({
      name: x[itemKey],
      value: indicatorKeys.map(indicator => x[indicator])
    })),
	color:colorPalette
  };

  const options = {
    legend: {
      data: data.map(x => x[itemKey]),
    },
    tooltip: {},
    radar: {
      name: {
        textStyle: {
          color: '#fff',
          backgroundColor: '#999',
          borderRadius: 3,
          padding: [3, 5]
        }
      },
      indicator: indicators,
    },
    series,
  };

  return (
    <ReactEcharts
      option={options}
      {...rest}
    />
  );
}

RadarChart.propTypes = propTypes;
RadarChart.defaultProps = defaultProps;

export default RadarChart;
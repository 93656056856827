import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  divider: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
});

const ProjectName = styled.div`
  color: #56af89;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  margin: auto auto auto 20px;
`;

const ProjectLink = styled(Link)`
  text-decoration: none;
  color: #56af89;
  &:visited {
    color: #56af89;
  }
`;

export const PublicProjectsTable = withStyles(styles)(({ projects = [], classes }) => {
  return (
    <Table
      css={css`
        border: 1px solid #bdbdbd;
      `}
    >
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '30%' }} className={classes.divider}>
            Nombre
          </TableCell>
          <TableCell style={{ width: '45%' }} className={classes.divider}>
            Descripción
          </TableCell>
          <TableCell>Autor</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {projects.map(project => {
          const { id, name, description, owner } = project;

          return (
            <TableRow className={classes.row} key={id}>
              <TableCell className={classes.divider}>
                <ProjectName>
                  <ProjectLink to={`/projects/${id}/design`}>{name}</ProjectLink>
                </ProjectName>
              </TableCell>
              <TableCell className={classes.divider}>{description}</TableCell>
              <TableCell>
                {owner.info.name=='ROOT' ? 'Caso de Estudio':owner.info.name} {owner.info.lastName=='ADMIN' ? '':owner.info.lastName}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
});

export default PublicProjectsTable;

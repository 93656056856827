import React from 'react';
import { css } from 'styled-components/macro';
import { Grid, Cell } from 'styled-css-grid';

import AppHeader from 'components/AppHeader';
import AppSidebar from 'components/AppSidebar';

const sideBarWidth = '130px';

export const AppLayout = ({ children }) => {
  return (
    <>
      <Grid
        gap="0px"
        columns={`${sideBarWidth} calc(100vw - ${sideBarWidth})`}
        rows={`50px calc(100vh - 50px)`}
        areas={['header header', 'sidebar content']}
      >
        <Cell
          area="header"
          css={css`
            position: fixed;
          `}
        >
          <AppHeader />
        </Cell>
        <Cell
          area="sidebar"
          css={css`
            position: fixed;
            top: 50px;
            & > div {
              height: 100%;
            }
          `} // TODO react reach router patch
        >
          <AppSidebar />
        </Cell>
        <Cell
          css={css`
            overflow-y: auto;
          `}
          area="content"
        >
          {children}
        </Cell>
      </Grid>
    </>
  );
};

export default AppLayout;

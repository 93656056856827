import React from 'react';
import { css } from 'styled-components/macro';
import { useQuery } from 'react-apollo-hooks';
import { Grid } from 'styled-css-grid';
import { Paper, Typography, Divider } from '@material-ui/core';
import { Flex, Box } from 'rebass';
import { NoteAdd as NoteAddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { GET_PROJECTS } from 'gql/queries';
import { ProjectsTable } from '../../components/ProjectsTable/ProjectsTable';
import { PublicProjectsTable } from '../../components/PublicProjectsTable/PublicProjectsTable';
import { useModal } from 'hooks';
import { CreateProjectDialog } from '../../components/CreateProjectDialog/CreateProjectDialog';

import { Button } from 'components/styled/Button';

const ProjectsRouteHeader = () => {
  const [openSignupDialog] = useModal(CreateProjectDialog);

  return (
    <Flex>
      <Typography color="primary" variant="h6">
        Mis Proyectos
      </Typography>
      <Flex ml="auto" alignItems="center">
        <Button onClick={openSignupDialog} color="primary">
          <NoteAddIcon
            css={css`
              margin-right: 1rem;
            `}
          />
          Crear Proyecto
        </Button>
      </Flex>
    </Flex>
  );
};

const PublicProjectsRouteHeader = () => (
  <Flex>
    <Typography color="primary" variant="h6">
      Proyectos Públicos
    </Typography>
  </Flex>
);

const SelfProjects = props => (
  <Grid columns="1fr" rows="1fr" justifyContent="center">
    <Paper
      css={css`
        width: 70%;
        margin: 60px auto 0;
      `}
    >
      <Grid
        columns="1fr"
        css={css`
          padding: 1rem;
        `}
      >
        <ProjectsRouteHeader />
        <Divider style={{ width: '100%', margin: '10px auto' }} />
        <ProjectsTable projects={props.projects} />
      </Grid>
    </Paper>
  </Grid>
);

const PublicProjects = props => (
  <Grid columns="1fr" rows="1fr" justifyContent="center">
    <Paper
      css={css`
        width: 70%;
        margin: 60px auto 0;
      `}
    >
      <Grid
        columns="1fr"
        css={css`
          padding: 1rem;
        `}
      >
        <PublicProjectsRouteHeader />
        <Divider style={{ width: '100%', margin: '10px auto' }} />
        <PublicProjectsTable projects={props.projects} />
      </Grid>
    </Paper>
  </Grid>
);

export const ProjectsRoute = ({ path }) => {
  const { data, error, loading } = useQuery(GET_PROJECTS);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const { publicProjects = [] } = data;
  const { projects = [] } = data.me;

  return (
    <>
      <SelfProjects projects={projects} />
      <PublicProjects projects={publicProjects} />
    </>
  );
};

export default ProjectsRoute;

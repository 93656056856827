import React, { useContext } from 'react';
import {
  Typography,
} from '@material-ui/core'
import { Grid, Cell } from 'styled-css-grid';
import { Flex } from 'rebass'

import EcoequivalencyWaterTable from './EcoequivalencyWaterTable';
import { FunctionalUnitMap } from 'consts';


function EcoequivalencyGEI({ components = [], project }) {

  const mappedComponents = components.map(({ name, resultsTree }) => {
    const resultsTreeObject = JSON.parse(resultsTree);
    return {
      name,
      gei: Object.values(resultsTreeObject['CONSUMO_DE_AGUA'])
        .reduce((a, b) => (a + b), 0),
    };
  });

  const projectFunctionalUnit = (project && project.functionalUnit);
  const projectMagnitude = (project && project.functionalUnitMagnitude);

  return (
    <Grid
      columns={1}
      rowGap='2em'
    >

      <Typography variant='h6'>
        <i>El consumo de agua asociado a cada producto por
        <b>{` ${projectMagnitude} ${FunctionalUnitMap[projectFunctionalUnit]} `}</b>
          de capacidad son equivalentes a lo siguiente:
        </i>
      </Typography>
      <Flex justifyContent='center'>
        <EcoequivalencyWaterTable components={mappedComponents} />
      </Flex>
    </Grid>
  )

}

export default EcoequivalencyGEI;
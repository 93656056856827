import React from 'react';
import { Grid, Cell } from 'styled-css-grid';
import { Flex, Box } from 'rebass';
import styled, { css } from 'styled-components/macro';
import { Paper, Button, Typography } from '@material-ui/core';
import {
  Create as CreateIcon,
  InsertChartOutlined as InsertChartOutlinedIcon,
  List as ListIcon,
  Settings as SettingsIcon,
  Home  as DocumentIcon,
} from '@material-ui/icons';
import useReactRouter from 'use-react-router';
import { Link, matchPath } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useAuthToken } from 'hooks';

// constants

const sideBarWidth = '130px';
const sideBarItemHeight = '100px';

// TODO finish
const SidebarItemContainer = styled.div`
  height: ${sideBarItemHeight};
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #9f9f9f; // gray
  font-size: 14px;
  width: 100%;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: #5aae8a;
      font-weight: bold;
      font-size: 20px;
      border-left: 7px solid #56af89;
    `}
`;

const SidebarItem = ({ path, text, icon: Icon }) => {
  const { location = {} } = useReactRouter();
  const { pathname } = location;
  const match = matchPath(pathname, { path });
  const { isExact = false } = match || {};
  const isSelected = isExact;
  const iconSize = isSelected ? 'large' : 'default';

  return (
    <Cell center middle>
      <SidebarItemContainer isSelected={isSelected}>
        <Link
          to={path}
          css={css`
            height: 100%;
            width: 100%;
          `}
        >
          <Button
            css={css`
              height: 100%;
              width: 100%;
            `}
          >
            <Flex flexDirection="column">
              {Icon && (
                <Box>
                  <Icon fontSize={iconSize} />
                </Box>
              )}
              <Box>
                <Typography style={{ textDecoration: 'none' }}>{text}</Typography>
              </Box>
            </Flex>
          </Button>
        </Link>
      </SidebarItemContainer>
    </Cell>
  );
};

export const Sidebar = props => {
  const { location = {} } = useReactRouter();
  const { authToken } = useAuthToken();
  const { pathname } = location;
  const match = matchPath(pathname, {
    path: '/projects/:projectId/*',
  });
  const { projectId = null } = (match && match.params) || {};

  //TODO fix authoken equals to 'null'
  if (isEmpty(authToken) || authToken == 'null') {
    return null;
  }

  return (
    <Paper>
      <Grid
        columns={sideBarWidth}
        css={css`
          grid-auto-rows: ${sideBarItemHeight};
        `}
      >
        <SidebarItem path="/documents" text="Documentos de Interés" icon={DocumentIcon} />
        <SidebarItem path="/projects" text="Mis Proyectos" icon={ListIcon} />
        <SidebarItem path="/processes" text="Mis Procesos" icon={SettingsIcon} />
        {projectId && (
          <>
            <SidebarItem path={`/projects/${projectId}/design`} text="Diseño" icon={CreateIcon} />
            <SidebarItem
              path={`/projects/${projectId}/results`}
              text="Resultados"
              icon={InsertChartOutlinedIcon}
            />
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default Sidebar;

import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

export const ConfirmDeleteDialog = ({ isOpen, closeModal, message, title, callback }) => {
  const handleAcceptAction = () => {
    callback();
    closeModal();
  };

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle>{title ? title : 'Confirmar'}</DialogTitle>
      <DialogContent
        css={css`
          font-family: Roboto;
        `}
      >
        {message}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAcceptAction}>Aceptar</Button>
        <Button onClick={closeModal}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;

import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { Paper } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { Grid, Cell } from 'styled-css-grid';

import ComponentTabs from './ComponentTabs';
import NameEdit from './ComponentNameAndWeight';
import OriginEdit from './ComponentOrigin';
import FinalDispositionEdit from './ComponentFinalDisposition';
import ProcessEdit from './ComponentProcess';
import TransportsEdit from './ComponentTransports';
import { ProjectRouteContext } from '../../routes/ProjectRoute/ProjectRouteContext';
import { GET_COMPONENT } from 'gql/queries';
import { useQuery } from 'react-apollo-hooks';

const Header = styled.div`
  width: 100%;
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #56af89;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  margin-left: 20px;
`;

const ComponentName = styled.div`
  color: #6c6464;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 900;
  line-height: 18px;
  margin: auto;
`;
// TODO update parents after updating component
export const ComponentEdit = ({ project }) => {
  const [{ selectedNodeId }] = useContext(ProjectRouteContext);
  const [currentTab, setCurrentTab] = useState(0);

  const { data = {}, error, loading } = useQuery(GET_COMPONENT, {
    variables: { id: selectedNodeId },
    skip: !selectedNodeId,
  });

  const { errors } = data;

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !isEmpty(errors)) {
    return <div>Error! {error.message}</div>;
  }
  let {component = {}} = data
  if (isEmpty(component)) return null;
  const isRoot = Boolean(project.components.find(({id}) => id === component.id));
  component = {...component, isRoot}

  const tabValueMapping = component && {
    0: <NameEdit component={component} project={project} />,
    1: <OriginEdit component={component} project={project} />,
    2: <TransportsEdit component={component} project={project} />,
    3: <ProcessEdit component={component} project={project} />,
    4: <FinalDispositionEdit component={component} project={project} />,
  };

  return (
    <Grid columns="1fr" rows="auto auto" areas={['header', 'content']}>
      <Cell area="header">
        <Paper>
          <Header>
            <Title>DETALLE DEL COMPONENTE</Title>
            <ComponentName>{component.name}</ComponentName>
          </Header>
        </Paper>
      </Cell>

      <Cell area="content">
        <Grid columns="1fr" rows="auto auto auto" areas={['tabs', 'form', 'submit']}>
          <Cell area="tabs">
            <ComponentTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
          </Cell>
          <Cell area="form">{tabValueMapping[currentTab] || null}</Cell>
          <Cell area="submit" />
        </Grid>
      </Cell>
    </Grid>
  );
};

export default ComponentEdit;

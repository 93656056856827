import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useField } from 'formik';
import { Select, OutlinedInput, InputLabel, FormControl } from '@material-ui/core';

export const SelectField = ({ name, label, style, fullWidth, onChange, ...other }) => {
  const [field, meta] = useField(name);
  const { onChange: onFieldChange, ...otherFieldProps } = field;
  const [labelWidth, setLabelWidth] = useState(0);
  const labelRef = useRef(null);

  useEffect(() => {
    if (label) setLabelWidth(ReactDOM.findDOMNode(labelRef.current).offsetWidth);
  }, [label]);

  return (
    <FormControl variant="outlined" style={style} fullWidth>
      {label && <InputLabel ref={labelRef}>{label}</InputLabel>}
      <Select
        input={<OutlinedInput labelWidth={labelWidth} />}
        onChange={(...args) => {
          onChange(...args);
          onFieldChange(...args);
        }}
        {...other}
        {...otherFieldProps}
      />
    </FormControl>
  );
};

SelectField.defaultProps = {
  label: '',
  fullWidth: true,
  style: {
    marginBottom: '15px',
  },
  children: [],
  onChange: () => {},
};

export default SelectField;

import React from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { PersonOutlined as PersonOutlinedIcon } from '@material-ui/icons';
import { Box, Flex } from 'rebass';
import useReactRouter from 'use-react-router';

import { useAuthToken } from 'hooks';
export const UserHeaderSection = React.memo(({ email }) => {
  const { history } = useReactRouter();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { setAuthToken } = useAuthToken();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    setAuthToken(null);
    handleClose();
    history.push('/');
  };

  return (
    <Flex>
      <Box alignSelf="center">
        <Typography variant="subtitle1" color="inherit">
          Hola, <b>{email}</b>
        </Typography>
      </Box>
      <IconButton onClick={handleClick}>
        <PersonOutlinedIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
      </Menu>
    </Flex>
  );
});

export default UserHeaderSection;

import React from 'react';

import RadarChart from 'components/RadarChart/RadarChart';

import { ResultStages, ResultTypes } from 'consts/results';
import { mergeObjects } from 'utils';

export const ImpactComparisonRadar = ({ components = [] }) => {
  // {[component.name]: {[resultType]: totalValue}}
  const productsCategories = components
    .map(({ name, resultsTree }) => {
      const resultsTreeObject = JSON.parse(resultsTree);

      let totalTypesValue = Object.entries(ResultTypes)
        .map(([type, typeValue]) => ({
          [typeValue]: Object.values(resultsTreeObject[type]).reduce((a, b) => a + b, 0),
        }))
        .reduce(mergeObjects, {});
      return { [name]: totalTypesValue };
    })
    .reduce(mergeObjects, {});
  Object.keys(productsCategories).forEach(k => {
    productsCategories[k]['Consumo de agua'] *= 1e3;
    productsCategories[k]['Emisiones GEI'] *= 1e3;
    productsCategories[k]['Generación de residuos'] *= 1e3;
  });

  // const productsCategories = components.map(({ name, results }) => {
  //   const totalTypesValue = Object.values(ResultTypes).map(type => ({ [type]: 0 })).reduce(mergeObjects);
  //   results.forEach(({ type, value }) => {
  //     totalTypesValue[ResultTypes[type]] += value;
  //   });
  //   return ({ [name]: totalTypesValue });
  // }).reduce(mergeObjects, {});

  const productCategoriesImpact = Object.entries(productsCategories).map(([k, v]) => ({
    name: k,
    ...v,
  }));

  return (
    <RadarChart
      data={productCategoriesImpact}
      indicatorKeys={Object.values(ResultTypes)}
      style={{ height: '500px' }}
    />
  );
};

export default ImpactComparisonRadar;

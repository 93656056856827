import React from 'react';
import {
  SnackbarProvider as NotistackSnackbarProvider,
  useSnackbar as _useSnackbar,
} from 'notistack';

const providerDefaultProps = {

};

export const SnackbarProvider = (props) => (<NotistackSnackbarProvider {...providerDefaultProps} {...props} />);

export const useSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar } = _useSnackbar();
    return {
      enqueueSnackbar,
      closeSnackbar,
      enqueueErrorSnackbar: (message, options = {}) => enqueueSnackbar(message, { variant: 'error', ...options }),
      enqueueSuccessSnackbar: (message, options = {}) => enqueueSnackbar(message, { variant: 'success', ...options }),
      enqueueInfoSnackbar: (message, options = {}) => enqueueSnackbar(message, { variant: 'info', ...options }),
      enqueueWarningSnackbar: (message, options = {}) => enqueueSnackbar(message, { variant: 'warning', ...options }),
    };
  }
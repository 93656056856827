import React from 'react';
import { Grid, Cell } from 'styled-css-grid';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Link, Divider } from '@material-ui/core';
import { Box } from 'rebass';
import { css } from 'styled-components/macro';
import { isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';

import Button from 'components/styled/Button';
import LoginForm from 'components/LoginForm/LoginForm';
import SignupDialog from 'components/LoginForm/SignupDialog';
import PasswordRecoveryDialog from 'components/LoginForm/PasswordRecoveryDialog'
import { useAuthToken, useModal } from 'hooks';

const strings = {
  localStorage: {
    authTokenKey: 'authToken',
  },
  titles: {
    login: 'Ingresar',
  },
};

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '500',
    paddingBottom: '5px',
    margin: '15px',
  },
  signUp: {
    fontWeight: '500',
  },
}));

export const LoginRoute = ({ path }) => {
  const { authToken } = useAuthToken();
  const [openSignupDialog] = useModal(SignupDialog);
  const [openPasswordRecoveryDialog] = useModal(PasswordRecoveryDialog);
  const classes = useStyles();

  return (
    <Grid height="100%" gap="0px" columns={`1fr`} rows={`1fr`} className={classes.container}>
      <Cell middle center>
        <Box m="auto" width="400px">
          <Paper
            css={css`
              /* TODO quickfix */
              position: relative;
              top: -120px;
              left: -65px;
            `}
          >
            <Grid
              columns="400px"
              rows="1fr 3fr 1fr"
              areas={['title', 'form', 'signup']}
              rowGap="1em"
            >
              <Cell area="title" center middle>
                <Typography variant="h5" color="primary" className={classes.title}>
                  {strings.titles.login}
                </Typography>
                <Divider variant="middle" />
              </Cell>
              <Cell area="form">
                <LoginForm />
              </Cell>
              <Cell area="signup" center>
                <Link
                  variant="subtitle1"
                  component="button"
                  color="primary"
                  onClick={openSignupDialog}
                  className={classes.signUp}
                >
                  Crear nuevo usuario
                </Link>
                <Button onClick={openPasswordRecoveryDialog} variant="text" >
                  Recuperar Contrasena
                </Button>
              </Cell>
            </Grid>
          </Paper>
        </Box>
      </Cell>
    </Grid>
  );
};

export default LoginRoute;

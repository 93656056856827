import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';

export const domRefToPng = async domRef => {
  let elem = ReactDOM.findDOMNode(domRef.current);
  const uri = await html2canvas(elem).then(canvas => {
    return canvas.toDataURL('image/png');
  });
  return uri;
};

export default domRefToPng;

import React from 'react';
import { css } from 'styled-components/macro';
import { AppBar, Typography } from '@material-ui/core';
import { Box, Flex } from 'rebass';
import { ReactComponent as LogoSvg } from './svg/logo-eco-chile.svg';

import { useQuery } from 'react-apollo-hooks';
import { GET_ME } from 'gql/queries';
import { UserHeaderSection } from './UserHeaderSection';

// TODO move to consts
const headerHeight = '50px';
// /TODO consts

export const AppHeader = () => {
  const { data } = useQuery(GET_ME);
  const { me = {} } = data;
  const { email } = me;

  return (
    <Box
      css={css`
        height: ${headerHeight};
      `}
    >
      <AppBar>
        <Flex
          css={css`
            height: ${headerHeight};
          `}
        >
          <Box>
            <LogoSvg height={60} />
          </Box>
          {email && (
            <Box ml={'auto'} my="auto">
              <UserHeaderSection email={email} />
            </Box>
          )}
        </Flex>
      </AppBar>
    </Box>
  );
};

export default AppHeader;

import React from 'react';
import styled from 'styled-components/macro';
import { Box } from 'rebass';

export const FullBox = styled(Box)`
  height: 100%;
  width: 100%;
  padding: 5px 18px;
`;

export default FullBox;

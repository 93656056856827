import React from 'react';
import { isEmpty } from 'lodash';
import useReactRouter from 'use-react-router';
import { Redirect, matchPath } from 'react-router-dom';

import { useAuthToken } from 'hooks';
/**
 * Redirects to projects or login when in '/', depending if authToken is valid
 */
export const RootPathRedirect = () => {
  const { authToken } = useAuthToken();
  const { location = {} } = useReactRouter();
  const { pathname } = location;
  const match = matchPath(pathname, { path: '/', exact: true });

  if (!isEmpty(match)) {
    if (isEmpty(authToken) || authToken == 'null') {
      return <Redirect to="/login" />;
    }
    return <Redirect to="/projects" />;
  }

  return null;
};

export default RootPathRedirect;

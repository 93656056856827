import React from 'react';
import { Route } from 'react-router-dom';

import AppRoot from 'components/AppRoot';
import { AppLayout } from 'components/AppLayout';
import LoginRoute from 'routes/LoginRoute';
import ProjectsRoute from 'routes/ProjectsRoute';
import ProcessRoute from 'routes/ProcessRoute';
import ProcessesRoute from 'routes/ProcessesRoute';
import DocumentsRoute from 'routes/DocumentsRoute';
import ProjectRoute from 'routes/ProjectRoute';
import ProjectResultsRoute from 'routes/ProjectResultsRoute';
import RootPathRedirect from 'routes/HomeRoute/RootPathRedirect';
import ResetPasswordRoute from 'routes/ResetPasswordRoute';

const App = () => {
  return (
    <AppRoot>
      <AppLayout>
        <Route exact path="/" component={RootPathRedirect} />
        <Route exact path="/login" component={LoginRoute} />
        <Route exact path="/projects" component={ProjectsRoute} />
        <Route exact path="/projects/:id/design" component={ProjectRoute} />
        <Route exact path="/projects/:id/results" component={ProjectResultsRoute} />
        <Route exact path="/processes" component={ProcessesRoute} />
        <Route exact path="/process/:id/edit" component={ProcessRoute} />
        <Route exact path="/documents" component={DocumentsRoute} />
        <Route exact path="/resetPassword/:token" component={ResetPasswordRoute} />
      </AppLayout>
    </AppRoot>
  );
};

export default App;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Typography,
  Paper,
  Divider,
  ButtonBase
} from '@material-ui/core';
import { Flex } from 'rebass';
import { Add, Remove } from '@material-ui/icons'

// TODO get from theme 
const greenColor = '#1AA58A';

const Section = styled(Paper)`
  margin-top: 30px;
`
const Body = styled.div`
  padding: 1em; 
`

const propTypes = {
  /** Title to display on header */
  title: PropTypes.string,
  /** Alternative component to display on header */
  header: PropTypes.node,
}

function ResultsSection({ title, header, children, ...rest }) {

  const [isOpen, setIsOpen] = useState(true);

  function toggleOpen() {
    setIsOpen(!isOpen)
  }

  return (
    <Section>
      <Flex>
        <ButtonBase>
          {isOpen ?
            <Remove onClick={toggleOpen} />
            :
            <Add onClick={toggleOpen} />
          }
        </ButtonBase>
        {title &&
          <Typography
            variant='h5'
            style={{ color: greenColor }}>
            {title}
          </ Typography>
        }
        {header}
      </Flex>

      {isOpen &&
        <>
          <Divider />
          <Body {...rest}>
            {children}
          </Body>
        </>
      }

    </Section>
  )
}

ResultsSection.propTypes = propTypes;

export default ResultsSection;
import React from 'react';
import StackBarChart from 'components/StackBarChart/StackBarChart';

const recycleNameValues = [
  'Material Reciclado',
  'Material Virgen Renovable',
  'Material Virgen No Renovable',
];

export const PdfRecycleOriginGraph = ({ components = [] }) => {
  // relative stages impact data
  const productsRelativeRecycleImpact = components.map(({ name, origin }) => ({
    name,
    [recycleNameValues[0]]: origin.recycledMaterial,
    [recycleNameValues[1]]: origin.renewableMaterial,
    [recycleNameValues[2]]: origin.nonRenewableMaterial,
  }));

  return (
    <StackBarChart
      data={productsRelativeRecycleImpact}
      valuesKeys={recycleNameValues}
      echartsOptions={{ xAxis: { min: 0, max: 100 } }}
    />
  );
};

export default PdfRecycleOriginGraph;

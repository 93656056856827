import { isEmpty } from 'lodash';

export interface Node {
  children: Node[];
  id: string;
}
/**
 * 
 * @param id id of node to be found
 * @param tree root node
 * 
 * Returns path of nodes used to find node with selected id, from leaf to root.
 */
export const dfs = (id: string, node: Node): Node[] => {
  if (node.id === id) return [node];
  const { children = [] } = node;
  for (let n of children) {
    const result = dfs(id, n);
    if (!isEmpty(result)) return [...result, node];
  }
  return [];
}

export default dfs;
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Flex } from 'rebass';

import SvgCircle from 'components/styled/SvgCircle';
import { ReactComponent as WaterSvg } from './svg/water.svg';
import { ReactComponent as HandsSvg } from './svg/hands.svg';
import { ReactComponent as ShowerSvg } from './svg/shower.svg';

import { formatNumber, mergeObjects } from 'utils';
import styled, { css } from 'styled-components/macro';

const colorTable = css`
  border-right: 1px solid;
  border-right-color: #bdbdbd;
  border-top: 1px solid;
  border-top-color: #bdbdbd;
`;

const propTypes = {
  /** products = [{name, 'Emisiones GEI', ...rest}] */
  products: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  products: [],
};

const blueFill = '#1381B9';

const geiFactors = {
  consumoDiario: 1.0 / 170,
  ducha: 1.0 / 12 / 5,
  lavadoManos: 1.0 / 10,
};

const geiFactorsFuncs = Object.entries(geiFactors)
  .map(([k, v]) => ({
    [k]: gei => formatNumber((gei && gei * v) || 0),
  }))
  .reduce(mergeObjects, {});

const columns = [
  {
    svg: WaterSvg,
    geiFunc: gei => `${geiFactorsFuncs.consumoDiario(gei)} personas`,
    title: 'Consumo diario per cápita',
  },
  {
    svg: ShowerSvg,
    geiFunc: gei => `${geiFactorsFuncs.ducha(gei)} duchas`,
    title: 'Duchas de 5 minutos',
  },
  {
    svg: HandsSvg,
    geiFunc: gei => `${geiFactorsFuncs.lavadoManos(gei)} minutos`,
    title: 'Minutos lavándose las manos',
  },
];

function EcoequivalencyWaterTable({ components, ...rest }) {
  return (
    <Table
      {...rest}
      css={css`
        border: 1px solid;
        border-color: #bdbdbd;
      `}
    >
      <TableHead>
        <TableCell style={{ verticalAlign: 'bottom' }} css={colorTable}>
          <Typography variant="h6">Producto</Typography>
        </TableCell>
        {columns.map(({ svg, title }) => (
          <TableCell css={colorTable}>
            <Flex justifyContent="center" flexDirection="column" alignItems="center">
              <SvgCircle svg={svg} color={blueFill} diameter="100px" />
              <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                {title}
              </Typography>
            </Flex>
          </TableCell>
        ))}
      </TableHead>
      <TableBody>
        {components.map(({ name, gei }) => (
          <TableRow>
            <TableCell style={{ textAlign: 'left' }} css={colorTable}>
              <Typography variant="subtitle2" style={{ color: blueFill }}>
                {name}
              </Typography>
            </TableCell>
            {columns.map(({ geiFunc }) => (
              <TableCell style={{ textAlign: 'center', width: '25%' }} css={colorTable}>
                <Typography variant="body1">{geiFunc(gei)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

EcoequivalencyWaterTable.propTypes = propTypes;
EcoequivalencyWaterTable.defaultProps = defaultProps;

export default EcoequivalencyWaterTable;

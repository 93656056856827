import React from 'react';
import { css } from 'styled-components/macro';
import { useQuery } from 'react-apollo-hooks';
import { Grid } from 'styled-css-grid';
import { Flex } from 'rebass';

import ComponentsSummaryTable from 'components/ComponentsSummaryTable/ComponentsSummaryTable';
import ResultsSection from './ResultsSection';
import ImpactComparisonRadar from 'components/ImpactComparisonRadar';
import ImpactComparisonGraphs from 'components/ImpactComparisonGraphs';
import CSVButton from 'components/CSVButton';
import XLSXButton from 'components/XLSXButton';
import { PdfResultsButton } from 'components/PdfResults';
import { EcoequivalencyGEI } from 'components/EcoequivalencyGEI';
import EcoequivalencyWaste from 'components/EcoequivalencyWaste';
import EcoequivalencyWater from 'components/EcoequivalencyWater';
import { Typography } from '@material-ui/core';
import { FunctionalUnitMap } from 'consts';
import { GET_PROJECT_RESULTS } from 'gql/queries';

export const ProjectResultsRoute = ({ match }) => {
  const { id: projectId } = match.params;

  const { data, error, loading } = useQuery(GET_PROJECT_RESULTS, {
    variables: { id: projectId },
    fetchPolicy: 'no-cache',
  });
  if (loading || !data) return null;

  const { project = { components: [] } } = data;
  const { components } = project;
  const {
    functionalUnit: projectFunctionalUnit,
    functionalUnitMagnitude: projectMagnitude,
    name: projectName,
  } = project;

  return (
    <Grid
      columns="1fr"
      rowGap="1rem"
      css={css`
        margin: 20px;
      `}
    >
      <Typography variant="h4" style={{ color: '#1AA58A' }}>
        Resultados de Impactos de Proyecto {projectName}
      </Typography>
      <Typography variant="subtitle1">
        <i>
          Los indicadores de impacto presentados a continuación se presentan por
          <b>{` ${projectMagnitude} ${FunctionalUnitMap[projectFunctionalUnit]} `}</b>
          contenido en cada producto de interés{' '}
        </i>
      </Typography>

      <Flex flexDirection="row-reverse">
        <XLSXButton project={project} components={components} />
        <PdfResultsButton components={components} project={project} />
      </Flex>
      <ResultsSection title={'Resumen de productos'}>
        <ComponentsSummaryTable components={components} project={project} />
      </ResultsSection>

      <ResultsSection title={'Comparación de impactos'}>
        <ImpactComparisonRadar components={components} />
      </ResultsSection>

      <ResultsSection title={'Comparación de impactos por etapa'}>
        <ImpactComparisonGraphs components={components} />
      </ResultsSection>

      <ResultsSection title={'Ecoequivalencias Emisiones GEI'}>
        <EcoequivalencyGEI components={components} project={project} />
      </ResultsSection>

      <ResultsSection title={'Ecoequivalencias Consumo de Agua'}>
        <EcoequivalencyWater components={components} project={project} />
      </ResultsSection>

      <ResultsSection title={'Ecoequivalencias Generación de Residuos'}>
        <EcoequivalencyWaste components={components} project={project} />
      </ResultsSection>
    </Grid>
  );
};

export default ProjectResultsRoute;

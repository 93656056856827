import React, { useCallback } from 'react';
import { useMutation } from 'react-apollo-hooks';
import styled, { css } from 'styled-components/macro';
import { Grid, Cell } from 'styled-css-grid';
import { Box } from 'rebass';

import {
  FunctionalUnit,
  FunctionalUnitMap,
  functionalUnitMagnitude,
  functionalUnitCapacity,
} from 'consts';

import { MenuItem } from '@material-ui/core';
import { InputField } from 'components/InputField';
import { SelectField } from 'components/SelectField';
import { mergeObjects } from 'utils';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useSnackbar, useModal } from 'hooks';
import { CREATE_PROJECT } from 'gql/mutations';
import { GET_PROJECTS } from 'gql/queries';
import { CopyProjectDialog } from 'components/CopyProjectDialog/CopyProjectDialog';

import Tooltip from 'components/Tooltip';

// TODO set to const or project schema
const maxProjectNameLength = 60;

const fields = [
  {
    name: 'name',
    initialValue: '',
    label: 'Nombre*',
    validation: Yup.string()
      .max(maxProjectNameLength, 'Nombre muy largo')
      .required('Nombre no puede ser vacío'),
    InputComponent: InputField,
  },
  {
    name: 'description',
    label: 'Descripción',
    initialValue: '',
    InputComponent: InputField,
  },
  {
    name: 'functionalUnit',
    label: 'Unidad funcional*',
    initialValue: Object.keys(FunctionalUnit)[0],
    InputComponent: SelectField,
    componentProps: {
      children: Object.entries(FunctionalUnit).map(([k, v]) => (
        <MenuItem key={k} value={k}>
          {FunctionalUnitMap[v]}
        </MenuItem>
      )),
    },
  },
  {
    name: 'functionalUnitMagnitude',
    label: 'Magnitud de Unidad Funcional*',
    initialValue: 1,
    InputComponent: InputField,
    validation: Yup.number()
      .positive('Magnitud debe ser mayor o igual a 0')
      .required('Campo no puede ser vacio'), // TODO set default to 0
    componentProps: {
      type: 'number',
      inputProps: { min: '0', step: 'any' },
    },
  },
];

const initialValues = fields.map(x => ({ [x.name]: x.initialValue })).reduce(mergeObjects);

const validationSchema = Yup.object().shape(
  fields.map(x => ({ [x.name]: x.validation })).reduce(mergeObjects)
);

export const CreateProjectDialog = ({ isOpen, closeModal }) => {
  const [openCopyProjectDialog] = useModal(CopyProjectDialog);

  const createProjectMutation = useMutation(CREATE_PROJECT, {
    update: (cache, { data: { projectCreate = {} } }) => {
      const { errors } = projectCreate;
      if (!isEmpty(errors)) return;
      const { project } = projectCreate;
      const { me } = cache.readQuery({ query: GET_PROJECTS });
      const updatedProjects = [...me.projects, project];
      cache.writeQuery({ query: GET_PROJECTS, data: { me: { ...me, projects: updatedProjects } } });
    },
  });

  const { enqueueErrorSnackbar } = useSnackbar();
  const createProjectSubmit = useCallback(
    async (values, actions) => {
      try {
        const { data } = await createProjectMutation({ variables: { input: values } });
        const { project, errors } = data.projectCreate;
        if (!isEmpty(errors)) throw new Error(errors[0].message);
        closeModal();
      } catch (e) {
        enqueueErrorSnackbar(e.message);
      }
    },
    [closeModal]
  );

  const functionalUnitField = fields.find(({ name }) => name === 'functionalUnit');
  const functionalUnitMagnitudeField = fields.find(
    ({ name }) => name === 'functionalUnitMagnitude'
  );
  const otherFields = fields.filter(
    field => field != functionalUnitField && field != functionalUnitMagnitudeField
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createProjectSubmit}
      render={({ handleSubmit, errors, dirty }) => (
        <Dialog open={isOpen}>
          <DialogTitle>Nuevo Proyecto</DialogTitle>
          <DialogContent>
            <Grid
              columns={'minmax(500px, auto)'}
              rows={'auto'}
              rowGap="1rem"
              css={css`
                padding-top: 1rem;
              `}
            >
              {otherFields.map(field => {
                const {
                  InputComponent,
                  validation,
                  initialValue,
                  name,
                  label,
                  componentProps,
                  ...other
                } = field;
                return (
                  <InputComponent
                    key={name}
                    {...{ name, label }}
                    {...componentProps || {}}
                    {...other}
                  />
                );
              })}
              <Grid columns="70% 1fr">
                <Cell center middle>
                  <Tooltip text={functionalUnitMagnitude}>
                    <InputField
                      name={functionalUnitMagnitudeField.name}
                      label={functionalUnitMagnitudeField.label}
                      {...functionalUnitMagnitudeField.componentProps}
                    />
                  </Tooltip>
                </Cell>
                <Cell center>
                  <Tooltip text={functionalUnitCapacity}>
                    <SelectField
                      name={functionalUnitField.name}
                      label={functionalUnitField.label}
                      {...functionalUnitField.componentProps}
                    />
                  </Tooltip>
                </Cell>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Box justifyContent="flex-start">
              <Button
                onClick={() => {
                  openCopyProjectDialog();
                  closeModal();
                }}
              >
                Copiar proyecto existente
              </Button>
            </Box>
            <Box justifyContent="flex-end">
              <Button onClick={closeModal}>Cerrar</Button>
              <Button onClick={handleSubmit} disabled={!(dirty && isEmpty(errors))}>
                Crear Proyecto
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default CreateProjectDialog;

import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, withStyles } from '@material-ui/core';

const styles = theme => ({
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#56AF89',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit,
    fontFamily: 'Roboto',
    '&:hover': {
      color: '#56AF89',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#56AF89',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#56AF89',
    },
  },
});

class ComponentTabs extends React.Component {

  handleChange = (event, value) => {
    this.props.setCurrentTab(value);
  };

  render() {
    const { currentTab, classes } = this.props;

    const tabsLabels = [
      'Nombre y Peso',
      'Origen',
      'Transporte',
      'Procesos',
      'Disposición Final',
    ];

    return (
      <Tabs
        variant='fullWidth'
        value={currentTab || 0}
        onChange={this.handleChange}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        {tabsLabels.map(label => (
          <Tab
            key={label}
            label={label}
            classes={{ root: classes.tabRoot}}
          />
        ))}
      </Tabs>
    );
  }
}

ComponentTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ComponentTabs);
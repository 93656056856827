import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { useMutation } from 'react-apollo-hooks';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import useReactRouter from 'use-react-router';

import { Divider } from '@material-ui/core';
import { Grid, Cell } from 'styled-css-grid';
import Button from 'components/styled/Button';
import { Form } from 'components/Form';
import { InputField } from 'components/InputField';
import { useSnackbar, useAuthToken } from 'hooks';
import { RESET_PASSWORD } from 'gql/mutations';

const strings = {
  button: { submit: 'Confirmar' },
  input: {
    password: { label: 'Nueva contraseña' },
    confirmPassword: { label: 'Confirmar contraseña' },
  },
  errors: {
    password: {
      required: 'Contraseña es requerida',
    },
  },
};

const initialValues = {
  password: '',
  // confirmPassword: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required(strings.errors.password.required),
});

//TODO layout
export const ResetPasswordForm = ({ token }) => {
  const resetPasswordMutation = useMutation(RESET_PASSWORD);
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const resetPasswordSubmit = useCallback(async (values, actions) => {
    try {
      const { password } = values;
      const { data } = await resetPasswordMutation({
        variables: { input: { newPassword: password, token } },
      });
      const { errors } = data.resetPassword;
      if (!isEmpty(errors)) throw new Error(errors[0].message);
      enqueueSuccessSnackbar(`Contrasena cambiada con exito`);
      // history.push('/');
    } catch (e) {
      enqueueErrorSnackbar(e.message);
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={resetPasswordSubmit}
      render={({ handleSubmit, errors, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <Grid
            gap="0px"
            height="100%"
            columns={`1fr`}
            rows={`1fr 1fr`}
            // areas={['password', 'confirm-password', 'submit']}
            areas={['password', 'submit']}
          >
            <Cell area="password" center middle>
              <InputField name="password" type="password" label={strings.input.password.label} />
              <Divider variant="fullWidth" />
            </Cell>
            {/* <Cell area="confirm-password" center middle>
              <InputField
                name="confirm-password"
                type="password"
                label={strings.input.confirmPassword.label}
              /> 
            </Cell> */}
            <Cell area="submit" center middle>
              <Divider variant="fullWidth" />
              <Button type="submit" color="primary" disabled={!(dirty && isEmpty(errors))}>
                {strings.button.submit}
              </Button>
            </Cell>
          </Grid>
        </Form>
      )}
    />
  );
};

export default ResetPasswordForm;

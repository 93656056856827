import React from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { HashRouter } from 'react-router-dom';

import ApolloProvider from 'components/ApolloProvider';
import { ModalProvider, AuthTokenProvider, SnackbarProvider } from 'hooks';
import { materialUiTheme } from 'consts';

export const AppRoot = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <MuiThemeProvider theme={materialUiTheme}>
        <AuthTokenProvider>
          <ApolloProvider>
            <SnackbarProvider>
              <HashRouter>
                <ModalProvider>{children}</ModalProvider>
              </HashRouter>
            </SnackbarProvider>
          </ApolloProvider>
        </AuthTokenProvider>
      </MuiThemeProvider>
    </>
  );
};

export default AppRoot;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { Flex } from 'rebass';

import SvgCircle from 'components/styled/SvgCircle';
import { ReactComponent as TrashSvg } from './svg/trash.svg';
import { ReactComponent as TruckSvg } from './svg/truck.svg';
import { ReactComponent as TrashManSvg } from './svg/trash-man.svg';

import { formatNumber, mergeObjects } from 'utils';
import styled, { css } from 'styled-components/macro';

const colorTable = css`
  border-right: 1px solid;
  border-right-color: #bdbdbd;
  border-top: 1px solid;
  border-top-color: #bdbdbd;
`;

const propTypes = {
  /** products = [{name, 'Emisiones GEI', ...rest}] */
  products: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  products: [],
};

const purpleFill = '#782689';

const geiFactors = {
  trash: 1 / 1.2803,
  trashBag: 1 / 10,
  truck: 1 / 2500,
};

const geiFactorsFuncs = Object.entries(geiFactors)
  .map(([k, v]) => ({
    [k]: gei => formatNumber((gei && gei * v) || 0),
  }))
  .reduce(mergeObjects, {});

const columns = [
  {
    svg: TrashManSvg,
    geiFunc: gei => `${geiFactorsFuncs.trash(gei)} personas`,
    title: 'Generación de basura per cápita',
  },
  {
    svg: TrashSvg,
    geiFunc: gei => `${geiFactorsFuncs.trashBag(gei)} bolsas de basura`,
    title: 'Bolsas de basura llenas',
  },
];

function EcoequivalencyWasteTable({ components, ...rest }) {
  return (
    <Table
      {...rest}
      css={css`
        border: 1px solid;
        border-color: #bdbdbd;
      `}
    >
      <TableHead>
        <TableCell style={{ verticalAlign: 'bottom' }} css={colorTable}>
          <Typography variant="h6">Producto</Typography>
        </TableCell>
        {columns.map(({ svg, title }) => (
          <TableCell css={colorTable}>
            <Flex justifyContent="center" flexDirection="column" alignItems="center">
              <SvgCircle svg={svg} color={purpleFill} diameter="100px" />
              <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                {title}
              </Typography>
            </Flex>
          </TableCell>
        ))}
      </TableHead>
      <TableBody>
        {components.map(({ name, gei }) => (
          <TableRow>
            <TableCell style={{ textAlign: 'left' }} css={colorTable}>
              <Typography variant="subtitle2" style={{ color: purpleFill }}>
                {name}
              </Typography>
            </TableCell>
            {columns.map(({ geiFunc }) => (
              <TableCell style={{ textAlign: 'center', width: '33.3%' }} css={colorTable}>
                <Typography variant="body1">{geiFunc(gei)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

EcoequivalencyWasteTable.propTypes = propTypes;
EcoequivalencyWasteTable.defaultProps = defaultProps;

export default EcoequivalencyWasteTable;

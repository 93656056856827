import React from 'react';
import useMethods from 'use-methods';


const strings = {
  errors: {
    invariantViolation: 'Attempted to call useModal outside of context.',
  }
};

const invariantViolation = () => {
  throw new Error(strings.errors.invariantViolation);
};

const initialState: { nodes: object, selectedNodeId: String } = {
  nodes: {},
  selectedNodeId: null,
};

export const ComponentTreeContext = React.createContext([initialState, {
  addNode: invariantViolation,
  updateNode: invariantViolation,
} as any]);

const methods = state => ({
  addNode: node => {
    const { id, children = [] } = node;
    let { nodes } = state
    if (id in nodes) return;
    nodes[id] = { ...nodes[id] };
    children.forEach(({ id: childId }) => {
      nodes[childId] = { ...nodes[childId], parentId: id };
    });
  },
  updateNode: (id, node) => {

  }
})




export const ComponentTreeProvider = ({ children }) => {

  const [state, callbacks] = useMethods(methods, initialState);

  return (
    <ComponentTreeContext.Provider value={[state, callbacks]}>
      {children}
    </ComponentTreeContext.Provider>

  );
} 

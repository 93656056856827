import { createMuiTheme } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';

export const materialUiTheme = createMuiTheme({
  palette: {
    primary: {
		main:"#1AA58A",
		contrastText: '#ffffff',
		},
	secondary: {
		main:"#1FC29F",
		contrastText: '#ffffff',
	},
  },
});

export default materialUiTheme;

import { useState, Dispatch } from 'react';

const useLocalStorage = (
  key: string,
  initialValue: string = ''
): [string, Dispatch<string>] => {

  const [item, setValue] = useState(() => {
    const value = localStorage.getItem(key) || initialValue;
    localStorage.setItem(key, value);
    return value;
  });

  const setItem = (newValue: string) => {
    setValue(newValue);
    window.localStorage.setItem(key, newValue);
  };

  return [item, setItem];
}

export default useLocalStorage;


import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-apollo-hooks';

import { InputField } from 'components/InputField';
import { mergeObjects } from 'utils';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useSnackbar, useModal } from 'hooks';
import { PROJECT_CREATE_CHILD_COMPONENT, COMPONENT_CREATE_CHILD_COMPONENT } from 'gql/mutations';
import { Box } from 'rebass';
import { CopyComponentDialog } from 'components/CopyComponentDialog/CopyComponentDialog';

// TODO set to const or project schema
const maxProjectNameLength = 60;

const fields = [
  {
    name: 'name',
    initialValue: '',
    label: 'Nombre',
    validation: Yup.string()
      .max(maxProjectNameLength, 'Nombre muy largo')
      .required('Nombre no puede ser vacío'),
    InputComponent: InputField,
  },
];

const initialValues = fields.map(x => ({ [x.name]: x.initialValue })).reduce(mergeObjects);

const validationSchema = Yup.object().shape(
  fields.map(x => ({ [x.name]: x.validation })).reduce(mergeObjects)
);

export const CreateComponentDialog = ({
  isOpen,
  closeModal,
  projectParentId = null,
  projectId = null,
  componentId = null,
  isRootComponent = false,
}) => {
  const copyComponentDialog = useMemo(
    () => props => (
      <CopyComponentDialog
        projectParentId={projectParentId}
        componentId={componentId}
        isRootComponent={isRootComponent}
        {...props}
      />
    ),
    [projectParentId]
  );

  const [openCopyComponentDialog] = useModal(copyComponentDialog);

  const createProjectChildMutation = useMutation(PROJECT_CREATE_CHILD_COMPONENT);
  const createComponentChildMutation = useMutation(COMPONENT_CREATE_CHILD_COMPONENT);
  const { enqueueErrorSnackbar } = useSnackbar();
  const createComponentSubmit = useCallback(
    async (values, actions) => {
      try {
        const parentId = projectId || componentId;
        const input = { ...values, id: parentId };
        if (projectId) {
          const { data } = await createProjectChildMutation({ variables: { input } });
          const { errors } = data.projectCreateChildComponent;
          if (!isEmpty(errors)) throw new Error(errors[0].message);
        } else if (componentId) {
          const { data } = await createComponentChildMutation({
            variables: { input },
          });
          const { errors } = data.componentCreateChild;
          if (!isEmpty(errors)) throw new Error(errors[0].message);
        } else {
          throw new Error('Invalid ID provided');
        }
        closeModal();
      } catch (e) {
        enqueueErrorSnackbar(e.message);
      }
    },
    [closeModal, projectId, componentId]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createComponentSubmit}
      render={({ handleSubmit, errors, dirty }) => (
        <Dialog open={isOpen} fullWidth={true}>
          <DialogTitle>Ingresar Nuevo </DialogTitle>
          <DialogContent>
            {fields.map(f => {
              const {
                InputComponent,
                validation,
                initialValue,
                name,
                label,
                componentProps,
                ...other
              } = f;
              return (
                <InputComponent
                  key={name}
                  style={{ marginTop: '1em' }}
                  {...{ name, label }}
                  {...componentProps || {}}
                  {...other}
                />
              );
            })}
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Box justifyContent="flex-start">
              <Button
                onClick={() => {
                  openCopyComponentDialog();
                  closeModal();
                }}
              >
                Copiar componente existente
              </Button>
            </Box>
            <Box justifyContent="flex-end">
              <Button onClick={closeModal}>Cerrar</Button>
              <Button onClick={handleSubmit} disabled={!(dirty && isEmpty(errors))}>
                Crear
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default CreateComponentDialog;

export const ResultStages = {
  EXTRACCION_MATERIA_PRIMA: 'Extraccion Materia Prima',
  MANUFACTURA: 'Manufactura',
  TRANPOSRTE: 'Transporte',
  DISPOSICION_FINAL: 'Disposicion Final',
};

export const ResultTypes = {
  CONSUMO_DE_AGUA: 'Consumo de agua',
  CONSUMO_DE_ENERGIA: 'Consumo de energía',
  CONSUMO_DE_COMBUSTIBLES_FOSILES: 'Consumo de combustibles fósiles',
  EMISIONES_GEI: 'Emisiones GEI',
  GENERACION_DE_RESIDUOS: 'Generación de residuos',
};

export const ResultUnits = {
    CONSUMO_DE_AGUA: 'ml',
    CONSUMO_DE_ENERGIA: 'kWh',
    CONSUMO_DE_COMBUSTIBLES_FOSILES: 'kWh',
    EMISIONES_GEI: 'gr CO2e',
    GENERACION_DE_RESIDUOS: 'gr',
};

export const ResultTypesAndUnit = {
    CONSUMO_DE_AGUA: 'Consumo de agua (ml)',
    CONSUMO_DE_ENERGIA: 'Consumo de energía (kWh)',
    CONSUMO_DE_COMBUSTIBLES_FOSILES: 'Consumo de combustibles fósiles (kWh)',
    EMISIONES_GEI: 'Emisiones GEI (gr CO2e)',
    GENERACION_DE_RESIDUOS: 'Generación de residuos (gr)',
};
  
export const groupResultsByType = (results) => {
  return Object.keys(ResultTypes).map(resultType => {
    const filteredByType = results.filter(({ type }) => type === resultType);
    const totalValueSum = filteredByType.reduce((a, b) => (a + b.value), 0);
    return ({ [resultType]: totalValueSum })
  }).reduce((a, b) => ({ ...a, ...b }), {});
}
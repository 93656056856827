import React from 'react';
import { round } from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import styled, { css } from 'styled-components/macro';
import { FunctionalUnitMap } from 'consts';
import { ResultTypes } from 'consts/results';
import PropTypes from 'prop-types';

const colorTable = css`
  border-top: 1px solid;
  border-top-color: #bdbdbd;
`;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const ComponentsSummaryTableHeader = props => {
  const { order, orderBy, onRequestSort, rows } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {rows.map(row => (
          <TableCell
            key={row.id}
            // numeric={row.numeric}
            align="center"
            sortDirection={orderBy === row.id ? order : false}
            css={colorTable}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <Typography variant="subtitle1" style={{ color: '#1AA58A' }}>
                {row.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ComponentsSummaryTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.number.isRequired,
};

export const ComponentsSummaryTable = ({ components = [], project = {} }) => {
  const { functionalUnit } = project;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const rows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Producto'},
    {
      id: 'functionalUnitMagnitude',
      numeric: true,
      disablePadding: false,
      label: `Capacidad del Envase (${FunctionalUnitMap[functionalUnit]})`,
    },
    { id: 'weightValueInKilograms', numeric: true, disablePadding: false, label: 'Masa (gr)' },
    { id: 'consumoAgua', numeric: true, disablePadding: false, label: 'Consumo de Agua (ml)' },
    {
      id: 'consumoEnergia',
      numeric: true,
      disablePadding: false,
      label: 'Consumo de energía (kWh)',
    },
    {
      id: 'consumoCombustibleFosil',
      numeric: true,
      disablePadding: false,
      label: 'Consumo de combustibles fósiles (kWh)',
    },
    { id: 'emisionesGEI', numeric: true, disablePadding: false, label: 'Emisiones GEI (gr CO2e)' },
    {
      id: 'generacionResiduos',
      numeric: true,
      disablePadding: false,
      label: 'Generación de Residuos (gr)',
    },
  ];

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const mappedComponents = components.map(component => {
    const {
      id,
      name,
      weight: { value: weightValue, metric: weightMetric },
      // results,
      resultsTree,
      functionalUnitMagnitude,
    } = component;
    const weightValueInKilograms = weightMetric === 'KILOGRAMS' ? weightValue : weightValue / 1000;

    const resultsTreeObject = JSON.parse(resultsTree);

    const groupedResultsByType = Object.keys(ResultTypes)
      .map(resultType => {
        const totalValueSum = Object.values(resultsTreeObject[resultType]).reduce(
          (a, b) => a + b,
          0
        );
        return { [resultType]: totalValueSum };
      })
      .reduce((a, b) => ({ ...a, ...b }), {});

    return {
      id,
      name,
      functionalUnitMagnitude:round(functionalUnitMagnitude,2),
      weightValueInKilograms:round(weightValueInKilograms*1e3,2),
      consumoAgua: round(groupedResultsByType['CONSUMO_DE_AGUA']*1e3, 2),
      consumoEnergia: round(groupedResultsByType['CONSUMO_DE_ENERGIA'], 2),
      consumoCombustibleFosil: round(groupedResultsByType['CONSUMO_DE_COMBUSTIBLES_FOSILES'], 2),
      emisionesGEI: round(groupedResultsByType['EMISIONES_GEI']*1e3, 2),
      generacionResiduos: round(groupedResultsByType['GENERACION_DE_RESIDUOS']*1e3, 2),
    };
  });

  return (
    <Table
      css={css`
        border: 1px solid;
        border-color: #bdbdbd;
      `}
    >
      <ComponentsSummaryTableHeader
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowCount={rows.length}
        functionalUnit={functionalUnit}
        rows={rows}
      />
      <TableBody>
        {stableSort(mappedComponents, getSorting(order, orderBy)).map(component => {
          const {
            id,
            name,
            functionalUnitMagnitude,
            weightValueInKilograms,
            consumoAgua,
            consumoEnergia,
            consumoCombustibleFosil,
            emisionesGEI,
            generacionResiduos,
          } = component;
          return (
            <TableRow key={id}>
              <TableCell align={'left'} css={colorTable} style={{width:'12.5%'}}>
                <Typography variant="subtitle2" style={{ color: '#1AA58A'}}>
                  {name}
                </Typography>
              </TableCell>
              <TableCell align={'center'} css={colorTable} style={{width:'12.5%'}}>
                <Typography variant="subtitle1">{functionalUnitMagnitude}</Typography>
              </TableCell>
              <TableCell align={'center'} css={colorTable} style={{width:'12.5%'}}>
                <Typography variant="subtitle1">{weightValueInKilograms}</Typography>
              </TableCell>
              <TableCell align={'center'} css={colorTable} style={{width:'12.5%'}}>
                <Typography variant="subtitle1">{consumoAgua}</Typography>
              </TableCell>
              <TableCell align={'center'} css={colorTable} style={{width:'12.5%'}}>
                <Typography variant="subtitle1">{consumoEnergia}</Typography>
              </TableCell>
              <TableCell align={'center'} css={colorTable} style={{width:'12.5%'}}>
                <Typography variant="subtitle1">{consumoCombustibleFosil}</Typography>
              </TableCell>
              <TableCell align={'center'} css={colorTable} style={{width:'12.5%'}}>
                <Typography variant="subtitle1">{emisionesGEI}</Typography>
              </TableCell>
              <TableCell align={'center'} css={colorTable} style={{width:'12.5%'}}>
                <Typography variant="subtitle1">{generacionResiduos}</Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ComponentsSummaryTable;

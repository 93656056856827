import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { css } from 'styled-components/macro';
import styled from 'styled-components/macro';

import { GET_PROJECT } from 'gql/queries';
import { useModal } from 'hooks';
import { CreateComponentDialog } from 'components/CreateComponentDialog';
import { ComponentTree } from 'components/ComponentTree';

import { Grid, Cell } from 'styled-css-grid';
import ProjectHeader from './ProjectHeader';
import ComponentEdit from 'components/ComponentEdit';

import { Button } from 'components/styled/Button';
import { ProjectRouteProvider } from './ProjectRouteContext';

import { Table, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import {
  NoteAdd as NoteAddIcon
} from '@material-ui/icons';

export const ProjectRoute = ({ match }) => {
  const { id: projectId } = match.params;
  const { data, error, loading } = useQuery(GET_PROJECT, { variables: { id: projectId } });
  const { project = {} } = data;
  const {
    id,
    name,
    description,
    functionalUnit,
    functionalUnitMagnitude,
    isPublic,
    components,
  } = project;

  const [componentData, setComponentData] = useState([]);
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    if (components) {
      const sorted = (components).sort((a, b) => {
        return (
          a["createdAt"].toString().localeCompare(b["createdAt"].toString(), "en", {
            numeric: true,
          })
        );
      });
      setComponentData(sorted);
    }
  }, [components]);

  const createComponentDialog = useMemo(
    () => props => (
      <CreateComponentDialog
        projectId={projectId}
        projectParentId={projectId}
        isRootComponent={true}
        {...props}
      />
    ),
    [projectId]
  );

  const Title = styled.div`
    color: #56af89;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    margin-left: 20px;
  `;

  const [openCreateComponentDialog] = useModal(createComponentDialog);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const handleSortingChange = (field) => {
    const order = field === sortField && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);

    const sorted = [...componentData].sort((a, b) => {
      return (
        a[field].toString().localeCompare(b[field].toString(), "en", {
          numeric: true,
        }) * (order === "asc" ? 1 : -1)
      );
    });

    setComponentData(sorted);
  };

  const handleToggleChange = (open) => {
/*
                  <IconButton onClick={() => handleToggleChange(true)} title="Expande todos los componentes">
                    <ExpandAllIcon />
                  </IconButton>
                  <IconButton onClick={() => handleToggleChange(false)} title="Colapsa todos los componentes">
                    <CollapseAllIcon />
                  </IconButton>
*/    
    console.log(open);
  };

  return (
    <ProjectRouteProvider>
      <Grid
        columns={'1fr 1fr'}
        rows={'auto 1fr'}
        areas={['header header', 'componentTree componentEdit']}
        css={css`
          padding: 15px;
          height: 100%;
        `}
      >
        <Cell area="header">
          <ProjectHeader
            id={id}
            name={name}
            description={description}
            functionalUnitMagnitude={functionalUnitMagnitude}
            functionalUnit={functionalUnit}
            isPublic={isPublic}
          />
        </Cell>
        <Cell center area="componentTree">
          <Grid
            columns="1fr"
            rows="auto 1fr"
            css={css`
              height: 100%;
            `}
          >
            <Cell>
              <Grid columns="15% 40% auto" justifyContent="space-between">
                <Cell center middle>
                  <Title>PRODUCTOS</Title>
                </Cell>
                <Cell>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell title='Ordenar los componentes por nombre'>
                            <TableSortLabel
                              active={sortField === "name"}
                              direction={sortOrder}
                              onClick={() => handleSortingChange("name")}>
                              Nombre
                            </TableSortLabel>
                        </TableCell>
                        <TableCell title='Ordenar los componentes por fecha de creación'>
                            <TableSortLabel
                              active={sortField === "createdAt"}
                              direction={sortOrder}
                              onClick={() => handleSortingChange("createdAt")}>
                              Creación
                            </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </Cell>
                <Cell>
                  <Button disabled={isPublic} onClick={openCreateComponentDialog} color="secondary">
                    <NoteAddIcon
                      css={css`
                        margin-right: 5px;
                      `}
                    />
                    Crear Producto
                  </Button>
                </Cell>
              </Grid>
            </Cell>

            <Cell
              css={css`
                background-color: rgba(0, 0, 0, 0.05);
              `}
            >
              <ComponentTree
                isPublic={isPublic}
                projectParentId={projectId}
                sortField={sortField}
                sortOrder={sortOrder}
                components={componentData}
              />
            </Cell>
          </Grid>
        </Cell>
        <Cell area="componentEdit">
          <ComponentEdit project={project} />
        </Cell>
      </Grid>
    </ProjectRouteProvider>
  );
};

export default ProjectRoute;

import React, { useCallback, useState } from 'react';
import { css } from 'styled-components/macro';
import { Formik, FieldArray } from 'formik';
import { useMutation, useQuery } from 'react-apollo-hooks';
import * as Yup from 'yup';
import { MenuItem, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Cell } from 'styled-css-grid';
import { isEmpty, groupBy } from 'lodash';

import Button from 'components/styled/Button';
import { Form } from 'components/Form';
import { InputField } from 'components/InputField';
import { PercentageInputField } from 'components/PercentageInputField';
import { SelectField } from 'components/SelectField';
import { ConfirmDeleteDialog } from 'components/ConfirmDeleteDialog';
import { COMPONENT_UPDATE } from 'gql/mutations';
import { GET_PROCESSES } from 'gql/queries';

import { processCycles, cleanEnergyPercentage, processes } from 'consts';

import Tooltip from 'components/Tooltip';

// TODO style, add delete

const childrenMessage = 'No se puede editar este campo si existen subcomponentes';

const emptyAppliedProcess = {
  material: '',
  processId: '',
  cycles: 1,
  percentajeCleanEnergy: 0,
};

function ComponentProcess({ component = {}, project = {} }) {
  const updateComponentMutation = useMutation(COMPONENT_UPDATE);
  const { data, error, loading } = useQuery(GET_PROCESSES);
  const [isArrayTouched, setIsArrayTouched] = useState(false);

  const { processes = [], errors } = data;

  const { id, appliedProcesses } = component;

  const { isPublic } = project;

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [confirmArgs, setConfirmArgs] = useState({
    title: '',
    message: '',
    callback: () => {},
  });

  const handleCloseConfirm = () => setIsConfirmOpen(false);

  const openConfirmDeleteDialog = ({ title, message, callback }) => {
    setConfirmArgs({
      title,
      message,
      callback,
    });
    setIsConfirmOpen(true);
  };

  const updateComponentSubmit = useCallback(
    async (values, actions) => {
      const input = {
        id,
        appliedProcesses: values.appliedProcesses
          .map(({ processId, cycles, percentajeCleanEnergy }) => ({
            processId,
            cycles,
            percentajeCleanEnergy,
          }))
          .filter(({ processId }) => !isEmpty(processId)),
      };
      updateComponentMutation({ variables: { input } });
    },
    [id]
  );

  if (isEmpty(component)) return null;

  // group transports by transport type
  const groupedProcesses = groupBy(processes, 'material');
  const processesMaterials = Object.keys(groupedProcesses);

  const initialValues = {
    id,
    appliedProcesses: appliedProcesses
      .map(({ process: { id, material }, cycles, percentajeCleanEnergy }) => ({
        processId: id,
        cycles,
        material,
        percentajeCleanEnergy,
      }))
      .filter(({ processId }) => !isEmpty(processId)),
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={updateComponentSubmit}>
      {({
        errors,
        dirty,
        handleSubmit,
        setFieldValue,
        values,
        resetForm,
        initialValues: formikInitialValues,
      }) => {
        return (
          <>
            <ConfirmDeleteDialog
              isOpen={isConfirmOpen}
              closeModal={handleCloseConfirm}
              {...confirmArgs}
            />
            <Form>
              <FieldArray name="appliedProcesses">
                {({ push, remove }) => {
                  return (
                    <Grid
                      columns={'1fr'}
                      rows={'auto'}
                      rowGap="1rem"
                      css={css`
                        padding-top: 1rem;
                      `}
                    >
                      <>
                        {values.appliedProcesses.map((process, index) => (
                          <Grid key={index} columns="1fr 1fr 20% 20% auto">
                            <SelectField
                              disabled={isPublic}
                              name={`appliedProcesses.${index}.material`}
                              label="Material"
                            >
                              {processesMaterials.sort().map(material => (
                                <MenuItem value={material}>{material}</MenuItem>
                              ))}
                            </SelectField>

                            <SelectField
                              name={`appliedProcesses.${index}.processId`}
                              label="Proceso"
                              disabled={
                                isPublic || isEmpty(values.appliedProcesses[index].material)
                              }
                            >
                              {(groupedProcesses[values.appliedProcesses[index].material] || [])
                                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                                .map(process => (
                                  <MenuItem key={process.id} value={process.id}>
                                    {process.name}
                                  </MenuItem>
                                ))}
                            </SelectField>

                            <Tooltip text={processCycles}>
                              <InputField
                                disabled={isPublic}
                                name={`appliedProcesses.${index}.cycles`}
                                label="Ciclos"
                                type="number"
                                inputProps={{ min: 1 }}
                              />
                            </Tooltip>
                            <Tooltip text={cleanEnergyPercentage}>
                              <PercentageInputField
                                disabled={isPublic}
                                name={`appliedProcesses.${index}.percentajeCleanEnergy`}
                                label="Porcentaje Energía Limpia"
                                type="number"
                                inputProps={{ min: 0, max: 100 }} // NO FUNCIONO. LA IDEA ES LIMITAR LA ENTRADA DE NUMEROS ENTRE 0 Y 100
                              />
                            </Tooltip>

                            <IconButton
                              disabled={isPublic}
                              onClick={() => {
                                openConfirmDeleteDialog({
                                  title: 'Confirmar eliminación',
                                  message:
                                    '¿Estás seguro, que deseas eliminar el proceso seleccionado?',
                                  callback: () => {
                                    remove(index);
                                    setIsArrayTouched(true);
                                  },
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        ))}
                      </>

                      <Cell center middle>
                        <Grid columns="30% 30%" justifyContent="space-around">
                          <Cell>
                            <Button
                              disabled={isPublic}
                              onClick={() => push({ ...emptyAppliedProcess })}
                              color="primary"
                            >
                              Agregar Proceso
                            </Button>
                          </Cell>
                          <Cell>
                            <Button
                              disabled={!isArrayTouched && (isPublic || !isEmpty(errors) || !dirty)}
                              onClick={handleSubmit}
                              color="primary"
                            >
                              Guardar cambios
                            </Button>
                          </Cell>
                        </Grid>
                      </Cell>
                    </Grid>
                  );
                }}
              </FieldArray>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default ComponentProcess;

import { useContext, useEffect, useMemo, useCallback } from 'react';

import ModalContext from './ModalContext'
import { ModalComponentType } from './types';

/**
 * Utility function to generate unique number per component instance
 */
const generateModalKey = (() => {
  let count = 0;
  return () => `${++count}`;
})();

const useModal = (
  modal: ModalComponentType,
  inputs: any[] = []
) => {
  const component = useMemo(() => modal, inputs);
  const key = useMemo(generateModalKey, []);
  const {
    addModal,
    closeModal,
    openModal,
    deleteModal,
  } = useContext(ModalContext);

  const open = useCallback(() => openModal(key), [key]);
  const close = useCallback(() => closeModal(key), [key]);

  useEffect(() => {
    addModal(key, component, close);
    return () => deleteModal(key);
  }, [component]);




  return [open, close];
}

export default useModal;

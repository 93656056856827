export const functionalUnitComparative =
  'Unidad de medida comparativa entre productos dentro del proyecto. Hace referencia a la capacidad del envase, como kg o litros.';

export const functionalUnitMagnitude =
  'Nivel de referencia a utilizar para obtener los resultados del proyecto.';

export const functionalUnitCapacity =
  'Unidad de medida de la capacidad de los productos a evaluar (e.g. kg, litros, otros)';

export const renewableVirginMaterialPercentage = 'e.g. papel, cartulina, biopolímero, otros';

export const nonRenewableVirginMaterialPercentage =
  'e.g. plásticos derivados del petróleo, metales, vidrio, otros';

export const processCycles = 'Número de veces que se repite el proceso en serie.';

export const cleanEnergyPercentage =
  'Porcentaje de electricidad del proceso que proviene de fuentes renovables (e.g. solar, eólica). Adicional a la energía renovable ya existente en la matriz energética.';

export const processes = 'Se deben ingresar los procesos en el orden en que ocurren.';

export const reusePercentage =
  'Porcentaje del componente que va a reuso o reutilización (e.g. envases retornables)';

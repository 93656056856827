import React from 'react';

import StackBarChart from 'components/StackBarChart/StackBarChart';
import { ResultStages, ResultTypes } from 'consts/results';
import { mergeObjects } from 'utils';

const CATEGORIES = Object.values(ResultTypes);
const STAGES = Object.values(ResultStages);

export const PdfImpactComparisonGraphs = ({
  components = [],
  category = CATEGORIES[0],
  variant = 'ABSOLUTE',
}) => {
  // category tabs
  // {[component.name]: {[type]: {[stage]: value}}}
  let productsCategories = components
    .map(({ name, resultsTree }) => {
      const resultsTreeObject = JSON.parse(resultsTree);

      const groupedResultsByType = Object.keys(ResultTypes)
        .map(type => ({
          [ResultTypes[type]]: Object.keys(ResultStages)
            .map(stage => {
              const typeFactor = [
                'CONSUMO_DE_AGUA',
                'EMISIONES_GEI',
                'GENERACION_DE_RESIDUOS',
              ].includes(type)
                ? 1e3
                : 1;
              return {
                [ResultStages[stage]]: resultsTreeObject[type][stage] * typeFactor,
              };
            })
            .reduce(mergeObjects, {}),
        }))
        .reduce(mergeObjects, {});

      return { [name]: groupedResultsByType };
    })
    .reduce(mergeObjects, {});

  const productStagesImpact = Object.entries(productsCategories).map(([name, results]) => ({
    name,
    ...results[ResultTypes[category]],
  }));

  // relative stages impact data
  let productsRelativeStagesImpact = productStagesImpact.map(x => {
    const { name, ...stages } = x;
    let totalImpact = Object.values(stages).reduce((a, b) => a + b, 0);
    totalImpact = totalImpact > 0 ? totalImpact : 1;
    return {
      name,
      ...Object.entries(stages)
        .map(([k, v]) => ({ [k]: (100.0 * v) / totalImpact }))
        .reduce(mergeObjects, {}),
    };
  });

  switch (variant) {
    case 'ABSOLUTE': {
      return (
        <StackBarChart
          data={productStagesImpact}
          valuesKeys={STAGES}
          echartsOptions={{ animation: false }}
        />
      );
    }
    case 'RELATIVE': {
      return (
        <StackBarChart
          data={productsRelativeStagesImpact}
          valuesKeys={STAGES}
          echartsOptions={{ xAxis: { min: 0, max: 100 }, animation: false }}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default PdfImpactComparisonGraphs;

import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles, TableSortLabel } from '@material-ui/core';

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  divider: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
});

const ProjectName = styled.div`
  color: #56af89;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  margin: auto auto auto 20px;
`;

const ProjectLink = styled(Link)`
  text-decoration: none;
  color: #56af89;
  &:visited {
    color: #56af89;
  }
`;

export const ProcessesTable = withStyles(styles)(({ processes = [], classes }) => {
  const sortedDefault = [...processes].sort((a, b) => {
    return (
      a["createdAt"].toString().localeCompare(b["createdAt"].toString(), "en", {
        numeric: true,
      }) * ("desc" === "asc" ? 1 : -1)
    );
  });

  const [rowData, setRowData] = useState(sortedDefault);
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortingChange = (field) => {
    const order = field === sortField && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);

    const sorted = [...rowData].sort((a, b) => {
      return (
        a[field].toString().localeCompare(b[field].toString(), "en", {
          numeric: true,
        }) * (order === "asc" ? 1 : -1)
      );
    });
    setRowData(sorted);
  };

  return (
    <Table
      css={css`
        border: 1px solid #bdbdbd;
      `}
    >
      <TableHead>
        <TableRow>
          <TableCell className={classes.divider} key={'name'} onClick={() => handleSortingChange('name')}>
            <TableSortLabel active={sortField === "name"} direction={sortOrder}>Nombre</TableSortLabel>
          </TableCell>
          <TableCell className={classes.divider} key={'description'} onClick={() => handleSortingChange('description')}>
            <TableSortLabel active={sortField === "description"} direction={sortOrder}>Descripción</TableSortLabel>
          </TableCell>
          <TableCell className={classes.divider} key={'material'} onClick={() => handleSortingChange('material')}>
            <TableSortLabel active={sortField === "material"} direction={sortOrder}>Material</TableSortLabel>
          </TableCell>
          <TableCell className={classes.divider} key={'lcStage'} onClick={() => handleSortingChange('lcStage')}>
            <TableSortLabel active={sortField === "lcStage"} direction={sortOrder}>Etapa del ciclo de vida</TableSortLabel>
          </TableCell>
          <TableCell key={'createdAt'} onClick={() => handleSortingChange('createdAt')}>
            <TableSortLabel active={sortField === "createdAt"} direction={sortOrder}>Fecha de creacion</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rowData.map(process => {
          const { id, name, material, description, lcStage, createdAt } = process;

          const createdAtText = new Date(createdAt).toLocaleString('en-GB', {
            timeZone: 'America/Santiago',
          });

          return (
            <TableRow className={classes.row} key={id}>
              <TableCell className={classes.divider}>
                <ProjectName>
                  <ProjectLink to={`/process/${id}/edit`}>{name}</ProjectLink>
                </ProjectName>
              </TableCell>
              <TableCell className={classes.divider}>{description}</TableCell>
              <TableCell className={classes.divider}>{material}</TableCell>
              <TableCell className={classes.divider}>{lcStage}</TableCell>
              <TableCell>{createdAtText}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
});

export default ProcessesTable;

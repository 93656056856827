import React from 'react';
import styled from 'styled-components/macro';

import FullBox from 'components/styled/FullBox';

export const Form = ({ children, ...props }) => {
  return (
    <FullBox as="form" {...props}>
      {children}
    </FullBox>
  );
};

export default Form;

import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import Paper from '@material-ui/core/Paper';
import { IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { EditProjectDialog } from 'components/EditProjectDialog';
import { FunctionalUnitMap } from 'consts';
import { useModal } from 'hooks';

const Container = styled.div`
  display: flex;
  background-color: #56af89;
  min-height: 60px;
  width: 100%;
`;

const Item = styled.div`
  margin: auto 15px;
  color: white;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: ${props => props.color || 'white'};
  font-weight: ${props => props.fontWeight || 'regular'};
  font-size: ${props => props.fontSize || '1rem'};
`;

const VerticalDivider = styled.div`
  border-left: 1px solid #15774c;
  height: 50%;
  margin: auto 0;
`;

export function ProjectBar(props) {
  const { id, name, description, functionalUnitMagnitude, functionalUnit, isPublic } = props;

  const editProjectDialog = useMemo(
    () => props => (
      <EditProjectDialog
        project={{ id, name, description, functionalUnitMagnitude, functionalUnit }}
        {...props}
      />
    ),
    [id]
  );

  const [openEditProjectDialog] = useModal(editProjectDialog);

  return (
    <Paper>
      <Container>
        <Item fontWeight="bold" fontSize="18px">
          PROYECTO
        </Item>
        <VerticalDivider />
        <Item fontWeight="bold" fontSize="21px">
          {name}
        </Item>
        <VerticalDivider />
        <Item fontSize="16px">{description}</Item>
        {/* right side  */}
        <VerticalDivider style={{ marginLeft: 'auto' }} />
        {isPublic && (
          <Item fontSize="16px" fontWeight={900} color="#15774C">
            PÚBLICO
          </Item>
        )}
        <VerticalDivider />
        <Item fontSize="14px" fontWeight="bold" color="#15774C">
          {`${functionalUnitMagnitude} ${FunctionalUnitMap[functionalUnit].toUpperCase()}`}
        </Item>
        <VerticalDivider />
        <Item>
          <IconButton
            disabled={isPublic}
            style={{ fontSize: '18px', fontWeight: 600 }}
            onClick={openEditProjectDialog}
          >
            <EditIcon style={{ marginRight: '3px' }} />
            Editar
          </IconButton>
        </Item>
      </Container>
    </Paper>
  );
}

export default React.memo(ProjectBar);

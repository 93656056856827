import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import Paper from '@material-ui/core/Paper';
import { IconButton } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  background-color: #56af89;
  min-height: 60px;
  width: 100%;
`;

const Item = styled.div`
  margin: auto 15px;
  color: white;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: ${props => props.color || 'white'};
  font-weight: ${props => props.fontWeight || 'regular'};
  font-size: ${props => props.fontSize || '1rem'};
`;

const VerticalDivider = styled.div`
  border-left: 1px solid #15774c;
  height: 50%;
  margin: auto 0;
`;

export function ProjectBar(props) {
  const { id, name } = props;

  return (
    <Paper>
      <Container>
        <Item fontWeight="bold" fontSize="18px">
          Editar Proceso
        </Item>
        <VerticalDivider />
        <Item fontWeight="bold" fontSize="21px">
          {name}
        </Item>
      </Container>
    </Paper>
  );
}

export default React.memo(ProjectBar);

import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';

import { cloneDeep } from 'lodash';

const propTypes = {
  /** Data of the stack graph, array of objects. Objects must contain valuesKeys and stackKey */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Keys of values to stack in data */
  valuesKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Key of the name of the stack in data */
  stackKey: PropTypes.string,
  /** Additional echarts options */
  echartsOptions: PropTypes.object,
};

const defaultProps = {
  stackKey: 'name',
  echartsOptions: {},
}

/**
 * Stack bar chart using Echarts.
 * example: https://ecomfe.github.io/echarts-examples/public/editor.html?c=bar-y-category-stack
 */
function StackBarChart({ data, valuesKeys, stackKey, echartsOptions }) {

// Paleta colores elegida
  const colorPalette = ['#1381B9','#1FC29F','#782689','#71E8D2'];

  const series = valuesKeys.map(value => ({
    name: value,
    type: 'bar',
    stack: 'total',
    data: data.map(product => product[value]).reverse()
  }))

  const stackOptions = {
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: data.map(x => x[stackKey]).reverse(),
      axisLabel:{
        formatter: function(value){
          return value.match(/.{1,25}/g).join('\n');
        }
      }
    },
    legend: {
      data: valuesKeys
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
	  color:colorPalette,
    series
  }

  const options = cloneDeep(stackOptions, echartsOptions);

  return (
    <ReactEcharts
      option={options}
    />
  );
}

StackBarChart.propTypes = propTypes;
StackBarChart.defaultProps = defaultProps;

export default StackBarChart;
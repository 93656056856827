import React, { useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-apollo-hooks';

import { REQUEST_RESET_PASSWORD_MAIL } from 'gql/mutations';
import { InputField } from 'components/InputField';
import { rutValidator, mergeObjects } from 'utils';
import { useSnackbar, useAuthToken } from 'hooks';

const initialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('email invalido')
    .required(),
});

export const PasswordRecoveryDialog = ({ isOpen, closeModal }) => {
  const requestResetPasswordMutation = useMutation(REQUEST_RESET_PASSWORD_MAIL);
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const signupSubmit = useCallback(
    async (values, actions) => {
      try {
        const { email } = values;
        const { data } = await requestResetPasswordMutation({ variables: { input: { email } } });
        const { errors } = data.requestResetPasswordMail;
        if (!isEmpty(errors)) throw new Error(errors[0].message);
        enqueueSuccessSnackbar(`Mail enviado a ${email} para restaurar contrasena`)
      } catch (e) {
        enqueueErrorSnackbar(e.message);
      }
    },
    [closeModal]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={signupSubmit}
      render={({ handleSubmit, errors, dirty }) => (
        <Dialog open={isOpen}>
          <DialogTitle>Restaurar contrasena</DialogTitle>
          <DialogContent>
            <InputField name="email" label="email" autoFocus style={{ marginTop: '1rem' }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cerrar</Button>
            <Button onClick={handleSubmit} disabled={!(dirty && isEmpty(errors))}>
              Restaurar contrasena
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default PasswordRecoveryDialog;

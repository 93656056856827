import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles, TableSortLabel } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { FunctionalUnitMap, functionalUnitComparative } from 'consts';

import { DELETE_PROJECT } from 'gql/mutations';
import { GET_ME } from 'gql/queries';
import { useMutation } from 'react-apollo-hooks';

import { useSnackbar } from 'hooks';
import { mergeObjects } from 'utils';
import { isEmpty } from 'lodash';

import Tooltip from 'components/Tooltip';
import { ConfirmDeleteDialog } from 'components/ConfirmDeleteDialog';

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  divider: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
});

const ProjectName = styled.div`
  color: #56af89;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  margin: auto auto auto 20px;
`;

const ProjectLink = styled(Link)`
  text-decoration: none;
  color: #56af89;
  &:visited {
    color: #56af89;
  }
`;

export const ProjectsTable = withStyles(styles)(({ projects = [], classes }) => {
  const { enqueueErrorSnackbar } = useSnackbar();

  const deleteProjectMutation = useMutation(DELETE_PROJECT, {
    update: (cache, { data: { projectDelete = {} } }) => {
      const { errors } = projectDelete;
      if (!isEmpty(errors)) return;
      const { project } = projectDelete;
      const { me } = cache.readQuery({ query: GET_ME });
      const updatedProjects = [...me.projects.filter(({ id }) => id !== project.id)];
      cache.writeQuery({
        query: GET_ME,
        data: { me: { ...me, projects: updatedProjects } },
      });
    },
  });

  const deleteProject = async projectId => {
    try {
      const { data } = await deleteProjectMutation({
        variables: { input: { id: projectId } },
      });
      const { errors } = data.projectDelete;

      if (!isEmpty(errors)) throw new Error(errors[0].message);
    } catch (e) {
      enqueueErrorSnackbar(e.message);
    }
  };

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [confirmArgs, setConfirmArgs] = useState({
    title: '',
    message: '',
    callback: () => {},
  });

  const handleCloseConfirm = () => setIsConfirmOpen(false);

  const openConfirmDeleteDialog = (id, name) => {
    setConfirmArgs({
      title: 'Confirmar eliminación',
      message: `¿Estás seguro, que deseas eliminar el proyecto ${name}?`,
      callback: () => {
        deleteProject(id);
      },
    });
    setIsConfirmOpen(true);
  };

  const sortedDefault = [...projects].sort((a, b) => {
    return (
      a["createdAt"].toString().localeCompare(b["createdAt"].toString(), "en", {
        numeric: true,
      }) * ("desc" === "asc" ? 1 : -1)
    );
  });

  const [rowData, setRowData] = useState(sortedDefault);
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortingChange = (field) => {
    const order = field === sortField && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);

    const sorted = [...rowData].sort((a, b) => {
      return (
        a[field].toString().localeCompare(b[field].toString(), "en", {
          numeric: true,
        }) * (order === "asc" ? 1 : -1)
      );
    });
    setRowData(sorted);
  };

  return (
    <>
      <ConfirmDeleteDialog
        isOpen={isConfirmOpen}
        closeModal={handleCloseConfirm}
        {...confirmArgs}
      />
      <Table
        css={css`
          border: 1px solid #bdbdbd;
        `}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '50%' }} className={classes.divider} key={'name'} onClick={() => handleSortingChange('name')}>
            <TableSortLabel active={sortField === "name"} direction={sortOrder}>Nombre</TableSortLabel>
            </TableCell>
            <Tooltip text={functionalUnitComparative}>
              <TableCell className={classes.divider} key={'functionalUnit'} onClick={() => handleSortingChange('functionalUnit')}>
              <TableSortLabel active={sortField === "functionalUnit"} direction={sortOrder}>Unidad funcional y magnitud</TableSortLabel>
              </TableCell>
            </Tooltip>
            <TableCell key={'createdAt'} onClick={() => handleSortingChange('createdAt')}>
            <TableSortLabel active={sortField === "createdAt"} direction={sortOrder}>Fecha de creacion</TableSortLabel>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map(project => {
            const { id, name, functionalUnit, functionalUnitMagnitude, createdAt } = project;

            const functionalUnitText = `${functionalUnitMagnitude} ${
              FunctionalUnitMap[functionalUnit]
            }`;
            const createdAtText = new Date(createdAt).toLocaleString('en-GB', {
              timeZone: 'America/Santiago',
            });

            return (
              <TableRow className={classes.row} key={id}>
                <TableCell className={classes.divider}>
                  <ProjectName>
                    <ProjectLink to={`/projects/${id}/design`}>{name}</ProjectLink>
                  </ProjectName>
                </TableCell>
                <TableCell className={classes.divider}>{functionalUnitText}</TableCell>
                <TableCell>{createdAtText}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      openConfirmDeleteDialog(id, name);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
});

export default ProjectsTable;

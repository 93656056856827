import gql from 'graphql-tag';

export const ComponentFragment = gql`
  fragment ComponentFragment on Component {
    id
    createdAt
    updatedAt
    isPublic
    name
    description
    functionalUnitMagnitude
    weight {
      value
      metric
    }
    origin {
      recycledMaterial
      renewableMaterial
      nonRenewableMaterial
    }
    finalDisposition {
      materialWaste
      incineration
      finalReusing
      finalRecycle
      dumpster
      sanitaryFilling
    }
    children {
      id
      name
      createdAt
    }
    appliedProcesses {
      process {
        id
        name
        material
      }
      cycles
      percentajeCleanEnergy
    }
    appliedTransports {
      transport {
        id
        vehicle
        type
      }
      distanceInKilometers
    }
  }
`;

export const ProjectFragment = gql`
  fragment ProjectFragment on Project {
    id
    createdAt
    updatedAt
    name
    description
    functionalUnit
    functionalUnitMagnitude
    isPublic
    components {
      id
      name
      createdAt
    }
    owner {
      id
      email
      info {
        name
        lastName
      }
    }
  }
`;

export const ProcessFragment = gql`
  fragment ProcessFragment on Process {
    id
    createdAt
    updatedAt
    owner {
      id
      email
      info {
        name
        lastName
      }
    }
    name
    description
    material
    lcStage
    country {
      id
      name
    }
    isPublic
    massOut
    massLoss
    dumpster
    sanitaryFill
    finalReusing
    finalRecycle
    incineration
    electricityCons
    naturalGasCons
    glpCons
    dieselCons
    oilCons
    biomassCons
    coalCons
    waterCons
  }
`;

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    createdAt
    updatedAt
    email
    role
    info {
      name
      lastName
      rut
      nationality
      mobilePhone
      phone
      region
      commune
      company
      productiveSector
    }
    projects {
      id
    }
    components {
      id
    }
  }
`;

import React, { useCallback } from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import styled, { css } from 'styled-components/macro';
import { Grid, Cell } from 'styled-css-grid';
import { InputAdornment } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import { InputField } from 'components/InputField';
import { PercentageInputField } from 'components/PercentageInputField';
import { SelectField } from 'components/SelectField';
import { mergeObjects } from 'utils';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'hooks';
import { CREATE_PROCESS } from 'gql/mutations';
import { GET_MY_PROCESSES, GET_COUNTRIES } from 'gql/queries';

import { LcStage, LcStageMap } from 'consts';

export const CreateProcessDialog = ({ isOpen, closeModal }) => {
  const { data, error, loading } = useQuery(GET_COUNTRIES);
  const { countries = [] } = data;

  const createProcessMutation = useMutation(CREATE_PROCESS, {
    update: (cache, { data: { processCreate = {} } }) => {
      const { errors } = processCreate;
      if (!isEmpty(errors)) return;
      const { process } = processCreate;
      const { me } = cache.readQuery({ query: GET_MY_PROCESSES });
      const updatedProcesses = [...me.processes, process];
      cache.writeQuery({
        query: GET_MY_PROCESSES,
        data: { me: { ...me, processes: updatedProcesses } },
      });
    },
  });

  const { enqueueErrorSnackbar } = useSnackbar();
  const createProcessSubmit = useCallback(
    async (values, actions) => {
      try {
        const { data } = await createProcessMutation({ variables: { input: values } });
        const { process, errors } = data.processCreate;
        if (!isEmpty(errors)) throw new Error(errors[0].message);
        closeModal();
      } catch (e) {
        enqueueErrorSnackbar(e.message);
      }
    },
    [closeModal]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !isEmpty(error)) {
    return <div>Error! {error.message}</div>;
  }

  const fields = [
    {
      name: 'name',
      label: 'Nombre del proceso*',
      initialValue: '',
      InputComponent: InputField,
      validation: Yup.string()
        .max(60, 'Nombre muy largo')
        .required('Campo no puede ser vacio'),
    },
    {
      name: 'material',
      label: 'Material*',
      initialValue: '',
      InputComponent: InputField,
      validation: Yup.string()
        .max(40, 'Nombre muy largo')
        .required('Campo no puede ser vacio'),
    },
    {
      name: 'description',
      label: 'Descripción',
      initialValue: '',
      InputComponent: InputField,
      validation: Yup.string(),
    },
    {
      name: 'lcStage',
      label: 'Etapa del ciclo de vida*',
      initialValue: Object.keys(LcStage)[0],
      InputComponent: SelectField,
      componentProps: {
        children: Object.entries(LcStage).map(([k, v]) => (
          <MenuItem key={k} value={k}>
            {LcStageMap[v]}
          </MenuItem>
        )),
      },
    },
    {
      name: 'massOut',
      label: 'Salida de masa procesada (kg)*',
      initialValue: '',
      InputComponent: InputField,
      validation: Yup.number()
        .positive('Magnitud debe ser mayor a 0')
        .required('Campo no puede ser vacio'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'massLoss',
      label: 'Porcentaje de mermas del proceso',
      initialValue: 0,
      InputComponent: PercentageInputField,
      validation: Yup.number()
        .min(0, 'Magnitud debe ser mayor o igual a 0')
        .max(100, 'Magnitud debe ser menor o igual a 100'),
      componentProps: {
        type: 'number',
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'finalRecycle',
      label: 'Porcentaje de merma con destino a reciclaje',
      initialValue: 0,
      InputComponent: PercentageInputField,
      validation: Yup.number()
        .min(0, 'Magnitud debe ser mayor o igual a 0')
        .max(100, 'Magnitud debe ser menor o igual a 100'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'incineration',
      label: 'Porcentaje de merma con destino a incineración',
      initialValue: 0,
      InputComponent: PercentageInputField,
      validation: Yup.number()
        .min(0, 'Magnitud debe ser mayor o igual a 0')
        .max(100, 'Magnitud debe ser menor o igual a 100'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'sanitaryFill',
      label: 'Porcentaje de merma con destino a relleno sanitario',
      initialValue: 100,
      InputComponent: PercentageInputField,
      validation: Yup.number()
        .min(0, 'Magnitud debe ser mayor o igual a 0')
        .max(100, 'Magnitud debe ser menor o igual a 100'),
      // TO DO disabled: true,
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'electricityCons',
      label: 'Consumo de electricidad (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'countryName',
      label: 'País de red eléctrica',
      initialValue: countries[0].name,
      InputComponent: SelectField,
      componentProps: {
        children: countries.sort((a, b) => (a.name > b.name) ? 1 : -1).map(({ name }) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        )),
      },
    },
    {
      name: 'naturalGasCons',
      label: 'Consumo de gas natural (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'glpCons',
      label: 'Consumo de GLP (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'dieselCons',
      label: 'Consumo de diesel (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'oilCons',
      label: 'Consumo de petróleo (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'biomassCons',
      label: 'Consumo de biomasa (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'coalCons',
      label: 'Consumo de carbón (kWh)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
    {
      name: 'waterCons',
      label: 'Consumo de agua (m³)',
      initialValue: 0,
      InputComponent: InputField,
      validation: Yup.number().min(0, 'Magnitud debe ser mayor o igual a 0'),
      componentProps: {
        type: 'number',
        inputProps: { min: '0', step: 'any' },
      },
    },
  ];

  const initialValues = fields.map(x => ({ [x.name]: x.initialValue })).reduce(mergeObjects);

  const validationSchema = Yup.object().shape(
    fields.map(x => ({ [x.name]: x.validation })).reduce(mergeObjects)
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createProcessSubmit}
      render={({ handleSubmit, errors, dirty }) => (
        <Dialog open={isOpen}>
          <DialogTitle>Nuevo Proceso</DialogTitle>
          <DialogContent>
            <Grid
              columns={'minmax(500px, auto)'}
              rows={'auto'}
              rowGap="1rem"
              css={css`
                padding-top: 1rem;
              `}
            >
              {fields.map(field => {
                const {
                  InputComponent,
                  validation,
                  initialValue,
                  name,
                  label,
                  componentProps,
                  ...other
                } = field;
                return (
                  <InputComponent
                    key={name}
                    {...{ name, label }}
                    {...componentProps || {}}
                    {...other}
                  />
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cerrar</Button>
            <Button onClick={handleSubmit} disabled={!(dirty && isEmpty(errors))}>
              Crear Proceso
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default CreateProcessDialog;

import React from 'react';
import { css } from 'styled-components/macro';
import { useQuery } from 'react-apollo-hooks';
import { Grid } from 'styled-css-grid';
import { Paper, Typography, Divider } from '@material-ui/core';
import { Flex, Box } from 'rebass';
import { NoteAdd as NoteAddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { GET_MY_PROCESSES } from 'gql/queries';
import { ProcessesTable } from '../../components/ProcessesTable/ProcessesTable';
import { useModal } from 'hooks';
import { CreateProcessDialog } from '../../components/CreateProcessDialog/CreateProcessDialog';

import { Button } from 'components/styled/Button';
import SvgCircle from 'components/styled/SvgCircle';
import { ReactComponent as ExcelSvg } from '@fortawesome/fontawesome-free/svgs/solid/file-excel.svg';
import xlsx from 'assets/xlsx/Apoyo-Mis-Procesos.xlsx';

const greenFill = '#1AA58A';

const ProcessesRouteHeader = props => {
  const [openSignupDialog] = useModal(CreateProcessDialog);

  return (
    <Flex>
      <Typography color="primary" variant="h6">
        Mis Procesos
      </Typography>
      <Flex ml="auto" alignItems="center">
        <a href={xlsx} download="Apoyo Mis Procesos.xlsx" style={{textDecoration: 'none'}}>
        <Button color="primary" title="Descargar planilla de apoyo para la creación" style={{width: '250px', height: '50px'}}>
          <SvgCircle
          svg={ExcelSvg}
          diameter="50px"
          borderRadius="15%"
          color={greenFill}
          style={{ margin: '5px' }}
        /> Descargar</Button>
        </a>
        <Button onClick={openSignupDialog} color="primary" style={{width: '250px', height: '50px', marginLeft: '10px'}}>
          <NoteAddIcon
            css={css`
              margin-right: 1rem;
            `}
          />
          Crear Proceso
        </Button>
      </Flex>
    </Flex>
  );
};

export const ProcessesRoute = ({ path }) => {
  const { data, error, loading } = useQuery(GET_MY_PROCESSES);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const { processes = [] } = data.me;

  return (
    <Grid columns="1fr" rows="1fr" justifyContent="center">
      <Paper
        css={css`
          width: 85%;
          margin: 60px auto 0;
        `}
      >
        <Grid
          columns="1fr"
          css={css`
            padding: 1rem;
          `}
        >
          <ProcessesRouteHeader />
          <Divider style={{ width: '100%', margin: '10px auto' }} />
          <ProcessesTable processes={processes} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ProcessesRoute;

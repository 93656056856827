import gql from 'graphql-tag';
import { ProjectFragment, ComponentFragment, ProcessFragment } from '../fragments';

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      errors {
        message
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation signup($input: SignupInput!) {
    signup(input: $input) {
      token
      errors {
        message
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($input: ProjectCreateInput!) {
    projectCreate(input: $input) {
      errors {
        message
      }
      project {
        ...ProjectFragment
      }
    }
  }
  ${ProjectFragment}
`;

export const COPY_PROJECT = gql`
  mutation copyProject($input: ProjectCopyInput!) {
    projectCopy(input: $input) {
      errors {
        message
      }
      project {
        ...ProjectFragment
      }
    }
  }
  ${ProjectFragment}
`;

export const CREATE_PROCESS = gql`
  mutation createProcess($input: ProcessCreateInput!) {
    processCreate(input: $input) {
      errors {
        message
      }
      process {
        ...ProcessFragment
      }
    }
  }
  ${ProcessFragment}
`;

export const UPDATE_PROCESS = gql`
  mutation updateProcess($input: ProcessUpdateInput!) {
    processUpdate(input: $input) {
      errors {
        message
      }
      process {
        ...ProcessFragment
      }
    }
  }
  ${ProcessFragment}
`;

export const DELETE_PROCESS = gql`
  mutation deleteProcess($input: ProcessDeleteInput!) {
    processDelete(input: $input) {
      errors {
        message
      }
      process {
        id
      }
    }
  }
`;
export const DELETE_PROJECT = gql`
  mutation deleteProject($input: ProjectDeleteInput!) {
    projectDelete(input: $input) {
      errors {
        message
      }
      project {
        id
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($input: ProjectUpdateInput!) {
    projectUpdate(input: $input) {
      errors {
        message
      }
      project {
        ...ProjectFragment
      }
    }
  }
  ${ProjectFragment}
`;

//TODO make conditional
export const PROJECT_CREATE_CHILD_COMPONENT = gql`
  mutation projectCreateChildComponent($input: ProjectCreateChildComponentInput!) {
    projectCreateChildComponent(input: $input) {
      errors {
        message
      }
      component {
        ...ComponentFragment
      }
      project {
        ...ProjectFragment
      }
    }
  }
  ${ProjectFragment}
  ${ComponentFragment}
`;

export const COMPONENT_CREATE_CHILD_COMPONENT = gql`
  mutation componentCreateChildComponent($input: ComponentCreateChildComponentInput!) {
    componentCreateChild(input: $input) {
      errors {
        message
      }
      childComponent {
        ...ComponentFragment
      }
      parentComponent {
        ...ComponentFragment
      }
    }
  }
  ${ComponentFragment}
`;

export const COMPONENT_UPDATE = gql`
  mutation updateComponent($input: ComponentUpdateInput!) {
    componentUpdate(input: $input) {
      errors {
        message
      }
      component {
        ...ComponentFragment
      }
    }
  }
  ${ComponentFragment}
`;

export const COPY_COMPONENT = gql`
  mutation copyComponent($input: ComponentCopyInput!) {
    componentCopy(input: $input) {
      errors {
        message
      }
      component {
        ...ComponentFragment
      }
    }
  }
  ${ComponentFragment}
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const REQUEST_RESET_PASSWORD_MAIL = gql`
  mutation requestResetPasswordMail($input: RequestResetPasswordMailInput!) {
    requestResetPasswordMail(input: $input) {
      token
      success
      errors {
        message
      }
    }
  }
`;

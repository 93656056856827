import React, { useContext, useMemo, useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { isEmpty } from 'lodash';
import styled from 'styled-components/macro';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { Paper, IconButton, Dialog, Collapse } from '@material-ui/core';
import {
  NoteAdd as NoteAddIcon,
  Delete as DeleteIcon,
  DeviceHub as DeviceHubIcon,
  ChevronRight,
  ExpandMore,
} from '@material-ui/icons';
import { Cell } from 'styled-css-grid';

import { GET_COMPONENT } from 'gql/queries';

import { ComponentTreeProvider, ComponentTreeContext } from './ComponentTreeContext';
import { CreateComponentDialog } from 'components/CreateComponentDialog';
import { ConfirmDeleteDialog } from 'components/ConfirmDeleteDialog';
import { useModal } from 'hooks';
import { ProjectRouteContext } from 'routes/ProjectRoute/ProjectRouteContext';
import { ComponentTreeVisualization } from 'components/ComponentTreeVisualization';

const componentContainerStyles = ({ isSelected, isRoot }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateRows: '50px',
  gridTemplateColumns: 'auto 1fr auto',
  alignItems: 'center',
  gridTemplateAreas: '"show name buttons"',
  backgroundColor: isSelected ? '#eafbf7' : isRoot ? '' : '#eef2f6',
});

export const COMPONENT_DELETE = gql`
  mutation componentDeleteComponent($input: ComponentDeleteInput!) {
    componentDelete(input: $input) {
      errors {
        message
      }
      component {
        id
      }
      parentComponent {
        id
        children {
          id
        }
      }
      parentProject {
        id
        components {
          id
        }
      }
    }
  }
`;

const ComponentItem = ({
  name,
  id,
  isPublic,
  isSelected = false,
  isRoot = false,
  isLeaf = true,
  projectParentId,
  openConfirmDeleteDialog,
  toggleIsNodeOpen,
  isNodeOpen,
  ...other
}) => {
  const deleteComponentMutation = useMutation(COMPONENT_DELETE);
  const createComponentDialog = useMemo(
    () => props => (
      <CreateComponentDialog projectParentId={projectParentId} componentId={id} {...props} />
    ),
    [id]
  );
  const [openCreateComponentDialog] = useModal(createComponentDialog);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Paper style={componentContainerStyles({ isRoot, isSelected })} {...other}>
      {!isLeaf && (
        <IconButton onClick={() => toggleIsNodeOpen(id)}>
          {isNodeOpen ? <ChevronRight /> : <ExpandMore />}
        </IconButton>
      )}
      <Cell area="name" middle>
        {name}
      </Cell>
      <Cell area="buttons">
        {isRoot && (
          <>
            <IconButton
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <DeviceHubIcon color="primary" />
            </IconButton>
            <Dialog
              open={isOpen}
              onClose={() => {
                setIsOpen(false);
              }}
              fullWidth={true}
              maxWidth="lg"
            >
              <ComponentTreeVisualization componentId={id} />
            </Dialog>
          </>
        )}
        <IconButton disabled={isPublic} onClick={openCreateComponentDialog}>
          <NoteAddIcon />
        </IconButton>
        <IconButton
          disabled={isPublic}
          onClick={() => {
            openConfirmDeleteDialog({
              title: 'Confirmar eliminación',
              message: `¿Estás seguro, que deseas eliminar "${name}"?`,
              callback: () => deleteComponentMutation({ variables: { input: { id } } }),
            });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Cell>
    </Paper>
  );
};

const ChildrenContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 4px;
  margin-left: 20px;
  grid-row-gap: 1px;
  font-family: Roboto;
`;

const RootContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 6px;
  font-family: Roboto;
`;

const ComponentTreeNode = ({
  id,
  isRoot = false,
  isPublic,
  projectParentId,
  sortField,
  sortOrder,
  openConfirmDeleteDialog,
  ...other
}) => {
  const { data, error, loading } = useQuery(GET_COMPONENT, { variables: { id } });
  const [{ nodes }, { addNode }] = useContext(ComponentTreeContext);
  const [{ selectedNodeId, openNodes }, { selectNode, toggleIsNodeOpen }] = useContext(
    ProjectRouteContext
  );
  const { component = {}, errors } = data;
  let { name, children = [] } = component;
  children = (children).sort((a, b) => {
    return (
      a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
        numeric: true,
      }) * (sortOrder === "asc" ? 1 : -1)
    );
  });

  const isSelected = selectedNodeId === id;
  const isNodeOpen = openNodes[id] || false;
  const isLeaf = isEmpty(children);
  useEffect(() => {
    if (!(id in nodes)) {
      addNode({ id, children });
    }
  }, [id, children]);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !isEmpty(errors)) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <div {...other}>
      <ComponentItem
        name={name}
        id={id}
        isPublic={isPublic}
        isSelected={isSelected}
        isRoot={isRoot}
        isLeaf={isLeaf}
        projectParentId={projectParentId}
        onClick={() => selectNode(id)}
        openConfirmDeleteDialog={openConfirmDeleteDialog}
        isNodeOpen={isNodeOpen}
        toggleIsNodeOpen={toggleIsNodeOpen}
      />

      <ChildrenContainer>
        {children.map(({ id }) => (
          <Collapse in={isNodeOpen}>
            <ComponentTreeNode
              id={id}
              key={id}
              isPublic={isPublic}
              projectParentId={projectParentId}
              sortField={sortField}
              sortOrder={sortOrder}
              openConfirmDeleteDialog={openConfirmDeleteDialog}
            />
          </Collapse>
        ))}
      </ChildrenContainer>
    </div>
  );
};

export const ComponentTree = ({ isPublic, projectParentId, sortField='name', sortOrder='asc', components = [] }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [confirmArgs, setConfirmArgs] = useState({
    title: '',
    message: '',
    callback: () => {},
  });

  const handleCloseConfirm = () => setIsConfirmOpen(false);

  const openConfirmDeleteDialog = ({ title, message, callback }) => {
    setConfirmArgs({
      title,
      message,
      callback,
    });
    setIsConfirmOpen(true);
  };

  return (
    <ComponentTreeProvider>
      <RootContainer>
        <ConfirmDeleteDialog
          isOpen={isConfirmOpen}
          closeModal={handleCloseConfirm}
          {...confirmArgs}
        />
        {components.map(({ id }) => (
          <ComponentTreeNode
            id={id}
            key={id}
            isRoot={true}
            projectParentId={projectParentId}
            isPublic={isPublic}
            sortField={sortField}
            sortOrder={sortOrder}
            openConfirmDeleteDialog={openConfirmDeleteDialog}
          />
        ))}
      </RootContainer>
    </ComponentTreeProvider>
  );
};

export default ComponentTree;

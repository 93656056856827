import React from 'react';
import MaterialButton, { ButtonProps } from '@material-ui/core/Button';


export const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  ...props
}) => {

  return (
    <MaterialButton {...props}>
      {children}
    </MaterialButton>
  );
}

Button.defaultProps = {
  variant: 'contained',
  fullWidth: true
};

export default Button;
export { default as rutValidator } from './rutValidator';
export { default as formatNumber } from './formatNumber';
export * from './dfs';
export * from './domRefToPng';

/** Merges two objects, useful with Array.prototype.reduce  */
export function mergeObjects(a: object, b: object) {
  return { ...a, ...b };
}

/** Merges two objects, useful with Array.prototype.reduce  */
export function mergeArrays(a: any[], b: any[]) {
  return [...a, ...b];
}

export const weightValueInKilograms = weight => {
  const { value, metric } = weight;
  return metric === 'KILOGRAMS' ? value : value / 1000;
};

export const weightValueInGrams = weight => {
  const { value, metric } = weight;
  return metric === 'GRAMS' ? value : value * 1000;
};

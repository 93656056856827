import React, { useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { Formik } from 'formik';
import { useMutation } from 'react-apollo-hooks';
import * as Yup from 'yup';
import { Grid, Cell } from 'styled-css-grid';
import { isEmpty } from 'lodash';
import { MenuItem } from '@material-ui/core';

import Button from 'components/styled/Button';
import { Form } from 'components/Form';
import { PercentageInputField } from 'components/PercentageInputField';
import { SelectField } from 'components/SelectField';
import { COMPONENT_UPDATE } from 'gql/mutations';
import { reusePercentage } from 'consts';

import Tooltip from 'components/Tooltip';

const fieldSchema = Yup.number()
  .min(0, 'Porcentaje no puede ser negativo')
  .max(100, 'Porcentaje no puede superar el 100%')
  .required('Campo no puede ser vacio');

const validationSchema = Yup.object().shape({
  materialWaste: Yup.string(),
  incineration: fieldSchema,
  finalReusing: fieldSchema,
  finalRecycle: fieldSchema,
  dumpster: fieldSchema,
  sanitaryFilling: fieldSchema,
});

const childrenMessage = 'No se puede editar este campo si existen subcomponentes.';

// TODO move to enum consts
const materialWasteValueMap = {
  '': '',
  papel: 'Papel/Cartón',
  madera: 'Madera',
  plasticos: 'Plásticos',
  metal: 'Metal',
  vidrio: 'Vidrio',
  otros: 'Otros, inertes',
};

const materialValues = {
  papel: {
    incineration: 0,
    finalReusing: 0,
    finalRecycle: 17.8,
    dumpster: 0,
    sanitaryFilling: 82.8,},
  madera:{
    incineration: 0,
    finalReusing: 0,
    finalRecycle: 0,
    dumpster: 0,
    sanitaryFilling: 100,},
  plasticos:{
      incineration: 0,
      finalReusing: 0,
      finalRecycle: 4.5,
      dumpster: 0,
      sanitaryFilling: 95.5,},
  metal:{
        incineration: 0,
        finalReusing: 0,
        finalRecycle: 6.9,
        dumpster: 0,
        sanitaryFilling: 93.1,},
  vidrio:{
    incineration: 0,
    finalReusing: 0,
    finalRecycle: 15.7,
    dumpster: 0,
    sanitaryFilling: 84.3,},
  otros:{
    incineration: 0,
    finalReusing: 0,
    finalRecycle: 0,
    dumpster: 0,
    sanitaryFilling: 100,}
  }


function ComponentFinalDisposition({ component = {}, project = {} }) {
  const updateComponentMutation = useMutation(COMPONENT_UPDATE);

  const {
    id,
    finalDisposition: {
      incineration,
      finalReusing,
      finalRecycle,
      dumpster,
      sanitaryFilling,
      materialWaste = '',
    },
    children,
  } = component;

  const { isPublic } = project;

  const updateComponentSubmit = useCallback(
    async (values, actions) => {
      let input = { id, ...values };
      if (!isEmpty(children)) {
        delete input.materialWaste;
      }
      updateComponentMutation({ variables: { input } });
    },
    [id]
  );

  const initialValues = {
    id,
    incineration,
    finalReusing,
    finalRecycle,
    dumpster,
    sanitaryFilling: 100 - (incineration + finalReusing + finalRecycle + dumpster),
    materialWaste,
  };

  const handleInputChange = ({ values, setFieldValue }) => event => {
    const { name, value } = event.target;
    const currentValues = { ...values, [name]: Number(value) };
    const { incineration, finalReusing, finalRecycle, dumpster } = currentValues;
    setFieldValue('sanitaryFilling', 100 - (incineration + finalReusing + finalRecycle + dumpster));
  };

  const handleMaterialSelectChange = ({setValues}) => event => {
    const { value } = event.target;
    setValues({id,...materialValues[value]})
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={updateComponentSubmit}
    >
      {({
        errors,
        dirty,
        handleSubmit,
        setFieldValue,
        setValues,
        values,
        resetForm,
        initialValues: formikInitialValues,
      }) => {
        return (
          <Form>
            <Grid
              columns={'1fr'}
              rows={'auto'}
              rowGap="2rem"
              css={css`
                padding-top: 1rem;
              `}
            >
              {isEmpty(children) && (
                <Cell center>
                  <SelectField
                    disabled={isPublic}
                    style={{ width: '50%' }}
                    name={`materialWaste`}
                    label="Material"
                    onChange={handleMaterialSelectChange({setValues}) }
                  >
                    {Object.keys(materialWasteValueMap).map(material => (
                      <MenuItem value={material}>{materialWasteValueMap[material]}</MenuItem>
                    ))}
                  </SelectField>
                </Cell>
              )}
              <Cell center>
                <PercentageInputField
                  name="incineration"
                  label="Incineración"
                  onChange={handleInputChange({ values, setFieldValue })}
                  disabled={isPublic || !isEmpty(component.children)}
                  helperText={errors.weight || (!isEmpty(component.children) && childrenMessage)}
                />
              </Cell>

              <Cell center>
                <PercentageInputField
                  name="dumpster"
                  label="Compostaje"
                  onChange={handleInputChange({ values, setFieldValue })}
                  disabled={isPublic || !isEmpty(component.children)}
                  helperText={errors.weight || (!isEmpty(component.children) && childrenMessage)}
                />
              </Cell>

              <Cell center>
                <Tooltip text={reusePercentage}>
                  <PercentageInputField
                    name="finalReusing"
                    label="Reuso"
                    onChange={handleInputChange({ values, setFieldValue })}
                    disabled={isPublic || !isEmpty(component.children)}
                    helperText={errors.weight || (!isEmpty(component.children) && childrenMessage)}
                  />
                </Tooltip>
              </Cell>

              <Cell center>
                <PercentageInputField
                  name="finalRecycle"
                  label="Reciclaje"
                  onChange={handleInputChange({ values, setFieldValue })}
                  disabled={isPublic || !isEmpty(component.children)}
                  helperText={errors.weight || (!isEmpty(component.children) && childrenMessage)}
                />
              </Cell>

              <Cell center>
                <PercentageInputField
                  name="sanitaryFilling"
                  label="Relleno sanitario"
                  disabled={isPublic || true}
                />
              </Cell>

              <Cell center middle>
                <Grid columns="30% 30%" justifyContent="space-around">
                  <Cell left={2}>
                    <Button
                      disabled={isPublic || !(dirty && isEmpty(errors))}
                      onClick={handleSubmit}
                      color="primary"
                    >
                      Guardar cambios
                    </Button>
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ComponentFinalDisposition;

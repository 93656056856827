import React from 'react';
import { Text, Link } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const bottomText =
  'Los resultados presentados en este informe fueron generados con información de la industria de Packaging en Chile. El uso adecuado de la herramienta es responsabilidad del usuario, así este informe no constituye una validación oficial de los resultados por parte de EcoPackaging';

const Container = styled.View`
  position: absolute;
  bottom: 0;
  margin-bottom: '1cm';
  margin-left: '1cm';
  margin-right: '1cm';
`;
const Row = styled.View`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: 30px;
  justify-content: space-around;
  align-content: center;
  text-align: center;
  font-size: 12;
`;

const Item = styled.View`
  width: 33%;
  display: flex;
`;

export const PdfFooter = ({ projectName = '' }) => {
  const date = new Date();
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const dateString = `${day}/${month}/${year}`;
  return (
    <Container>
      <Row>
        <Item>
          <Text>{projectName}</Text>
        </Item>
        <Item>
          <Text style={{ textDecoration: 'underline', color: 'blue', fontSize: 10 }}>
            <Link>www.ecopackagingchile.cl</Link>
          </Text>
        </Item>
        <Item>
          <Text>{dateString}</Text>
        </Item>
      </Row>
      <Text
        style={{
          fontSize: 12,
          fontStyle: 'oblique',
        }}
      >
        {bottomText}
      </Text>
    </Container>
  );
};

export default PdfFooter;

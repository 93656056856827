import React from 'react';
import ReactDOM from 'react-dom';
import { ModalMap } from './types';
import { Box } from 'rebass'

/**
 * Modal Root Props
 */
interface ModalRootProps {
  /**
   * Map of modal instances associated by unique ids
   */
  modals: ModalMap;
}

/**
 * Modal Root
 *
 * Renders modals using react portal.
 */
const ModalRoot = ({ modals }: ModalRootProps) => {
  return (
    <>
      {
        Object.keys(modals).map(key => {
          const { component: Component, props } = modals[key];
          return Component && <Component key={key} {...props} />;
        })
      }
    </>
  );
}

export default React.memo(ModalRoot);
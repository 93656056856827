import React, { useContext, Dispatch } from 'react';
import useLocalStorage from './useLocalStorage';

const strings = {
  localStorage: {
    authTokenKey: 'authToken'
  }
};

const AuthTokenContext = React.createContext<[string, Dispatch<string>]>(null);

export const AuthTokenProvider = ({ children }) => {
  const contextValue = useLocalStorage(strings.localStorage.authTokenKey);
  return (
    <AuthTokenContext.Provider value={contextValue}>
      {children}
    </AuthTokenContext.Provider>
  )
};

const useAuthToken: () => { authToken: string, setAuthToken: Dispatch<string> } = () => {
  const [authToken, setAuthToken] = useContext(AuthTokenContext);
  return { authToken, setAuthToken };
}

export default useAuthToken;
import React from 'react';
import useMethods from 'use-methods';

const initialState = {
  selectedNodeId: null,
  openNodes: {},
};

export const ProjectRouteContext = React.createContext([
  initialState,
  {
    selectNode: null,
    toggleIsNodeOpen: null,
  },
]);

const methods = state => ({
  selectNode: id => {
    state.selectedNodeId = id;
  },
  toggleIsNodeOpen: id => {
    const { openNodes = {} } = state;
    const isNodeOpen = openNodes[id] || false;
    state.openNodes[id] = !Boolean(isNodeOpen);
  },
});

export const ProjectRouteProvider = ({ children }) => {
  const [state, callbacks] = useMethods(methods, initialState);

  return (
    <ProjectRouteContext.Provider value={[state, callbacks]}>
      {children}
    </ProjectRouteContext.Provider>
  );
};

import React, { useCallback } from 'react';
import { css } from 'styled-components/macro';
import { Grid } from 'styled-css-grid';

import { useQuery, useMutation } from 'react-apollo-hooks';
import { MenuItem } from '@material-ui/core';
import { SelectField } from 'components/SelectField';
import { mergeObjects } from 'utils';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'hooks';
import { COPY_PROJECT } from 'gql/mutations';
import { GET_PROJECTS } from 'gql/queries';

export const CopyProjectDialog = ({ isOpen, closeModal }) => {
  const { data, error, loading } = useQuery(GET_PROJECTS);

  const copyProjectMutation = useMutation(COPY_PROJECT, {
    update: (cache, { data: { projectCopy = {} } }) => {
      const { errors } = projectCopy;
      if (!isEmpty(errors)) return;
      const { project } = projectCopy;
      const { me } = cache.readQuery({ query: GET_PROJECTS });
      const updatedProjects = [...me.projects, project];
      cache.writeQuery({ query: GET_PROJECTS, data: { me: { ...me, projects: updatedProjects } } });
    },
  });

  const { enqueueErrorSnackbar } = useSnackbar();
  const copyProjectSubmit = useCallback(
    async (values, actions) => {
      try {
        const { data } = await copyProjectMutation({ variables: { input: values } });
        const { project, errors } = data.projectCopy;
        if (!isEmpty(errors)) throw new Error(errors[0].message);
        closeModal();
      } catch (e) {
        enqueueErrorSnackbar(e.message);
      }
    },
    [closeModal]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const { publicProjects = [] } = data;
  const { projects = [] } = data.me;

  const projectsMerged = [...publicProjects, ...projects];
  const publicProjectIds = publicProjects.map(({ id }) => id);
  const projectIds = projects.map(({ id }) => id);

  const allProjects = [...new Set([...publicProjectIds, ...projectIds])].map(id =>
    projectsMerged.find(({ id: projectId }) => projectId === id)
  );

  const fields = [
    {
      name: 'id',
      label: 'Proyecto',
      initialValue: (allProjects[0] || {}).id,
      InputComponent: SelectField,
      componentProps: {
        children: allProjects.map(({ name, id }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        )),
      },
    },
  ];

  const initialValues = fields.map(x => ({ [x.name]: x.initialValue })).reduce(mergeObjects);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={copyProjectSubmit}
      render={({ handleSubmit, errors, dirty }) => (
        <Dialog open={isOpen}>
          <DialogTitle>Copiar proyecto existente</DialogTitle>
          <DialogContent>
            <Grid
              columns={'minmax(500px, auto)'}
              rows={'auto'}
              rowGap="1rem"
              css={css`
                padding-top: 1rem;
              `}
            >
              {fields.map(field => {
                const {
                  InputComponent,
                  validation,
                  initialValue,
                  name,
                  label,
                  componentProps,
                  ...other
                } = field;
                return (
                  <InputComponent
                    key={name}
                    {...{ name, label }}
                    {...componentProps || {}}
                    {...other}
                  />
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cerrar</Button>
            <Button onClick={handleSubmit}>Copiar Proyecto</Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default CopyProjectDialog;

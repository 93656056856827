import React, { useState, useEffect } from 'react';
import { ApolloProvider as ApolloHookProvider } from 'react-apollo-hooks';

import { useAuthToken } from 'hooks';
import { createApolloClient } from './createApolloClient';


export const ApolloProvider = ({ children }) => {
  const { authToken } = useAuthToken();
  const [client, setClient] = useState(() => (createApolloClient({ authToken })))

  useEffect(() => {
    setClient(createApolloClient({ authToken }));
  }, [authToken])

  return (
    <ApolloHookProvider client={client}>
      {children}
    </ApolloHookProvider>
  )
};

export default ApolloProvider;
export default function rutValidator(rut) {
  if (!rut) {
    return false;
  }
  rut = String(rut);
  let value = rut.replace(".", "").replace(".", "");
  value = value.replace("-", "");
  let body = value.slice(0, -1);
  let dv = value.slice(-1).toUpperCase();
  rut = body + "-" + dv;
  if (body.length < 7) {
    return false;
  }
  let sum = 0;
  let multiple = 2;
  for (let i = 1; i <= body.length; i++) {
    let index = multiple * value.charAt(body.length - i);
    sum = sum + index;
    if (multiple < 7) {
      multiple = multiple + 1;
    } else {
      multiple = 2;
    }
  }
  let correctDv = 11 - sum % 11;
  dv = dv == "K"
    ? 10
    : dv;
  dv = dv == 0
    ? 11
    : dv;
  if (correctDv != dv) {
    return false;
  }
  return true;
}
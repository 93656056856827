import gql from 'graphql-tag';
import { ComponentFragment, ProjectFragment, UserFragment, ProcessFragment } from '../fragments';

export const GET_PROCESSES = gql`
  query getProcesses {
    processes {
      id
      name
      material
    }
  }
`;

export const GET_MY_PROCESSES = gql`
  query getMyProcesses {
    me {
      id
      processes {
        ...ProcessFragment
      }
    }
  }
  ${ProcessFragment}
`;

export const GET_TRANSPORTS = gql`
  query getTransports {
    transports {
      id
      vehicle
      type
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjects {
    me {
      id
      projects {
        ...ProjectFragment
      }
    }
    publicProjects {
      ...ProjectFragment
    }
  }
  ${ProjectFragment}
`;

export const GET_PROJECT = gql`
  query getProject($id: String!) {
    project(id: $id) {
      ...ProjectFragment
    }
  }
  ${ProjectFragment}
`;

export const GET_PROCESS = gql`
  query getProcess($id: String!) {
    process(id: $id) {
      ...ProcessFragment
    }
  }
  ${ProcessFragment}
`;

export const GET_COMPONENT = gql`
  query getComponent($id: String!) {
    component(id: $id) {
      ...ComponentFragment
    }
  }
  ${ComponentFragment}
`;

export const GET_COMPONENTS = gql`
  query getComponentsList($id: String!) {
    componentsList(id: $id) {
      id
      name
    }
  }
`;

export const GET_ME = gql`
  query getMe {
    me {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      name
    }
  }
`;

export const GET_PROJECT_RESULTS = gql`
  fragment ComponentResults on Component {
    id
    name
    functionalUnitMagnitude
    weight {
      value
      metric
    }
    resultsTree
    origin {
      recycledMaterial
      renewableMaterial
      nonRenewableMaterial
    }
  }
  query getProjectResults($id: String!) {
    project(id: $id) {
      id
      name
      functionalUnit
      functionalUnitMagnitude
      owner {
        email
      }
      components {
        ...ComponentResults
        children {
          ...ComponentResults
          children {
            ...ComponentResults
            children {
              ...ComponentResults
              children {
                ...ComponentResults
                children {
                  ...ComponentResults
                  children {
                    ...ComponentResults
                    children {
                      ...ComponentResults
                      children {
                        ...ComponentResults
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    me {
      id
      components {
        id
        name
        functionalUnitMagnitude
        weight {
          value
          metric
        }
      }
    }
  }
`;

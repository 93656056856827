import React from 'react';
import { Formik } from 'formik';
import { css } from 'styled-components/macro';
import {
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { SelectField } from 'components/SelectField';
import { Grid, Cell } from 'styled-css-grid';

import transportOptions from './transportOptions';

export const SelectOriginDestinationDialog = ({
  isOpen,
  closeModal,
  transportType,
  distanceCallback,
}) => {
  const transports = transportOptions[transportType] || {};
  const destinations = Object.keys(transports);

  const initialValues = {
    origin: (destinations && destinations[0]) || '',
    destination: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        distanceCallback(transports[values.origin][values.destination].distance);
        closeModal();
      }}
      render={({ handleSubmit, errors, dirty, values }) => (
        <Dialog open={isOpen} onClose={closeModal}>
          <DialogTitle>Seleccione el origen y el destino del transporte </DialogTitle>
          <DialogContent>
            <Grid
              columns="1fr 1fr"
              css={css`
                margin-top: 1rem;
              `}
            >
              <SelectField name="origin" label="Origen">
                {isEmpty(destinations) && <MenuItem value="" />}
                {destinations.map(origin => (
                  <MenuItem value={origin}>{origin}</MenuItem>
                ))}
              </SelectField>

              <SelectField name="destination" label="Destino">
                <MenuItem value="" />
                {((transports && transports[values.origin]) || []).map((origin, i) => (
                  <MenuItem value={i}>{origin.destiny}</MenuItem>
                ))}
              </SelectField>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cancelar</Button>
            <Button onClick={handleSubmit} disabled={!(dirty && isEmpty(errors))}>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};
export default SelectOriginDestinationDialog;
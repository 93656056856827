import React from 'react';
import { makeStyles } from '@material-ui/styles';

import MuiTooltip from '@material-ui/core/Tooltip';

const styleGenerator = color => ({
  tooltip: {
    backgroundColor: color,
    fontSize: 14,
    textAlign: 'center',
    color: '#fff',
  },
  arrow: {
    position: 'absolute',
    fontSize: 8,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  },
});

const useStylesArrow = makeStyles(theme => styleGenerator('#4cb5ab'));

const Tooltip = props => {
  const { children, text } = props;
  const { arrow, ...classes } = useStylesArrow();
  const [arrowRef, setArrowRef] = React.useState(null);

  return (
    <MuiTooltip
      classes={classes}
      placement="top"
      title={
        <React.Fragment>
          {text}
          <span className={arrow} ref={setArrowRef} />
        </React.Fragment>
      }
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;

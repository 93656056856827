import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { Grid } from 'styled-css-grid';

import { useMutation, useQuery } from 'react-apollo-hooks';
import { MenuItem } from '@material-ui/core';
import { SelectField } from 'components/SelectField';
import { mergeObjects } from 'utils';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useSnackbar, useModal } from 'hooks';
import { COPY_COMPONENT } from 'gql/mutations';
import { GET_COMPONENTS, GET_PROJECT } from 'gql/queries';

export const CopyComponentDialog = ({
  isOpen,
  closeModal,
  projectParentId = null,
  componentId = null,
  isRootComponent,
}) => {
  const { data, error, loading } = useQuery(GET_COMPONENTS, {
    variables: { id: projectParentId },
  });

  const { componentsList = [] } = data;

  const copyComponentMutation = useMutation(COPY_COMPONENT);

  const { enqueueErrorSnackbar } = useSnackbar();
  const copyComponentSubmit = useCallback(
    async (values, actions) => {
      try {
        const { data } = await copyComponentMutation({
          variables: {
            input: { ...values, parentId: componentId || projectParentId, isRootComponent },
          },
        });
        const { component, errors } = data.componentCopy;
        if (!isEmpty(errors)) throw new Error(errors[0].message);
        closeModal();
      } catch (e) {
        enqueueErrorSnackbar(e.message);
      }
    },
    [closeModal]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const fields = componentsList.length
    ? [
        {
          name: 'id',
          label: 'Componente',
          initialValue: componentsList[0].id,
          InputComponent: SelectField,
          componentProps: {
            children: componentsList.map(({ name, id }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            )),
          },
        },
      ]
    : [];

  const initialValues = fields.map(x => ({ [x.name]: x.initialValue })).reduce(mergeObjects, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={copyComponentSubmit}
      render={({ handleSubmit, errors, dirty }) => (
        <Dialog open={isOpen}>
          <DialogTitle>Copiar componente existente</DialogTitle>
          <DialogContent>
            <Grid
              columns={'minmax(500px, auto)'}
              rows={'auto'}
              rowGap="1rem"
              css={css`
                padding-top: 1rem;
              `}
            >
              {fields.map(field => {
                const {
                  InputComponent,
                  validation,
                  initialValue,
                  name,
                  label,
                  componentProps,
                  ...other
                } = field;
                return (
                  <InputComponent
                    key={name}
                    {...{ name, label }}
                    {...componentProps || {}}
                    {...other}
                  />
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cerrar</Button>
            <Button onClick={handleSubmit}>Copiar Componente</Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default CopyComponentDialog;

import React from 'react';
import { Grid, Cell } from 'styled-css-grid';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Link, Divider } from '@material-ui/core';
import { css } from 'styled-components/macro';

import { ReactComponent as PdfSvg } from '@fortawesome/fontawesome-free/svgs/solid/file-pdf.svg';
import { PictureAsPdf as PdfIcon } from '@material-ui/icons';
import SvgCircle from 'components/styled/SvgCircle';

import pdfDescripcionProcesos from 'assets/pdf/ACVCORFO-Descripción de procesos disponibles en la herramienta.pdf';
import pdfManualUsuario from 'assets/pdf/ACVCORFO-Manual-Usuario.pdf';
import pdfModeloLogistico from 'assets/pdf/ACVCORFO-Modelo_Logico.pdf';
import Button from 'components/styled/Button';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '500',
    paddingBottom: '5px',
    margin: '15px',
  },
  button: {
    margin: '20px',
  },
  input: {
    display: 'none',
  },
}));

const DocumentButton = props => {
  const { text, pdfRef } = props;

  return (
    <Button variant="text" href={pdfRef} target="_blank" style={{ width: '100%' }}>
      <Grid columns="auto 1fr" style={{ width: '100%' }}>
        <SvgCircle
          svg={PdfSvg}
          diameter="40px"
          borderRadius="15%"
          color={greenFill}
          style={{ margin: '5px' }}
          alignItems="middle"
        />
        <Cell middle>
          <Typography variant="subtitle1"> {text} </Typography>
        </Cell>
      </Grid>
    </Button>
  );
};

const greenFill = '#1AA58A';

export const DocumentsRoute = ({ path }) => {
  const classes = useStyles();

  return (
    <Grid
      columns="1fr"
      rowGap="1rem"
      css={css`
        margin: 20px;
      `}
    >
      <Typography variant="h4" style={{ color: '#1AA58A' }}>
        Documentos de Interés
      </Typography>

      <Grid
        columns="25%"
        rowGap="1rem"
        css={css`
          margin: 20px;
          justify-content: left;
        `}
      >
        <DocumentButton text="Manual de Usuario" pdfRef={pdfManualUsuario} />
        <DocumentButton text="Modelo Lógico" pdfRef={pdfModeloLogistico} />
        <DocumentButton text="Descripción de procesos" pdfRef={pdfDescripcionProcesos} />
      </Grid>
    </Grid>
  );
};

export default DocumentsRoute;

import React from 'react';
import { Grid, Cell } from 'styled-css-grid';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Link, Divider } from '@material-ui/core';
import { Box } from 'rebass';
import { css } from 'styled-components/macro';
import { isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import * as jwt from 'jsonwebtoken';

import Button from 'components/styled/Button';
import ResetPasswordForm from 'components/ResetPasswordForm';

const strings = {
  localStorage: {
    authTokenKey: 'authToken',
  },
  titles: {
    resetPassword: 'Cambiar contraseña',
  },
};

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '500',
  },
}));

export const ResetPasswordRoute = ({ path }) => {
  const classes = useStyles();
  const { match } = useReactRouter();
  const { token } = match.params;

  const content = jwt.decode(token);
  const { email, type } = content;

  return (
    <Grid height="100%" gap="0px" columns={`1fr`} rows={`1fr`}>
      <Cell middle center>
        <Box m="auto" width="400px">
          <Paper
            css={css`
              /* TODO quickfix */
              position: relative;
              top: -120px;
              left: -65px;
            `}
          >
            <Grid
              columns="400px"
              rows="1fr 3fr auto"
              areas={['title', 'form', 'signup']}
              rowGap="1em"
            >
              <Cell area="title" center middle>
                <Typography variant="h5" color="primary" className={classes.title}>
                  {strings.titles.resetPassword}
                </Typography>
                <Typography variant="h6">{email}</Typography>
                <Divider variant="middle" />
              </Cell>
              <Cell area="form">
                <ResetPasswordForm token={token} />
              </Cell>
            </Grid>
          </Paper>
        </Box>
      </Cell>
    </Grid>
  );
};

export default ResetPasswordRoute;

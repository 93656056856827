import React from 'react';
import { InputAdornment } from '@material-ui/core';

import { InputField } from 'components/InputField';

export const PercentageInputField = props => {
  return (
    <InputField
      type="number"
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
        inputProps: { min: '0', max: '100' },
      }}
      {...props}
    />
  );
};

export default PercentageInputField;

import React from 'react';
import styled from 'styled-components';

const CircleBackGround = styled.div`
  background-color: ${props => props.color || 'black'};
  border-radius: ${props => props.borderRadius || '50%'};
  width: ${props => props.diameter || '100%'};
  height: ${props => props.diameter || '100%'};
  /* padding-top: 100%; */
  position: relative; 
`;

const SvgWrapper = styled.div`
  position: absolute;
  fill: white;
  width: 50%;
  height: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto; 
`;

export function SvgCircle({svg, ...rest}) {
  const Svg = svg;

  return (
    <CircleBackGround {...rest}>
      <SvgWrapper as={Svg} />
    </CircleBackGround>
  )
}

export default SvgCircle;
import React from 'react';
import styled from '@react-pdf/styled-components';

import cenem from 'assets/img/cenem.png';
import dictuc from 'assets/img/dictuc.png';
import ecopackaging from 'assets/img/ecopackaging.png';

const Container = styled.View`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: space-around;
  align-content: center;
`;

const Item = styled.View`
  width: 33%;
  display: flex;
  align-content: center;
`;

const StyledImg = styled.Image`
  object-fit: contain;
`;

export const PdfHeader = () => {
  return (
    <Container>
      <Item>
        <StyledImg src={ecopackaging} style={{ margin: 'auto' }} />
      </Item>
      <Item>
        <StyledImg src={cenem} />
      </Item>
      <Item>
        <StyledImg src={dictuc} />
      </Item>
    </Container>
  );
};

export default PdfHeader;

import React, { useEffect, useState, useRef } from 'react';
import Tree from 'react-d3-tree';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { isEmpty } from 'lodash';
import { Typography } from '@material-ui/core';
import { css } from 'styled-components/macro';

import { formatNumber, weightValueInGrams } from 'utils';
import {} from 'gql/queries';

// TODO make a real recursive call
// Number of fragment limits size of display tree. Current value set at 20.
const getComponentTree = gql`
  query getComponentTree($id: String!) {
    component(id: $id) {
      ...ComponentRecursive
    }
  }
  fragment ComponentRecursive on Component {
    ...ComponentFields
    children {
      ...ComponentFields
      children {
        ...ComponentFields
        children {
          ...ComponentFields
          children {
            ...ComponentFields
            children {
              ...ComponentFields
              children {
                ...ComponentFields
                children {
                  ...ComponentFields
                  children {
                    ...ComponentFields
                    children {
                      ...ComponentFields
                      children {
                        ...ComponentFields
                        children {
                          ...ComponentFields
                          children {
                            ...ComponentFields
                            children {
                              ...ComponentFields
                              children {
                                ...ComponentFields
                                children {
                                  ...ComponentFields
                                  children {
                                    ...ComponentFields
                                    children {
                                      ...ComponentFields
                                      children {
                                        ...ComponentFields
                                        children {
                                          ...ComponentFields
                                          children {
                                            ...ComponentFields
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment ComponentFields on Component {
    id
    name
    weight {
      value
      metric
    }
    appliedProcesses {
      process {
        id
        name
        material
      }
      cycles
      percentajeCleanEnergy
    }    
  }
`;

export const ComponentTreeVisualization = ({ componentId }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const treeContainerRef = useRef();
  const { data, error, loading } = useQuery(getComponentTree, { variables: { id: componentId } });
  const { component = {}, errors } = data;

  useEffect(() => {
    if (!isEmpty(component)) {
      // center tree
      setDimensions(treeContainerRef.current.getBoundingClientRect());
    }
  }, [component]);

  if (isEmpty(component)) {
    return null;
  }

  const appliedProcessesLabel = appliedProcesses => {
    return appliedProcesses.map(item => `${item.process.name} (${item.process.material}: ${item.cycles} ciclos y ${item.percentajeCleanEnergy} % energía limpia)`).join('. ');
  };
  
  const mapTreeData = node => {
    const { name, weight, appliedProcesses, children = [] } = node;
    let output = {
      name,
      attributes: {
        'Masa física': `${formatNumber(weightValueInGrams(weight))} gr`
      },
      children: children.map(child => mapTreeData(child))
    };
    if (appliedProcesses) {
      //output.attributes['Procesos'] = appliedProcessesLabel(appliedProcesses);

      appliedProcesses.forEach(function callback(item, index) {
        output.attributes[`Proceso ${index + 1}`] = `${item.process.name} (${item.process.material})`;
      });      
        
    }
    return output;
  };
  const treeData = mapTreeData(component);

  return (
    <div style={{ height: '80vh', overflow: 'hidden' }} ref={treeContainerRef}>
      <Typography
        variant="h4"
        style={{ color: '#1AA58A' }}
        css={css`
          padding-top: 30px;
          padding-left: 30px;
          padding-bottom: 20px;
          border: 1px solid;
        `}
      >
        Árbol de Componentes
      </Typography>
      <Tree
        orientation="vertical"
        translate={{
          x: dimensions.width / 2,
          y: 50,
        }}
        data={treeData}
        pathFunc="elbow"
        separation={{ siblings: 2 }}
      />
    </div>
  );
};

export default ComponentTreeVisualization;

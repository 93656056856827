import React from 'react';
import { useField } from 'formik';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';


export const InputField = ({
  name,
  label,
  helperText,
  onChange,
  ...other
}) => {
  const [field, meta] = useField(name);
  const { onChange: onFieldChange, ...otherFieldProps } = field;
  const errorText = meta.touch && meta.error
  const error = Boolean(errorText);

  return (
    <TextField
      label={label}
      helperText={errorText || helperText}
      error={error}
      onChange={(...args) => { onFieldChange(...args); onChange(...args); }}
      {...other}
      {...otherFieldProps}
    />
  );
}

InputField.defaultProps = {
  variant: 'outlined',
  label: '',
  helperText: ' ',
  fullWidth: true,
  onChange: () => { },
};

export default InputField;